import { useQuery } from '@apollo/client'
import { MenuItem, TextField, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiNodeInfoProps } from './ApiNodeInfoPopover'
import { NodeBranchInput, userBranchesQuery } from '../../generated/graphql'
import { OrganizationBranches } from '../../schema/OrganizationBranches'
import AsyncButton from '../AsyncButton'
import GraphqlError from '../GraphqlError'
import Loading from '../Loading'

interface Props extends ApiNodeInfoProps {
  open: boolean
  onClose(): void
  commit(input: NodeBranchInput): void
}

function ApiNodeInfoEditDialog(props: Props): React.ReactElement {
  const { open, onClose, commit, node } = props
  const { branch, id, shareWithChildBranches } = node
  const { data, error, loading } = useQuery<userBranchesQuery>(OrganizationBranches.GET_USER_BRANCHES)
  const { t } = useTranslation()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [state, setState] = useState({ branch_id: branch?.id, shareWithChildBranches })
  const editNodeBranch = async () => {
    await commit({ id, ...state })
    onClose()
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
    >
      <DialogTitle id="form-dialog-title">{t('Edit branch info')}</DialogTitle>
      <DialogContent>
        <GraphqlError error={error} />
        {Boolean(data) && (
          <TextField
            select
            key={state.branch_id}
            variant="standard"
            label={t('Branch')}
            value={state.branch_id || ''}
            onChange={(event) => setState((prevState) => ({ ...prevState, branch_id: event.target.value }))}
          >
            {data.userBranches.map((d) => (
              <MenuItem value={d.id} key={d.id}>
                {d.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {loading && <Loading variant="inline" size={20} />}
        <div style={{ margin: '10px 0px' }}>
          <FormControl>
            <FormControlLabel
              label={t('Shared with subbranches?')}
              labelPlacement={'end'}
              control={<Checkbox />}
              checked={state.shareWithChildBranches}
              onChange={() =>
                setState((prevState) => ({ ...prevState, shareWithChildBranches: !prevState.shareWithChildBranches }))
              }
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('Cancel')}
        </Button>
        <AsyncButton onClick={editNodeBranch} />
      </DialogActions>
    </Dialog>
  )
}

export default ApiNodeInfoEditDialog
