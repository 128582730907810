import { useQuery } from '@apollo/client'
import { MenuItem, Select, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Column } from 'material-table'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import MultipleSelect from '../../components/MultiSelect'
import { AclTable } from '../../components/table/AclTable'
import {
  aclRolesQuery,
  CreateProfileMutationMutation,
  CreateProfileMutationMutationVariables,
  NewOrganizationUserInput,
  organizationBranchesQuery,
  OrganizationProfileDataFragment,
  OrganizationUserEditInput,
  organizationUsersQuery,
  organizationUsersQueryVariables,
  ProfileMutationMutation,
  ProfileMutationMutationVariables,
} from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { useDeleteOrganizationUsersMutation, useListMutation } from '../../helpers/GqlHelper'
import { requiredEmail, requiredField } from '../../helpers/validations'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import AclRoles from '../../schema/AclRoles'
import { OrganizationBranches } from '../../schema/OrganizationBranches'
import Profiles from '../../schema/Profiles'

interface UserDataTable extends Omit<OrganizationProfileDataFragment, 'organizationBranch'> {
  aclRolesIds: string[]
  organizationBranchId?: string
}

const ManageUsersScreen: React.FC = () => {
  const { t } = useTranslation()
  const [openMergeAccount, setOpenMergeAccount] = React.useState(false)
  const [accountinvited, setAccountInvited] = React.useState<NewOrganizationUserInput>()

  const handleClose = () => {
    setOpenMergeAccount(false)
  }

  const handleCloseAgree = () => {
    createProfile(accountinvited)
    setOpenMergeAccount(false)
  }

  const branchFilter = useBranchFilteredQuery()
  const [deleteProfile, { error: deleteUserError }] = useDeleteOrganizationUsersMutation(
    Profiles.GET_ORGANIZATION_USERS,
    branchFilter.variables,
  )
  const [createOrganizationUser, { error: userCreateError }] = useListMutation<
    CreateProfileMutationMutation,
    CreateProfileMutationMutationVariables
  >(Profiles.CREATE_ORGANIZATION_USER, Profiles.GET_ORGANIZATION_USERS, branchFilter.variables)
  const [mutateProfile, { error: userEditError }] = useListMutation<
    ProfileMutationMutation,
    ProfileMutationMutationVariables
  >(Profiles.EDIT_ORGANIZATION_USER, Profiles.GET_ORGANIZATION_USERS)

  const { loading, error, data } = useQuery<organizationUsersQuery, organizationUsersQueryVariables>(
    Profiles.GET_ORGANIZATION_USERS,
    branchFilter,
  )

  useEffect(() => {
    if (userCreateError?.graphQLErrors[0]?.extensions?.code === 'USER_IS_PARTICIPANT') {
      setOpenMergeAccount(true)
    }
  }, [userCreateError])

  const { data: rolesData, error: getRolesError } = useQuery<aclRolesQuery>(AclRoles.GET_ACL_ROLES)
  const { data: branchData, error: branchError } = useQuery<organizationBranchesQuery>(
    OrganizationBranches.GET_ORGANIZATION_BRANCH,
  )
  const [newUserTypeAdded, setNewUserTypeAdded] = useState(null)
  const createProfile = async (input: NewOrganizationUserInput) => {
    setAccountInvited({ ...input, mergeAccount: true })
    const response = await createOrganizationUser({ variables: { input: DataHelper.cleanInputData(input) } })
    setNewUserTypeAdded(response.data.createOrganizationUser)
  }

  const editProfile = async (input: OrganizationUserEditInput) => {
    await mutateProfile({ variables: { input: DataHelper.cleanInputData(input) } })
  }
  const deleteNode = async (id: string) => {
    await deleteProfile({ variables: { input: { id } } })
  }
  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  const organizationUsers = data.organizationUsers
  const aclRoles = rolesData ? rolesData.aclRoles : []
  const aclBranches = branchData?.organizationBranches || []

  const rolesOptions = aclRoles.reduce((options, role) => {
    options[role.id] = role.name
    return options
  }, {} as { [key: string]: string })

  const branchDataOptions = aclBranches.reduce(
    (options, branch) => {
      options[branch.id] = branch.name
      return options
    },
    { '0': t('None') } as { [key: string]: string },
  )

  const columns: Column<UserDataTable>[] = [
    {
      title: 'Name',
      field: 'name',
      validate: requiredField('name'),
      editComponent: (editProps) => {
        return (
          <TextField
            variant="standard"
            value={editProps.value || ''}
            label="Name"
            fullWidth
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          />
        )
      },
    },
    {
      title: 'Email',
      field: 'email',
      validate: requiredEmail('email'),
      editComponent: (editProps) => {
        return (
          <TextField
            variant="standard"
            value={editProps.value || ''}
            label="Email"
            fullWidth
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          />
        )
      },
    },
    {
      title: 'ACL Roles',
      field: 'aclRolesIds',
      render: (userDataTable) => {
        return userDataTable.aclRolesIds && userDataTable.aclRolesIds.map((r) => rolesOptions[r]).join(', ')
      },
      editComponent: ({ value, onChange }) => {
        return <MultipleSelect value={value || []} options={rolesOptions} setValue={onChange} />
      },
      initialEditValue: [],
    },
    {
      title: 'Organization branch',
      field: 'organizationBranchId',
      lookup: branchDataOptions,
      editComponent: (editProps) => {
        return (
          <Select
            labelId="timeReminder"
            id="timeReminder"
            value={editProps.value || ''}
            label="Time"
            fullWidth
            variant="standard"
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          >
            {Object.keys(branchDataOptions).map((k) => (
              <MenuItem key={k} value={k}>
                {branchDataOptions[k]}
              </MenuItem>
            ))}
          </Select>
        )
      },
      initialEditValue: '0',
      emptyValue: 'None',
    },
    { title: 'Active', field: 'active', type: 'boolean', initialEditValue: true },
  ]
  return (
    <div>
      <Helmet>
        <title>{t('Manage Users')}</title>
      </Helmet>
      <GraphqlError error={userEditError} />
      <GraphqlError error={userCreateError} />
      <GraphqlError error={deleteUserError} />
      <GraphqlError error={getRolesError} />
      <GraphqlError error={branchError} />
      <React.Fragment>
        <Dialog
          open={openMergeAccount}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Role Conversion Confirmation'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                'Hello! It seems this user is already registered in the application as a participant. Would you like to convert user to the selected role at this time?'
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{'Disagree'}</Button>
            <Button onClick={handleCloseAgree} autoFocus>
              {'Agree'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <AclTable<UserDataTable>
        entityName={EntityNames.Profile}
        title={t('Organization users')}
        columns={columns}
        data={organizationUsers.map((user) => {
          const { organizationBranch, ...u } = user
          return {
            ...u,
            aclRolesIds: u.aclRoles ? u.aclRoles.map((r) => r.id) : [],
            organizationBranchId: organizationBranch?.id || '0',
          }
        })}
        paginateToElement={newUserTypeAdded}
        editable={{
          onRowAdd: async (newData) => {
            /* eslint-disable no-new */
            new Promise((resolve) => {
              setTimeout(async () => {
                const { aclRolesIds, ...userDataTable } = newData
                if (userDataTable.organizationBranchId === '0') {
                  userDataTable.organizationBranchId = null
                }
                await createProfile({
                  ...userDataTable,
                  aclRolesIds: aclRolesIds || [],
                })
                resolve(true)
              }, 1000)
            })
          },
          onRowUpdate: async (newData) => {
            const { aclRolesIds, ...userDataTable } = newData
            if (userDataTable.id) {
              if (userDataTable.organizationBranchId === '0') {
                userDataTable.organizationBranchId = null
              }
              const input: UserDataTable = {
                ...userDataTable,
                aclRolesIds: aclRolesIds || [],
              }
              const { aclRoles: roles, ...finalData } = input
              await editProfile(DataHelper.cleanInputData<OrganizationUserEditInput>(finalData))
            }
          },
          onRowDelete: async (oldData) => {
            if (oldData.id) {
              await deleteNode(oldData.id)
            }
          },
        }}
      />
    </div>
  )
}

export default ManageUsersScreen
