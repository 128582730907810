import { Box, Button, Grid, Snackbar, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  name: string
  value: string
}

export default function DnsInfo(props: Props): JSX.Element {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  function handleClick(val: string) {
    navigator.clipboard.writeText(val)

    setOpen(true)
  }

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'grey.500',
        px: 5,
        pb: 5,
        mr: 2,
        mt: 2,
      }}
    >
      <h2 style={{ textAlign: 'center' }}>{props.title}</h2>
      <Grid container>
        <Grid sx={{ mr: 1 }}>
          <TextField
            variant="standard"
            label={t('Name')}
            name="name"
            inputProps={{ readOnly: true }}
            value={props.name}
          />
        </Grid>
        <Grid item alignItems="stretch" sx={{ display: 'flex', alignSelf: 'flex-end', mb: 0.5 }}>
          <Button onClick={() => handleClick(props.name)}>{t('Copy')}</Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sx={{ mr: 1 }}>
          <TextField
            variant="standard"
            label={t('Value')}
            name="value"
            inputProps={{ readOnly: true }}
            value={props.value}
          />
        </Grid>
        <Grid item alignItems="stretch" sx={{ display: 'flex', alignSelf: 'flex-end', mb: 0.5 }}>
          <Button onClick={() => handleClick(props.value)}>{t('Copy')}</Button>
        </Grid>
      </Grid>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message={t('Copied')} />
    </Box>
  )
}
