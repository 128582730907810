import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useUserContext } from './UserContext'
import Loading from '../components/Loading'
import { CalendarDataFragment, calendarsQuery } from '../generated/graphql'
import { useBranchFilteredQuery } from '../hooks/BranchFilterHook'
import log from '../log'
import Calendars from '../schema/Calendars'

type ToggleCalendarSelection = (calendarId: any) => void
type CalendarSelection = Record<string, boolean | undefined>

export interface SelectableCalendar extends CalendarDataFragment {
  isSelected: boolean
}

const SelectedCalendarsContext = React.createContext<SelectableCalendar[]>([])
const CalendarDispatchContext = React.createContext<ToggleCalendarSelection | undefined>(undefined)

export const CalendarProvider: React.FC = ({ children }) => {
  const { id: userId } = useUserContext()
  const [selection, setSelection] = useState<CalendarSelection>(() => loadFromLocalStorage())
  const { enqueueSnackbar } = useSnackbar()
  const branchFilter = useBranchFilteredQuery()
  const { data, error, loading, refetch } = useQuery<calendarsQuery>(Calendars.GET_CALENDARS, {
    fetchPolicy: 'cache-and-network',
    ...branchFilter,
  })

  useEffect(() => {
    if (userId) {
      setSelection(loadFromLocalStorage())
      refetch()
    }
  }, [refetch, userId])

  useEffect(() => {
    saveToLocalStorage(selection)
  }, [selection])

  useEffect(() => {
    if (error && !error.message.match(/not_authenticated/)) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [enqueueSnackbar, error])

  useEffect(() => {
    setSelection((previousSelection) => {
      const newSelection: CalendarSelection = {}
      if (data?.calendars) {
        data.calendars.forEach((calendar) => {
          newSelection[calendar.id] = previousSelection[calendar.id] !== false
        })
      }
      return newSelection
    })
  }, [data])

  const calendars: SelectableCalendar[] = useMemo<SelectableCalendar[]>(() => {
    return (
      data?.calendars.map((calendar) => ({
        ...calendar,
        isSelected: typeof selection[calendar.id] !== 'undefined' ? !!selection[calendar.id] : true,
      })) || []
    )
  }, [data, selection])

  const toggleSelectedCalendar = useCallback<ToggleCalendarSelection>(
    (calendarId: any): void => {
      if (typeof calendarId === 'string') {
        const nextState: boolean = selection[calendarId] === false || selectedCalendarsCount(calendars) <= 1
        setSelection({
          ...selection,
          [calendarId]: nextState,
        })
      } else {
        // Is array
        const superSecret = function (spy) {
          Object.keys(spy).forEach(function (key) {
            if (key === calendarId.defaultMyCalendar) {
              spy[key] = true
            } else {
              spy[key] = !calendarId.optionSelected
            }
          })
          return spy
        }
        const result = superSecret(selection)
        setSelection({
          ...result,
        })
      }
    },
    [selection, calendars],
  )

  if (loading) {
    return <Loading />
  }

  return (
    <SelectedCalendarsContext.Provider value={calendars}>
      <CalendarDispatchContext.Provider value={toggleSelectedCalendar}>{children}</CalendarDispatchContext.Provider>
    </SelectedCalendarsContext.Provider>
  )
}

function selectedCalendarsCount(calendars: SelectableCalendar[]): number {
  return calendars.filter(({ isSelected }) => isSelected).length
}

// Cache managing
const CALENDAR_SELECTION_CACHE_KEY = 'CalendarSelection'

function isCalendarSelection(data: any): data is CalendarSelection {
  if (typeof data !== 'object') return false
  return Object.keys(data).reduce((acc: boolean, value: string) => acc && typeof data[value] === 'boolean', true)
}

function loadFromLocalStorage(): CalendarSelection {
  try {
    const loadedJson = localStorage.getItem(CALENDAR_SELECTION_CACHE_KEY)
    if (loadedJson) {
      const loadedData = JSON.parse(loadedJson)
      if (isCalendarSelection(loadedData)) {
        return loadedData
      }
    }
  } catch (ex) {
    log.info(`Stored "${CALENDAR_SELECTION_CACHE_KEY}" cannot be parsed (${ex.message})`)
  }
  return {}
}

function saveToLocalStorage(selection: CalendarSelection): void {
  const data = JSON.stringify(selection)
  const previousData = localStorage.getItem(CALENDAR_SELECTION_CACHE_KEY)
  if (data !== previousData) {
    localStorage.setItem(CALENDAR_SELECTION_CACHE_KEY, data)
  }
}

export function useCalendars() {
  return {
    calendars: useCalendarsList(),
    selectedCalendars: useSelectedCalendars(),
    toggleSelectedCalendar: useToggleSelectedCalendar(),
  }
}

export function useCalendarsList(): SelectableCalendar[] {
  return useContext(SelectedCalendarsContext)
}

export function useSelectedCalendars(): SelectableCalendar[] {
  const calendars = useContext(SelectedCalendarsContext)
  return useMemo(() => calendars.filter((calendar) => calendar.isSelected), [calendars])
}

export function useToggleSelectedCalendar(): ToggleCalendarSelection {
  const context = useContext(CalendarDispatchContext)
  if (!context) {
    throw new Error('"useToggleSelectedCalendar()" must be used within a "CalendarProvider"')
  }
  return context
}
