import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function AsyncButton(props: ButtonProps) {
  const { t } = useTranslation()
  const { children, disabled, onClick, ...buttonProps } = props
  const [loading, setLoading] = useState<boolean>(false)
  const onDynamicClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setLoading(true)
      await onClick(event)
      setLoading(false)
    },
    [onClick],
  )

  return (
    <Button
      startIcon={loading && <CircularProgress size={22} />}
      color="primary"
      disabled={loading || disabled}
      onClick={onDynamicClick}
      {...buttonProps}
    >
      {children || t('Save')}
    </Button>
  )
}
