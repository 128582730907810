import { gql } from '@apollo/client'

const TagData = gql`
  fragment TagData on NodeTag {
    id
    key
    value
  }
`

const GET_ALL_TAGS_NAMES = gql`
  query tagNames {
    tagNames
  }
`

const GET_ALL_TAG_VALUES = gql`
  query tagValues($tagKey: String!) {
    tagValues(tagKey: $tagKey)
  }
`

const Tags = {
  fragment: {
    TagData,
  },
  GET_ALL_TAGS_NAMES,
  GET_ALL_TAG_VALUES,
}

export default Tags
