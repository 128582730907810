import { useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AsyncButton from '../../components/AsyncButton'
import ColorSelector from '../../components/ColorSelector'
import ApiNodeInfo from '../../components/owned-api-node/ApiNodeInfo'
import { CalendarDataFragment, CalendarInput } from '../../generated/graphql'
import DH from '../../helpers/DH'

interface Props {
  open: boolean
  calendar?: CalendarDataFragment

  onClose(): void

  commit(calendar: CalendarInput): void
}

function getInitialState(props: Props, forceReset = false) {
  if (props.calendar && !forceReset) {
    return props.calendar
  }
  return {
    color: DH.getDefaultColorHex(),
    name: '',
  }
}

const CalendarDialog: React.FC<Props> = (props) => {
  const { open, onClose, commit } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const [calendarData, setCalendarData] = useState<CalendarInput>(getInitialState(props))
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const commitCalendar = async () => {
    await commit(calendarData)
    setCalendarData(getInitialState(props, true))
    onClose()
  }
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">
        {!!props.calendar && <ApiNodeInfo node={props.calendar} />}
        {props.calendar ? t('Edit calendar') : t('Create calendar')}
      </DialogTitle>
      <DialogContent>
        {!props.calendar && <DialogContentText>{t('Please fill the new calendar details')}</DialogContentText>}
        <TextField
          variant="standard"
          autoFocus
          margin="dense"
          id="name"
          label={t('Name')}
          type="text"
          value={calendarData.name}
          onChange={(e) => setCalendarData({ ...calendarData, name: e.target.value })}
          fullWidth
        />
        <FormControl>
          <FormLabel>{t('Color')}</FormLabel>
          <ColorSelector
            color={calendarData.color}
            onChange={(color: string) => setCalendarData({ ...calendarData, color })}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="text">
          {t('Cancel')}
        </Button>
        <AsyncButton variant="text" onClick={commitCalendar} disabled={!calendarData.name} />
      </DialogActions>
    </Dialog>
  )
}

export default CalendarDialog
