import { Typography, TypographyProps } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => {
  return {
    title: {
      margin: 0,
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
  }
})

export default function ScreenTitle(props: TypographyProps) {
  const { classes } = useStyles()
  return <Typography className={classes.title} {...props} />
}
