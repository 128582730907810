import { useMutation } from '@apollo/client'
import AssignmentIcon from '@mui/icons-material/Assignment'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import ErrorIcon from '@mui/icons-material/Error'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { downloadTemplate, EventImportValidateResult, validateImportFile } from '@persei/scheduling-event-import'
import { useRouter } from 'found'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import GraphqlError from '../../components/GraphqlError'
import Message from '../../components/Message'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import MediaField from '../../components/files/MediaField'
import SaveButton from '../../components/forms/SaveButton'
import {
  DoEventImportMutationMutation,
  DoEventImportMutationMutationVariables,
  EventImportDataFragment,
  EventImportInput,
  EventImportMutationMutation,
  EventImportMutationMutationVariables,
  eventImportsQuery,
  EventImportState,
  MediaAccessUrlQuery,
  MediaAccessUrlQueryVariables,
} from '../../generated/graphql'
import DataHelper from '../../helpers/DataHelper'
import { TZ_NAMES } from '../../helpers/DateHelper'
import GqlHelper, { useDeleteMutation } from '../../helpers/GqlHelper'
import Auth from '../../helpers/auth'
import routes from '../../helpers/routes'
import { User, useUserContext } from '../../providers/UserContext'
import EventImports from '../../schema/EventImports'
import Medias from '../../schema/Medias'

const useStyles = makeStyles()((theme) => {
  return {
    fileContainer: {
      marginTop: theme.spacing(1),
    },
    switchLabel: {
      alignItems: 'start',
    },
    errorMessage: {
      marginBottom: 10,
    },
    wrapIcon: {
      'display': 'flex',
      'alignItems': 'center',
      'textTransform': 'capitalize',
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  }
})

interface Props {
  eventImport?: EventImportDataFragment
}

const DATE_FORMATS = [
  'DD/MM/YYYY HH:mm',
  'DD/MM/YYYY HH:mm:ss',
  'DD-MM-YYYY HH:mm',
  'DD-MM-YYYY HH:mm:ss',
  'DD.MM.YYYY HH:mm',
  'DD.MM.YYYY HH:mm:ss',
  'MM/DD/YYYY HH:mm',
  'MM/DD/YYYY HH:mm:ss',
  'MM-DD-YYYY HH:mm',
  'MM-DD-YYYY HH:mm:ss',
  'MM.DD.YYYY HH:mm',
  'MM.DD.YYYY HH:mm:ss',
  'YYYY-MM-DD HH:mm',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY.MM.DD HH:mm',
  'YYYY.MM.DD HH:mm:ss',
]

function getStateFromProps(data: EventImportDataFragment, user: User): EventImportInput {
  return {
    id: data?.id,
    name: data?.name,
    id_media: data?.file?.id,
    tzname: data?.tzname || user.getTz(),
    dateFormat: data?.dateFormat || DATE_FORMATS[0],
    geolocateLocation: data?.geolocateLocation || false,
  }
}

function downloadXlsx(e: React.SyntheticEvent) {
  e.preventDefault()
  downloadTemplate()
}

export default function EventImportEditContent(props: Props) {
  const user = useUserContext()
  const { classes } = useStyles()
  const { router } = useRouter()
  const { t } = useTranslation()
  const [eventImport, setEventImport] = useState<EventImportDataFragment | undefined>(props.eventImport)
  const [changed, setChanged] = useState(false)
  const [validating, setValidating] = useState(false)
  const [state, setState] = useState<EventImportInput>(getStateFromProps(props.eventImport, user))
  const [importFile, setImportFile] = useState<Blob | null>(null)
  const [validationResult, setValidationResult] = useState<EventImportValidateResult | null>(null)

  const [mutateEventImport, { loading, error }] = useMutation<
    EventImportMutationMutation,
    EventImportMutationMutationVariables
  >(EventImports.EDIT_EVENT_IMPORT, {
    update: GqlHelper.getMutationReducer<EventImportMutationMutation, eventImportsQuery>(
      'mutateEventImport',
      EventImports.GET_EVENT_IMPORTS,
      'eventImports',
    ),
    onCompleted: (data) => {
      if (!props.eventImport) {
        router.push({
          pathname: routes.app_settings_import_edit + '/' + data.mutateEventImport.id,
        })
      }
    },
  })

  const [doEventImport, { loading: doImportLoading, error: doImportError }] = useMutation<
    DoEventImportMutationMutation,
    DoEventImportMutationMutationVariables
  >(EventImports.DO_EVENT_IMPORT, {
    update: GqlHelper.getMutationReducer<DoEventImportMutationMutation, eventImportsQuery>(
      'importEvents',
      EventImports.GET_EVENT_IMPORTS,
      'eventImports',
    ),
  })
  const [deleteEvent, { loading: deleting }] = useDeleteMutation(EventImports.GET_EVENT_IMPORTS)
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)

  function doCancel() {
    router.push(routes.app_settings_import)
  }

  const deleteAction = async () => {
    setShowDeleteConfirmDialog(false)
    if (eventImport?.id) {
      deleteEvent({
        variables: {
          input: { id: eventImport.id },
        },
      }).then(doCancel)
    }
  }

  useEffect(() => {
    if (props.eventImport) {
      setEventImport(props.eventImport)
    }
  }, [props.eventImport])

  const updateState = useCallback(
    (newState: EventImportInput) => {
      setState(newState)
      setChanged(true)
    },
    [setState],
  )

  const editEventImport = useCallback(
    async (eventImportInput: EventImportInput) => {
      await mutateEventImport({ variables: { input: DataHelper.removeTypeName(eventImportInput) } })
    },
    [mutateEventImport],
  )
  const [errorFile, setErrorFile] = useState('')
  useEffect(() => {
    async function loadImportFile() {
      try {
        const fileData = await Auth.getClient().query<MediaAccessUrlQuery, MediaAccessUrlQueryVariables>({
          query: Medias.GET_ACCESS_URL,
          variables: {
            id: props.eventImport.file.id,
          },
        })
        if (fileData.data.mediaAccessUrl.url) {
          const response = await fetch(fileData.data.mediaAccessUrl.url)
          if (response.ok) {
            const blob = await response.blob()
            setImportFile(blob)
          } else {
            setErrorFile(t("Can't download the file"))
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    setState(getStateFromProps(props.eventImport, user))
    if (props.eventImport?.file) {
      // Load file from url
      // noinspection JSIgnoredPromiseFromCall
      loadImportFile()
    }
    setChanged(false)
  }, [props.eventImport, t, user])

  useEffect(() => {
    if (importFile) {
      try {
        const reader = new FileReader()
        reader.onload = function (e) {
          setValidating(true)
          const valResult = validateImportFile(new Uint8Array(e.target.result as ArrayBuffer), false, {
            tz: state.tzname,
            dateFormat: state.dateFormat,
          })
          setValidationResult(valResult)
          setValidating(false)
        }
        reader.readAsArrayBuffer(importFile)
      } catch (e) {
        setErrorFile(t("Can't read the file"))
        console.error(e)
      }
    }
  }, [importFile, user, state.tzname, state.dateFormat, t])

  const anyLoading = loading || deleting || doImportLoading || validating
  const readOnly = eventImport?.state === EventImportState.success
  return (
    <div>
      <GraphqlError error={error} />
      {Boolean(errorFile) && <Message type={'error'} message={errorFile} />}
      <GraphqlError error={doImportError} />
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <TextField
            required
            autoFocus
            variant="standard"
            label={t('Name')}
            type="text"
            value={state.name || ''}
            onChange={(e) => updateState({ ...state, name: e.target.value })}
            fullWidth
            disabled={readOnly || anyLoading}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <FormControl>
            <FormLabel>{t('State')}</FormLabel>
            <Typography className={classes.wrapIcon}>
              {eventImport?.state === 'validated' && <CheckIcon fontSize="large" />}
              {eventImport?.state === 'success' && <CloudDoneIcon fontSize="large" />}
              {eventImport?.state === 'draft' && <AssignmentIcon fontSize="large" />}
              {eventImport?.state === 'failed' && <ErrorIcon fontSize="large" />}
              {eventImport?.state === 'importing' && <CloudDoneIcon fontSize="large" />}
              {eventImport?.state === 'notValid' && <BlockIcon fontSize="large" />}
              {t(eventImport?.state || 'draft')}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item sm={12} md={4}>
          <Autocomplete
            disabled={readOnly || anyLoading}
            value={state.tzname}
            options={TZ_NAMES}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField variant="standard" required label={t('Dates time zone')} fullWidth {...params} />
            )}
            onChange={(event: never, newValue: string | null) => updateState({ ...state, tzname: newValue })}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <TextField
            select
            variant="standard"
            label={t('Date format')}
            disabled={readOnly || anyLoading}
            fullWidth
            required
            id="dateFormat-id"
            value={state.dateFormat || DATE_FORMATS[0]}
            onChange={(e) => updateState({ ...state, dateFormat: e.target.value as string })}
          >
            {DATE_FORMATS.map((n) => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={12} md={4}>
          <FormControlLabel
            control={
              <Switch
                disabled={readOnly || anyLoading}
                checked={state.geolocateLocation}
                onChange={(event, checked) => updateState({ ...state, geolocateLocation: checked })}
              />
            }
            className={classes.switchLabel}
            labelPlacement="top"
            label={t('Geocode locations')}
          />
        </Grid>
        {!readOnly && (
          <Grid item sm={12} md={12}>
            <FormControl required>
              <FormLabel required>{t('Import file')}</FormLabel>
              <FormHelperText>
                {t('Please upload a excel file following this')}{' '}
                <Link href="#" target="_blank" rel="noreferrer" onClick={downloadXlsx} underline="hover">
                  {t('template')}
                </Link>
              </FormHelperText>
              <div className={classes.fileContainer}>
                <MediaField
                  options={{
                    accept: {
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    },
                  }}
                  notDeleteMedia={true}
                  idMedia={state.id_media}
                  onChange={(id, file?: File) => {
                    setImportFile(file)
                    updateState({ ...state, id_media: id })
                  }}
                />
              </div>
            </FormControl>
          </Grid>
        )}
        {!!state.id_media && (
          <Grid item sm={12} md={12}>
            {validationResult?.errors.map((e, index) => (
              <Message key={index} type={'warningText'} message={e} className={classes.errorMessage} />
            ))}
            {Object.keys(validationResult?.rowErrors || {}).map((row) => (
              <Message
                key={'row' + row}
                type={'warningText'}
                message={
                  <div>
                    <div>{t('Row') + ' ' + row + ':'}</div>
                    {validationResult.rowErrors[row].map((e, idx) => (
                      <div key={'row-error-' + idx}>{e}</div>
                    ))}
                  </div>
                }
                className={classes.errorMessage}
              />
            ))}
          </Grid>
        )}
      </Grid>
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Button onClick={doCancel} color="primary" variant="text">
          {t('Cancel')}
        </Button>
        {eventImport?.state !== EventImportState.success && (
          <SaveButton
            variant="text"
            onClick={() => state && editEventImport(state)}
            disabled={
              !changed ||
              anyLoading ||
              !state.name ||
              !state.id_media ||
              eventImport?.state === EventImportState.importing ||
              (validationResult && !validationResult.valid)
            }
            loading={loading || validating}
          >
            {t('Save & Validate')}
          </SaveButton>
        )}
        {state.id && eventImport?.state !== EventImportState.success && !changed && !validating && (
          <SaveButton
            onClick={() => state.id && doEventImport({ variables: { id: state.id } })}
            disabled={anyLoading || !state.id || eventImport?.state !== EventImportState.validated}
            loading={doImportLoading}
          >
            {t('Import')}
          </SaveButton>
        )}
        {!!eventImport?.id && (
          <SaveButton onClick={() => setShowDeleteConfirmDialog(true)} disabled={anyLoading} loading={deleting}>
            {t('Delete')}
          </SaveButton>
        )}
        <ConfirmDialog
          open={showDeleteConfirmDialog}
          onClose={() => setShowDeleteConfirmDialog(false)}
          onConfirm={deleteAction}
          title={t('Delete event import')}
          text={t('Are you sure you want to delete this import and all imported events?')}
        />
      </div>
    </div>
  )
}
