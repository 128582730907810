import { ApolloError, useMutation, useQuery } from '@apollo/client'
import Check from '@mui/icons-material/Check'
import Clear from '@mui/icons-material/Clear'
import { MenuItem, Select, TextField } from '@mui/material'
import * as events from 'events'
import { Column } from 'material-table'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import UploadFile from './UpoadFile'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { MediaFieldView } from '../../components/files/MediaFieldView'
import { AclTable } from '../../components/table/AclTable'
import {
  MediaInput,
  MediaListDataFragment,
  mediasQuery,
  mediasQueryVariables,
  MutateMediaMutation,
  MutateMediaMutationVariables,
} from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { useDeleteMutation } from '../../helpers/GqlHelper'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import { CanBeNodeInterface } from '../../providers/UserContext'
import Medias from '../../schema/Medias'

type MediaLibraryData = Partial<MediaListDataFragment> & Partial<MediaInput> & CanBeNodeInterface

type Filename = {
  name?: string
}

export interface MediaLibraryScreenProps {
  selector?: boolean
  ownerNodeId?: string
  onChange?(id?: string | null, file?: File): void
  closeDialog?(ev?: events): void
}

const useStyles = makeStyles()(() => {
  return {
    selectButton: {
      'backgroundColor': '#ffffff',
      'borderColor': '#adadad',
      'display': 'inline-block',
      'margin': 0,
      'marginLeft': 0,
      'textAlign': 'center',
      'verticalAlign': 'middle',
      'border': '1px solid #ccc',
      'borderRadius': 4,
      '&:hover, &:focus': {
        backgroundColor: '#e6e6e6',
      },
    },
  }
})

const getFileName = (newData: MediaLibraryData): Filename => {
  if (newData.name) {
    return { name: newData.name }
  } else if ('file' in newData && 'name' in newData.file) {
    return { name: newData.file.name }
  } else if ('filename' in newData) {
    return { name: newData.filename }
  }
  return {}
}

export default function MediaLibraryScreen(props: MediaLibraryScreenProps) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [updateError, setUpdateError] = useState<ApolloError | undefined>()
  const branchFilter = useBranchFilteredQuery()
  const [deleteMediaType] = useDeleteMutation(Medias.GET_MEDIAS, branchFilter.variables)

  const nodeFilter = props.selector ? props.ownerNodeId || '' : props.ownerNodeId

  const [mutateMedia] = useMutation<MutateMediaMutation, MutateMediaMutationVariables>(Medias.MUTATE_MEDIA, {
    refetchQueries: [{ query: Medias.GET_MEDIAS, variables: { ...branchFilter?.variables, node: nodeFilter } }],
  })

  const [newMediaTypeAdded, setNewMediaTypeAdded] = useState(null)

  const { onChange, closeDialog } = props

  const deleteMediaFunction = async (id: string) => {
    await deleteMediaType({ variables: { input: { id } } })
    return true
  }

  const mutateMediaFunction = async (mediaFile: MediaInput) => {
    try {
      const response = await mutateMedia({ variables: { input: DataHelper.cleanInputData(mediaFile) } })
      setNewMediaTypeAdded(response.data.mutateMedia)
    } catch (e) {
      setUpdateError(e)
    }

    return true
  }

  const { loading, error, data } = useQuery<mediasQuery, mediasQueryVariables>(Medias.GET_MEDIAS, {
    variables: { ...branchFilter?.variables, node: nodeFilter },
  })

  const allMedia = data?.medias
  const choices = [
    {
      id: false,
      text: t('Non global'),
    },
    {
      id: true,
      text: t('Global'),
    },
  ]

  const editColumn = {
    title: '',
    render: (nodeData) => {
      return (
        <button
          type={'button'}
          className={classes.selectButton}
          onClick={() => {
            if ('id' in nodeData) {
              onChange(nodeData.id)
              closeDialog()
            }
          }}
        >
          {t('Select')}
        </button>
      )
    },
  }

  const columns: Column<MediaLibraryData>[] = useMemo(
    () => [
      {
        title: t('Name'),
        field: 'name',
        editComponent: (editProps) => {
          return (
            <TextField
              variant="standard"
              value={editProps.value || ''}
              label={t('Name')}
              fullWidth
              onChange={(val) => {
                editProps.onChange(val.target.value)
              }}
            />
          )
        },
      },
      {
        title: t('Alt Text'),
        field: 'altText',
        editComponent: (editProps) => {
          return (
            <TextField
              variant="standard"
              value={editProps.value || ''}
              label={t('Alt Text')}
              fullWidth
              onChange={(val) => {
                editProps.onChange(val.target.value)
              }}
            />
          )
        },
      },
      {
        title: t('Description'),
        field: 'description',
        editComponent: (editProps) => {
          return (
            <TextField
              variant="standard"
              value={editProps.value || ''}
              label={t('Description')}
              fullWidth
              onChange={(val) => {
                editProps.onChange(val.target.value)
              }}
            />
          )
        },
      },
      {
        title: t('Global'),
        field: 'global',

        render: (nodeData) => {
          return nodeData.global ? <Check /> : <Clear />
        },
        lookup: choices,
        editComponent: (editProps) => {
          return (
            <Select
              labelId="globalOrNot"
              id="globalOrNot"
              value={editProps.value}
              fullWidth
              variant="standard"
              onChange={(val) => {
                console.log(val.target.value)
                editProps.onChange(val.target.value)
              }}
            >
              {Object.keys(choices).map((k) => (
                <MenuItem key={choices[k].text} value={choices[k].id}>
                  {choices[k].text}
                </MenuItem>
              ))}
            </Select>
          )
        },
      },
      {
        title: t('File'),
        field: 'file',
        validate: (rowData: MediaLibraryData): boolean | string => {
          if ('id' in rowData || 'file' in rowData) {
            return true
          }
          return t('File required') as string
        },
        editComponent: (editProps) => {
          return (
            <div>
              <UploadFile
                id={editProps.value}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  const file: File = acceptedFiles[0]
                  editProps.onChange(file)
                }}
              >
                {editProps.rowData.file?.name || editProps.rowData.filename}
              </UploadFile>
            </div>
          )
        },
        render: (nodeData) => {
          return <MediaFieldView idMedia={nodeData.id} />
        },
        customFilterAndSearch: (term, rowData) => rowData.filename.includes(term),
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  )

  if (error || updateError) {
    return <GraphqlError error={error || updateError} />
  }
  if (loading || !data) {
    return <Loading />
  }

  return (
    <div>
      <Helmet>
        <title>{t('Media Library')}</title>
      </Helmet>
      <AclTable<MediaLibraryData>
        entityName={EntityNames.Media}
        title={t('Media library')}
        columns={props.selector ? [...columns, editColumn] : columns}
        showDefaultActions={!props.selector}
        showInfoColumn={!props.selector}
        options={{
          filtering: false,
          showTitle: false,
        }}
        localization={{
          body: { editRow: { deleteText: t('Are you sure you want to delete this file?') } },
        }}
        data={allMedia}
        paginateToElement={newMediaTypeAdded}
        editable={{
          onRowAdd: async (newData) => {
            const fileName = getFileName(newData)
            const newFile = {
              ...fileName,
              file: newData.file,
              description: newData.description,
              altText: newData.altText,
            }
            await mutateMediaFunction(newFile)
          },
          onRowUpdate: async (newData) => {
            const fileName = getFileName(newData)

            const newFile = {
              ...fileName,
              file: newData.file,
              id: newData.id,
              description: newData.description,
              altText: newData.altText,
            }
            await mutateMediaFunction(newFile)
          },
          onRowDelete: async (oldData) => {
            await deleteMediaFunction(oldData.id)
          },
        }}
      />
    </div>
  )
}
