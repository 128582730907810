import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  List,
  ListItem,
  Paper,
  Snackbar,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { ConditionalComponent } from '../../components/ConditionalComponent'
import DnsInfo from '../../components/domain/DnsInfo'
import NewDomainRequestForm from '../../components/domain/NewDomainRequestForm'
import RequestStatus from '../../components/domain/RequestStatus'
import {
  CustomDomainRequestStatus,
  DnsRecordType,
  DomainInProgressMutation,
  DomainInProgressMutationVariables,
  getDomainQuery,
  getDomainQueryResult,
} from '../../generated/graphql'
import DH from '../../helpers/DH'
import Domain from '../../schema/Domain'

function buildSubstring(key: string, size: number, andString: string) {
  let text = ''
  for (let i = 2; i <= size; i++) {
    text += key + ' ' + i

    if (i < size - 1) {
      text += ', '
    } else if (i === size - 1) {
      text += ' ' + andString + ' '
    }
  }

  return text
}

const DomainScreen: React.FC = () => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const { data } = useQuery<getDomainQuery, getDomainQueryResult>(Domain.GET_DOMAIN, {
    fetchPolicy: 'network-only',
  })
  const domain = data?.getCustomDomainNameRequest
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)

  const [mutateInProgressCustomDomainNameRequest] = useMutation<
    DomainInProgressMutation,
    DomainInProgressMutationVariables
  >(Domain.IN_PROGRESS_DOMAIN, {
    refetchQueries: [Domain.GET_DOMAIN],
  })

  const dnsItems = {
    cnames: { label: t('Create a CNAME record using Eventoom info.'), items: [] },
    txt: { label: t('Modify/add existent SPF entry adding a new valid sender.'), items: [] },
    mx: { label: t('Generate a DNS mail subdomain entry.'), items: [] },
  }

  if (domain?.status === CustomDomainRequestStatus.configured && domain?.customDomainNameDnsRecords) {
    let indexCname = 1
    let indexTxt = 1
    let indexMx = 1

    domain?.customDomainNameDnsRecords.forEach((dns) => {
      if (dns.type === DnsRecordType.cname) {
        dnsItems.cnames.items.push(
          <DnsInfo key={dns.id} name={dns.name} value={dns.value} title={'CNAME ' + indexCname} />,
        )
        indexCname++
      }
      if (dns.type === DnsRecordType.txt) {
        dnsItems.txt.items.push(<DnsInfo key={dns.id} name={dns.name} value={dns.value} title={'TXT ' + indexTxt} />)
        indexTxt++
      }
      if (dns.type === DnsRecordType.mx) {
        dnsItems.mx.items.push(<DnsInfo key={dns.id} name={dns.name} value={dns.value} title={'MX ' + indexMx} />)
        indexMx++
      }
    })
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFinish = async () => {
    try {
      await mutateInProgressCustomDomainNameRequest({
        variables: {
          input: {
            id: domain.id,
          },
        },
      })
      setOpen(true)
    } catch (ex) {
      enqueueSnackbar(DH.getGraphqlErrorMessages(ex).join('<br>'), { variant: 'error' })
    }
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  function cnamesAdditionalComponent(key: { label: string; items: any[] }) {
    if (key === dnsItems.cnames) {
      return (
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            {t(
              "You are going to add {{num}} CNAME records to your domain. These records are managed on your domain provider's website or interface. They help us direct your email to the right place.",
              { num: key.items.length },
            )}
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 3, fontSize: '16px' }}>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              {t('Look for a place to create or add a new record.')}
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              {t(
                "Copy the Name info from CNAME 1 below and paste it into your record's Name or Host field. This field may also be called Hostname or Host record.",
              )}
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              {t(
                "Copy the Value info from CNAME 1 below and paste it into your record's Value field. This field may also be called Data or Points To.",
              )}
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              {t(
                'If you have an option for TTL, either leave it at the default setting or choose the lowest value offered.',
              )}
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>{t('Add or save your record.')}</ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              {t('Now repeat those steps, but this time copy/paste the info for {{name}}.', {
                name: buildSubstring('CNAME', key.items.length, t('and')),
              })}
            </ListItem>
          </List>
        </Box>
      )
    } else {
      return null
    }
  }

  return (
    <div>
      <Helmet>
        <title>{t('Domain')}</title>
      </Helmet>
      <ConditionalComponent show={!domain || domain?.status === CustomDomainRequestStatus.cancelled}>
        <NewDomainRequestForm />
      </ConditionalComponent>
      <ConditionalComponent show={domain && domain?.status !== CustomDomainRequestStatus.cancelled}>
        <RequestStatus />
        <ConditionalComponent show={domain?.status === CustomDomainRequestStatus.configured}>
          <Box sx={{ mt: 3 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {Object.values(dnsItems).map((key, index) => (
                <Step key={key.label}>
                  <StepLabel
                    optional={
                      index === key.items.length - 1 ? (
                        <Typography variant="caption">{t('Last step')}</Typography>
                      ) : null
                    }
                    sx={{ fontSize: '24px', fontWeight: 'bold' }}
                  >
                    {key.label}
                  </StepLabel>
                  <StepContent>
                    {cnamesAdditionalComponent(key)}
                    <Box sx={{ display: 'flex', flexFlow: 'row wrap' }}>
                      {key.items.map((item) => (
                        <Box sx={{ width: 1 / 4 }}>{item}</Box>
                      ))}
                    </Box>
                    <Box key={key.label} sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={activeStep === Object.keys(dnsItems).length - 1 ? handleFinish : handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === Object.keys(dnsItems).length - 1 ? t('Finish') : t('Continue')}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                          {t('Back')}
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === Object.keys(dnsItems).length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>{t('Done - you have finished')}</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  {t('Reset')}
                </Button>
              </Paper>
            )}
          </Box>
        </ConditionalComponent>
      </ConditionalComponent>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={5000}
        message={t('Done! Wait for eventoom to check the records')}
      />
    </div>
  )
}

export default DomainScreen
