import { useMutation } from '@apollo/client'
import { Grid, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import { useRouter } from 'found'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import ScreenTitle from '../../components/screen/ScreenTitle'
import { Locale, mutateViewerMutation, mutateViewerMutationVariables, ViewerEditInput } from '../../generated/graphql'
import DH from '../../helpers/DH'
import { TZ_NAMES } from '../../helpers/DateHelper'
import { TimeZones } from '../../helpers/Locale'
import Auth from '../../helpers/auth'
import routes from '../../helpers/routes'
import { useUserContext } from '../../providers/UserContext'
import Viewer from '../../schema/Viewers'

const useStyles = makeStyles()((theme) => {
  return {
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
    },
    addSpace: {
      margin: '10px 0px',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      float: 'right',
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
  }
})
const AccountScreen: React.FC = () => {
  const { router } = useRouter()
  const { t } = useTranslation()
  const { classes } = useStyles()
  const user = useUserContext()
  const { enqueueSnackbar } = useSnackbar()
  const [pass, setPass] = useState('')
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = useState<ViewerEditInput>({
    name: user.getName(),
    email: user.getEmail(),
    locale: user.locale(),
    tzname: user.getTz(),
  })

  const [edit] = useMutation<mutateViewerMutation, mutateViewerMutationVariables>(Viewer.EDIT_VIEWER)
  const submit = async function (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    try {
      const input: mutateViewerMutationVariables = {
        input: value,
      }
      if (user.isOrganization()) {
        input.input.email = user.getEmail()
      }
      setLoading(true)
      await edit({ variables: input })
      setLoading(false)
      enqueueSnackbar('Changes have been saved', { variant: 'success' })
      if (value.email !== user.getEmail()) {
        Auth.logout()
        router.push(routes.login)
      }
    } catch (ex) {
      setLoading(false)
      enqueueSnackbar(DH.getGraphqlErrorMessages(ex).join('<br>'), { variant: 'error' })
    }
  }

  let extraPropsEmail = {}
  if (user.isOrganization()) {
    extraPropsEmail = {
      disabled: true,
      helperText: t('If you want to change your email contact the administrator of your organization.'),
    }
  }

  let extraPropsSaveButton = {}
  let extraPropsHelper = {}
  if (value.password && value.password !== pass) {
    extraPropsSaveButton = {
      disabled: true,
    }
    extraPropsHelper = {
      helperText: t('The password does not match'),
      color: 'error',
    }
  }
  const allLanguages = TimeZones

  return (
    <div>
      <Helmet>
        <title>{t('My Account')}</title>
      </Helmet>
      <Grid container component="main">
        <Grid item md={12} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <ScreenTitle>{t('My account')}</ScreenTitle>
            <Grid>
              <TextField
                className={classes.addSpace}
                variant="standard"
                fullWidth
                label={t('Name')}
                autoFocus
                name="name"
                type="text"
                value={value.name}
                onChange={(e) => setValue({ ...value, name: e.target.value })}
              />
              <TextField
                className={classes.addSpace}
                variant="standard"
                fullWidth
                label={t('Email address')}
                autoFocus
                name="email"
                type="email"
                value={value.email}
                onChange={(e) => setValue({ ...value, email: e.target.value })}
                {...extraPropsEmail}
              />
              <TextField
                className={classes.addSpace}
                variant="standard"
                label={t('Password')}
                placeholder="●●●●●●●●"
                fullWidth
                name="password"
                type="password"
                onChange={(e) => setValue({ ...value, password: e.target.value })}
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
              />
              {Boolean(value.password) && (
                <TextField
                  className={classes.addSpace}
                  variant="standard"
                  label={t('Repeat password')}
                  fullWidth
                  name="repeatPassword"
                  type="password"
                  value={pass}
                  onChange={(event) => setPass(event.target.value)}
                  autoComplete="off"
                  {...extraPropsHelper}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              <TextField
                className={classes.addSpace}
                variant="standard"
                label={t('Locale')}
                select
                value={value.locale}
                onChange={(e) => setValue({ ...value, locale: e.target.value as Locale })}
                fullWidth
              >
                {Object.keys(allLanguages).map((k) => (
                  <MenuItem key={k} value={allLanguages[k].apiCode}>
                    <ListItemText primary={allLanguages[k].timezone} />
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.addSpace}
                variant="standard"
                label={t('Time zone')}
                select
                fullWidth
                value={value.tzname}
                onChange={(e) => setValue({ ...value, tzname: e.target.value })}
              >
                {TZ_NAMES.map((l) => {
                  return (
                    <MenuItem value={l} key={l}>
                      {l}
                    </MenuItem>
                  )
                })}
              </TextField>
              <Button
                disabled={loading}
                type="submit"
                color="primary"
                className={classes.submit}
                onClick={submit}
                {...extraPropsSaveButton}
              >
                {loading ? t('Saving') : t('Save')}
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default AccountScreen
