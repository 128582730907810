import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { EntityNames } from '../../helpers/DataHelper'
import { AclAction, useUserContext } from '../../providers/UserContext'
import Message from '../Message'

interface Props {
  entityName: EntityNames
  denyComponent?: JSX.Element
  action: AclAction | AclAction[]
}

export const UserCanDo: FunctionComponent<Props> = (props) => {
  const user = useUserContext()
  // Only organization users has per entity/action ACL
  if (user.isOrganization() && !user.canDo(props.entityName, props.action)) {
    return props.denyComponent || null
  }
  return <>{props.children}</>
}

export const UserCanRead: FunctionComponent<Omit<Props, 'action'>> = (props) => {
  return (
    <UserCanDo {...props} action={AclAction.read}>
      {props.children}
    </UserCanDo>
  )
}

export const UserCanCreate: FunctionComponent<Omit<Props, 'action'>> = (props) => {
  return (
    <UserCanDo {...props} action={AclAction.create}>
      {props.children}
    </UserCanDo>
  )
}

export const UserCanCreateOrEdit: FunctionComponent<Omit<Props, 'action'>> = (props) => {
  return (
    <UserCanDo {...props} action={[AclAction.create, AclAction.update]}>
      {props.children}
    </UserCanDo>
  )
}

export const UserCanCreateOrEditScreen: FunctionComponent<Omit<Props, 'action'>> = (props) => {
  const { t } = useTranslation()
  return (
    <UserCanCreateOrEdit
      {...props}
      denyComponent={<Message type={'error'} message={t('You are not allowed to access this screen')} />}
    >
      {props.children}
    </UserCanCreateOrEdit>
  )
}
