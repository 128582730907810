import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CssBaseline from '@mui/material/CssBaseline'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useRouter } from 'found'
import { useSnackbar } from 'notistack'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Config from '../../Config'
import LinkBody2 from '../../components/navigation/LinkBody2'
import Auth from '../../helpers/auth'
import routes from '../../helpers/routes'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://source.unsplash.com/featured/?calendar)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    close: {
      padding: theme.spacing(0.5),
    },
  }
})

export default function LoginScreen() {
  const { t } = useTranslation()
  const { router } = useRouter()
  const { classes } = useStyles()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(Auth.getStayLogged())
  const { enqueueSnackbar } = useSnackbar()
  const [loggedIn, setLoggedIn] = useState(Auth.isLoggedIn())

  useEffect(() => {
    function syncLoging(event: any) {
      if (event.key === Config.LOGIN.LOGIN_STORAGE_KEY && localStorage.getItem(Config.LOGIN.LOGIN_STORAGE_KEY)) {
        Auth.refreshCookie()
        setLoggedIn(true)
      }
    }

    window.addEventListener('storage', syncLoging)
    return () => {
      window.removeEventListener('storage', syncLoging)
    }
  }, [router])

  useEffect(() => {
    if (loggedIn) {
      router.push(routes.app.index)
    }
  }, [loggedIn]) // eslint-disable-line react-hooks/exhaustive-deps

  const logIn = async (e: MouseEvent) => {
    e.preventDefault()
    try {
      if (await Auth.doLogin(username, password, rememberMe)) {
        setLoggedIn(true)
      } else {
        enqueueSnackbar(t('unknown_error'), { variant: 'error' })
      }
    } catch (error) {
      if (error.code === 'UNRECOGNIZED_USER') {
        enqueueSnackbar(
          t(
            "The provided credentials are invalid or the user does not exist. If you already have an account try the 'Forgot Password?' link below",
          ),
          { variant: 'error' },
        )
      } else if (error.code === 'INACTIVE_USER') {
        enqueueSnackbar(t('User is inactive'), { variant: 'error' })
      } else {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    }
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Log in')}
          </Typography>
          <form className={classes.form} noValidate>
            {/* https://github.com/mui-org/material-ui/issues/14427 */}
            <TextField
              autoFocus
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('Email Address')}
              name="email"
              autoComplete="email"
              type="email"
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('Password')}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(_event: object, checked: boolean) => setRememberMe(checked)}
                  value="remember"
                  color="primary"
                />
              }
              label={t('Remember me')}
            />
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              className={classes.submit}
              onClick={logIn}
              disabled={!username || !password}
            >
              {t('Log in')}
            </Button>
            <Grid container>
              <Grid item xs>
                <LinkBody2 to={routes.forgot_password}>{t('Forgot password?')}</LinkBody2>
              </Grid>
              <Grid item>
                {routes.register && <LinkBody2 to={routes.register}>{t("Don't have an account? Sign Up")}</LinkBody2>}
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}
