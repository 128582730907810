import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { green } from '@mui/material/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      'backgroundColor': green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }
})

interface Props extends ButtonProps {
  loading?: boolean
  success?: boolean
}

export default function SaveButton(props: Props) {
  const { loading, success, ...buttonProps } = props
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const buttonClassname = cx({
    [classes.buttonSuccess]: success,
  })

  return (
    <div className={classes.wrapper}>
      <Button color="primary" className={buttonClassname} {...buttonProps} disabled={props.loading || props.disabled}>
        {props.children || t('Save')}
      </Button>
      {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}
