// import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Sentry.init({
//   dsn: 'https://658257a7942a4ed2888671546f598797@sentry.io/3728638',
//   release: process.env.REACT_APP_RELEASE_VERSION,
//   environment: process.env.NODE_ENV,
// })

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
