import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaLibraryScreen from './MediaLibraryScreen'
import { UserCanRead } from '../../components/acl/UserCanDo'
import { EntityNames } from '../../helpers/DataHelper'

export interface MediaLibrarySelectorControlProps {
  closeFunction: (ev: any) => void
  id: string
  ownerNodeId?: string
  onChange(id?: string | null, file?: File): void
}

const MediaLibrarySelectorControl: React.FC<MediaLibrarySelectorControlProps> = (
  props: MediaLibrarySelectorControlProps,
) => {
  const { t } = useTranslation()
  const { onChange, closeFunction, ownerNodeId } = props

  return (
    <UserCanRead entityName={EntityNames.Media}>
      <Dialog
        open={true}
        fullWidth={true}
        onClose={closeFunction}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">{t('Select media')}</DialogTitle>
        <DialogContent>
          <MediaLibraryScreen
            closeDialog={closeFunction}
            onChange={onChange}
            selector={true}
            ownerNodeId={ownerNodeId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFunction} color="primary" autoFocus>
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </UserCanRead>
  )
}

export default MediaLibrarySelectorControl
