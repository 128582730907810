import { useMutation, useQuery } from '@apollo/client'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import I18n from 'i18next'
import { Column } from 'material-table'
import { useSnackbar } from 'notistack'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { AclTable } from '../../components/table/AclTable'
import {
  MediaDocumentTypeDataFragment,
  MediaDocumentTypeInput,
  mediaDocumentTypesQuery,
  mediaDocumentTypesQueryVariables,
  MutateMediaDocumentTypeMutation,
  MutateMediaDocumentTypeMutationVariables,
} from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { useDeleteMutation } from '../../helpers/GqlHelper'
import { slugify } from '../../helpers/Helpers'
import { requiredField } from '../../helpers/validations'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import Medias from '../../schema/Medias'

function uniqueCode(documentTypes: MediaDocumentTypeDataFragment[]) {
  return (rowData) => {
    if (rowData.code) {
      if (documentTypes.find((d) => d.id !== rowData.id && slugify(rowData.code) === d.code)) {
        return I18n.t('Code already exists')
      }
    }
    return true
  }
}

export default function DocumentTypesScreen() {
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const branchFilter = useBranchFilteredQuery()
  const [deleteMediaDocumentType] = useDeleteMutation(Medias.GET_DOCUMENT_TYPES, branchFilter.variables)
  const [mutateMediaDocumentType] = useMutation<
    MutateMediaDocumentTypeMutation,
    MutateMediaDocumentTypeMutationVariables
  >(Medias.MUTATE_DOCUMENT_TYPE, {
    refetchQueries: [{ query: Medias.GET_DOCUMENT_TYPES, variables: { ...branchFilter?.variables } }],
  })
  const { loading, error, data } = useQuery<mediaDocumentTypesQuery, mediaDocumentTypesQueryVariables>(
    Medias.GET_DOCUMENT_TYPES,
    branchFilter,
  )

  const [newDocumentTypeAdded, setNewDocumentTypeAdded] = useState(null)
  const allDocumentTypes = data?.mediaDocumentTypes
  const columns: Column<MediaDocumentTypeDataFragment>[] = useMemo(
    () => [
      {
        title: 'Name',
        field: 'name',
        validate: requiredField('name'),
        editComponent: (editProps) => {
          return (
            <TextField
              variant="standard"
              value={editProps.value || ''}
              label="Name"
              fullWidth
              onChange={(val) => {
                editProps.onChange(val.target.value)
              }}
            />
          )
        },
      },
      {
        title: 'Code',
        field: 'code',
        validate: uniqueCode(allDocumentTypes),
        editComponent: (editProps) => {
          return (
            <TextField
              variant="standard"
              value={editProps.value || ''}
              label="Code"
              fullWidth
              onChange={(val) => {
                editProps.onChange(val.target.value)
              }}
            />
          )
        },
      },
    ],
    [allDocumentTypes],
  )

  const deleteNode = async (id: string) => {
    try {
      await deleteMediaDocumentType({ variables: { input: { id } } })
    } catch (errorDelete) {
      let messageError: string
      if (errorDelete.message.includes('ER_ROW_IS_REFERENCED_2')) {
        messageError = t('This document type cannot be deleted because it is being used in some document.')
      } else {
        messageError = t('Cannot delete this document type.')
      }
      enqueueSnackbar(messageError, {
        variant: 'error',
        persist: true,
        action: (key) => (
          <Button variant="text" color="inherit" onClick={() => closeSnackbar(key)}>
            {t('Close')}
          </Button>
        ),
      })
    }
  }

  const mutateDocumentType = async (docType: MediaDocumentTypeInput) => {
    const response = await mutateMediaDocumentType({
      variables: {
        input: DataHelper.cleanInputData(docType),
      },
    })
    setNewDocumentTypeAdded(response.data.mutateMediaDocumentType)
    return true
  }

  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }

  return (
    <div>
      <Helmet>
        <title>{t('Document Types')}</title>
      </Helmet>
      <AclTable<MediaDocumentTypeDataFragment>
        entityName={EntityNames.MediaDocumentType}
        title={t('Document types')}
        columns={columns}
        options={{
          showTitle: false,
        }}
        localization={{
          body: { editRow: { deleteText: t('Are you sure you want to delete this document type?') } },
        }}
        data={allDocumentTypes}
        paginateToElement={newDocumentTypeAdded}
        editable={{
          onRowAdd: async (newData) => {
            await mutateDocumentType(newData)
          },
          onRowUpdate: async (newData) => {
            await mutateDocumentType(newData)
          },
          onRowDelete: async (oldData) => {
            await deleteNode(oldData.id)
          },
        }}
      />
    </div>
  )
}
