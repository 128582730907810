import { useQuery } from '@apollo/client'
import AddBox from '@mui/icons-material/AddBox'
import Edit from '@mui/icons-material/Edit'
import dayjs from 'dayjs'
import { useRouter } from 'found'
import { Column } from 'material-table'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { AclTable } from '../../components/table/AclTable'
import { EventImportDataFragment, eventImportsQuery, eventImportsQueryVariables } from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'
import { useDeleteMutation } from '../../helpers/GqlHelper'
import routes from '../../helpers/routes'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import EventImports from '../../schema/EventImports'

const columns: Column<EventImportDataFragment>[] = [
  { title: 'Name', field: 'name' },
  {
    title: 'Created at',
    field: 'createdAt',
    editable: 'never',
    type: 'datetime',
    render: (itemData) => {
      return dayjs(Number(itemData.createdAt)).format('DD/MM/YYYY HH:mm')
    },
  },
  { title: 'Status', field: 'state', editable: 'never' },
]

export default function ImportEventsScreen() {
  const { router } = useRouter()
  const { t } = useTranslation()
  const branchFilter = useBranchFilteredQuery()
  const [deleteEventImport] = useDeleteMutation(EventImports.GET_EVENT_IMPORTS, branchFilter.variables)
  const { loading, error, data } = useQuery<eventImportsQuery, eventImportsQueryVariables>(
    EventImports.GET_EVENT_IMPORTS,
    branchFilter,
  )

  const deleteNode = async (id: string) => {
    await deleteEventImport({ variables: { input: { id } } })
    return true
  }

  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  const eventImports = data.eventImports
  return (
    <div>
      <Helmet>
        <title>{t('Import events')}</title>
      </Helmet>
      <AclTable<EventImportDataFragment>
        customAddAction={{
          icon: () => <AddBox />,
          tooltip: t('New event import'),
          isFreeAction: true, // the action is not associated to specific rows
          onClick: () => {
            router.push(routes.app_settings_import_new)
          },
        }}
        customEditAction={{
          icon: () => <Edit />,
          tooltip: t('Edit event import'),
          onClick: (_event: any, rowData: EventImportDataFragment | EventImportDataFragment[]) => {
            let id
            if (Array.isArray(rowData)) {
              id = rowData[0].id
            } else {
              id = rowData.id
            }
            router.push(routes.app_settings_import_edit + '/' + id)
          },
        }}
        entityName={EntityNames.EventImport}
        title={t('Event imports')}
        columns={columns}
        options={{
          showTitle: false,
        }}
        localization={{
          body: { editRow: { deleteText: t('Are you sure you want to delete this import and all imported events?') } },
        }}
        data={eventImports}
        editable={{
          onRowDelete: async (oldData) => {
            if (oldData.id) {
              await deleteNode(oldData.id)
            }
          },
        }}
      />
    </div>
  )
}
