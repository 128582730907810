import { RouterState } from 'found'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import TemplateEditContent from './TemplateEditContent'
import CreateOrEditComponent from '../../components/CreateOrEditComponent'
import { notificationTemplateQuery } from '../../generated/graphql'
import NotificationTemplates from '../../schema/NotificationTemplates'

const TemplatesEditScreen: React.FC<RouterState> = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <title>{t(props.match.params.id ? 'Edit notification template' : 'New notification template')}</title>
      </Helmet>
      <CreateOrEditComponent<notificationTemplateQuery>
        query={NotificationTemplates.GET_NOTIFICATION_TEMPLATE}
        id={props.match.params.id}
        render={(data?: notificationTemplateQuery) => {
          return <TemplateEditContent template={data && data.notificationTemplate} />
        }}
      />
    </div>
  )
}

export default TemplatesEditScreen
