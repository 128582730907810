import { CanvasWidget } from '@projectstorm/react-canvas-core'
import { DagreEngine, DiagramEngine, DiagramModel } from '@projectstorm/react-diagrams'
import React, { useCallback, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

interface Props {
  engine: DiagramEngine
  model: DiagramModel
}

const useStyles = makeStyles()(() => {
  return {
    graphCanvas: {
      width: '100%',
      height: '100%',
      minHeight: 400,
    },
  }
})

const dagreEngine: DagreEngine = new DagreEngine({
  graph: {
    rankdir: 'LR',
    ranker: 'longest-path',
    marginx: 25,
    marginy: 25,
  },
  includeLinks: true,
})
const DagreDiagram: React.FC<Props> = (props) => {
  const { classes } = useStyles()

  const autoDistribute = useCallback(() => {
    dagreEngine.redistribute(props.model)
    // reroute only happens if pathFinding is enabled
    // reroute()
    props.engine.repaintCanvas()
  }, [props.model, props.engine])

  useEffect(() => {
    setTimeout(() => {
      autoDistribute()
    }, 1000)
  }, [autoDistribute])

  /*
  const reroute = useCallback(() => {
    props.engine
      .getLinkFactories()
      .getFactory<PathFindingLinkFactory>(PathFindingLinkFactory.NAME)
      .calculateRoutingMatrix()
  }, [props.engine])
  */
  return (
    <>
      <CanvasWidget className={classes.graphCanvas} engine={props.engine} />
    </>
  )
}

export default DagreDiagram
