import { useMutation, useQuery } from '@apollo/client'
import AddBox from '@mui/icons-material/AddBox'
import Edit from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import createEngine, {
  DefaultNodeModel,
  DefaultPortModel,
  DiagramEngine,
  DiagramModel,
  NodeModel,
} from '@projectstorm/react-diagrams'
import { filter } from 'graphql-anywhere'
import MaterialTable, { Column } from 'material-table'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DagreDiagram from './DagreDiagram'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import {
  CalendarEvent,
  EventInput,
  EventMutationMutation,
  EventMutationMutationVariables,
  PathwayItemDataFragment,
  PathwayItemInput,
  PathwayItemMutationMutation,
  PathwayItemMutationMutationVariables,
  pathwayItemsQuery,
} from '../../generated/graphql'
import DataHelper from '../../helpers/DataHelper'
import GqlHelper, { useDeleteMutation } from '../../helpers/GqlHelper'
import TableHelper from '../../helpers/TableHelper'
import CalendarEvents from '../../schema/CalendarEvents'
import PathwayItems from '../../schema/PathwayItems'
import Tags from '../../schema/Tags'
import EventDialog from '../events/EventDialog'

interface Props {
  pathwayId: string
}

function createNode(name: string, color?: string): DefaultNodeModel {
  return new DefaultNodeModel(name, color || 'rgb(68,138,255)')
}

function connectNodes(nodeFrom: NodeModel, nodeTo: NodeModel, diagramEngine?: DiagramEngine) {
  const portOut = nodeFrom.getPort('out') as DefaultPortModel
  const portTo = nodeTo.getPort('in') as DefaultPortModel

  // ################# UNCOMMENT THIS LINE FOR PATH FINDING #############################
  if (diagramEngine) {
    // return portOut.link(portTo, diagramEngine.getLinkFactories().getFactory(PathFindingLinkFactory.NAME));
  }
  // #####################################################################################
  return portOut.link(portTo)
}

const engine = createEngine()

const PathwayEventTab: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [editPathwayItem, setEditPathwayItem] = useState<PathwayItemDataFragment | null | undefined>(undefined)

  const [deleteItem] = useDeleteMutation(PathwayItems.GET_PATHWAY_ITEMS, {
    input: { id: props.pathwayId },
  })

  const [mutatePathwayItem] = useMutation<PathwayItemMutationMutation, PathwayItemMutationMutationVariables>(
    PathwayItems.EDIT_PATHWAY_ITEMS,
    {
      update: GqlHelper.getMutationReducer<PathwayItemMutationMutation, pathwayItemsQuery>(
        'mutatePathwayItem',
        PathwayItems.GET_PATHWAY_ITEMS,
        'pathwayItems',
        { input: { id: props.pathwayId } },
      ),
    },
  )

  const [mutateEvent] = useMutation<EventMutationMutation, EventMutationMutationVariables>(CalendarEvents.EDIT_EVENT)

  const model = useMemo(() => new DiagramModel(), [])

  const { loading, error, data, refetch } = useQuery<pathwayItemsQuery>(PathwayItems.GET_PATHWAY_ITEMS, {
    variables: { input: { id: props.pathwayId } },
  })

  const deleteNode = async (id: string) => {
    await deleteItem({ variables: { input: { id } } })
  }

  const createEvent = async (
    input: EventInput & Partial<CalendarEvent>,
    pathwayItem?: PathwayItemDataFragment | null,
  ) => {
    const { data: mutateEventResult } = await mutateEvent({
      variables: {
        input: {
          ...DataHelper.cleanInputData(input),
          tags: filter(Tags.fragment.TagData, input.tags ?? []),
        },
      },
    })
    if (mutateEventResult) {
      const pwInput: PathwayItemInput = {
        id_pathway: props.pathwayId,
        id_event: mutateEventResult.mutateEvent.id,
      }
      if (pathwayItem) {
        pwInput.id = pathwayItem.id
      }
      // After creating an event, create pathway item assigning the eventId
      await mutatePathwayItem({ variables: { input: pwInput } })
    }
  }

  const pathwayItems = useMemo(() => data?.pathwayItems || [], [data])

  const columns: Column<PathwayItemDataFragment>[] = [
    {
      title: 'Parent Event',
      field: 'parent',
      render: (pathwayItemData: PathwayItemDataFragment) => {
        const items = pathwayItems.filter((pwi) => !pathwayItemData.allChildrenIDs.includes(pwi.id))
        if (!items.length) {
          return t('No parent')
        }
        return (
          <Select
            variant="standard"
            displayEmpty={true}
            style={{ width: '100%' }}
            value={pathwayItemData.parent ? pathwayItemData.parent.id : ''}
            onChange={async (event: ChangeEvent<any>) => {
              const pwInput: PathwayItemInput = {
                id: pathwayItemData.id,
                id_pathway: pathwayItemData.pathway.id,
                id_event: pathwayItemData.event.id,
                id_parent_pathway: event.target.value,
              }
              await mutatePathwayItem({ variables: { input: pwInput } })
              await refetch({ input: { id: props.pathwayId } })
            }}
          >
            <MenuItem value={''}>{t('No parent')}</MenuItem>
            {items.map((pwi) => (
              <MenuItem key={pwi.id} value={pwi.id}>
                {pwi.event.title}
              </MenuItem>
            ))}
          </Select>
        )
      },
    },
    {
      title: 'Event',
      field: 'event',
      render: (pathwayItemData: PathwayItemDataFragment) => pathwayItemData.event && pathwayItemData.event.title,
    },
  ]

  useEffect(() => {
    const allNodes: { [pwi: string]: NodeModel } = pathwayItems.reduce((prev, pwi, idx) => {
      const node = createNode(pwi.event.title)
      node.setPosition(idx * 70, idx * 100)
      const portIn = new DefaultPortModel(true, 'in')
      const portOut = new DefaultPortModel(false, 'out')
      // Lock ports so they can't be editable
      portOut.setLocked(true)
      portIn.setLocked(true)
      // Add in & out ports to each node
      node.addPort(portIn)
      node.addPort(portOut)
      model.addNode(node)

      return {
        ...prev,
        [pwi.id]: node,
      }
    }, {})

    pathwayItems.forEach((pwi) => {
      if (pwi.parent) {
        const link = connectNodes(allNodes[pwi.parent.id], allNodes[pwi.id], engine)
        // nodeLinks.push(link)
        model.addLink(link)
      }
    })
  }, [model, pathwayItems])

  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading && !data) {
    return <Loading />
  }

  // 5) load model into engine
  engine.setModel(model)
  return (
    <div>
      <EventDialog
        open={editPathwayItem !== undefined}
        id={editPathwayItem && editPathwayItem.event.id}
        onClose={() => setEditPathwayItem(undefined)}
        commit={async (input: EventInput & Partial<CalendarEvent>) => {
          await createEvent(input, editPathwayItem)
        }}
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <MaterialTable
            title={t('Pathway nodes')}
            icons={TableHelper.getTableIcons()}
            options={{
              actionsColumnIndex: -1,
            }}
            columns={columns}
            data={pathwayItems.map((pw) => ({ ...pw }))}
            actions={[
              {
                icon: () => <AddBox />,
                tooltip: t('Add New Pathway'),
                isFreeAction: true, // the action is not associated to specific rows
                onClick: () => {
                  setEditPathwayItem(null)
                },
              },
              {
                icon: () => <Edit />,
                tooltip: t('Edit event'),
                onClick: (event, rowData) => {
                  if (Array.isArray(rowData)) {
                    if (rowData.length) {
                      setEditPathwayItem(rowData[0])
                    }
                  } else {
                    setEditPathwayItem(rowData)
                  }
                },
              },
            ]}
            editable={{
              isEditable: () => false,
              isDeletable: (rowData) => {
                return rowData.allChildrenIDs.length <= 1
              },
              onRowDelete: async (oldData) => {
                // Delete pahtway item
                if (oldData.id) {
                  await deleteNode(oldData.id)
                }
                // Delete related event
                if (oldData.event.id) {
                  await deleteNode(oldData.event.id)
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <CanvasWidget className={classes.graphCanvas} engine={engine}/> */}
          <DagreDiagram engine={engine} model={model} />
        </Grid>
      </Grid>
    </div>
  )
}

export default PathwayEventTab
