import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  MediaAccessUrlQuery,
  MediaAccessUrlQueryVariables,
  mediaQuery,
  mediaQueryVariables,
} from '../../generated/graphql'
import Medias from '../../schema/Medias'
import GraphqlError from '../GraphqlError'
import Loading from '../Loading'

interface Props {
  idMedia: string
  showPreview?: boolean
}

const useStyles = makeStyles()(() => {
  return {
    imageMediaPreview: {
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: '#EEEEEE',
      borderStyle: 'dashed',
      maxWidth: '100%',
      objectFit: 'contain',
    },
  }
})

export const MediaFieldView: React.FC<Props> = ({ idMedia, showPreview = false }) => {
  const { classes } = useStyles()
  const { loading, error, data } = useQuery<mediaQuery, mediaQueryVariables>(Medias.GET_MEDIA, {
    variables: { id: idMedia },
  })
  const {
    data: mediaUrlData,
    error: mediaUrlError,
    loading: mediaUrlLoading,
  } = useQuery<MediaAccessUrlQuery, MediaAccessUrlQueryVariables>(Medias.GET_ACCESS_URL, {
    variables: {
      id: idMedia,
    },
  })
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(true)
  if (error || mediaUrlError) {
    return <GraphqlError error={error || mediaUrlError} />
  }
  if (loading || mediaUrlLoading || !data || !mediaUrlData) {
    return <Loading variant="inline" />
  }
  const { filename, mimeType, name } = data.media
  const mediaName = name + (name !== filename ? ` [${filename}]` : '')
  const isImage = mimeType.split('/')[0] === 'image'
  return (
    <span>
      {showPreview && isImage ? (
        <a href={mediaUrlData.mediaAccessUrl.url} rel="noreferrer noopener" target="_blank">
          {isPreviewLoading && <Loading variant="inline" />}
          <img
            alt={mediaName}
            className={classes.imageMediaPreview}
            onLoad={() => setIsPreviewLoading(false)}
            src={mediaUrlData.mediaAccessUrl.url}
          />
        </a>
      ) : (
        <a href={mediaUrlData.mediaAccessUrl.url} rel="noreferrer noopener" target="_blank">
          {mediaName}
        </a>
      )}
    </span>
  )
}
