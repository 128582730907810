import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    speedDial: {
      'position': 'absolute',
      '&$directionUp, &$directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(3),
      },
      '&$directionDown, &$directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(3),
      },
    },
    directionUp: {},
    directionRight: {},
    directionDown: {},
    directionLeft: {},
  }
})

interface Props {
  actions: {
    icon: React.ReactNode
    name: string
    action(): void
  }[]
}

const SpeedDials: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const speedDialClassName = cx(classes.speedDial, classes.directionUp)
  return (
    <SpeedDial
      ariaLabel={t('Actions')}
      className={speedDialClassName}
      icon={<SpeedDialIcon />}
      onBlur={handleClose}
      onClick={handleOpen}
      onClose={handleClose}
      onFocus={handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      open={open}
      direction={'up'}
    >
      {props.actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={t(action.name)}
          title={t(action.name)}
          onClick={() => {
            handleClose()
            action.action()
          }}
        />
      ))}
    </SpeedDial>
  )
}

export default SpeedDials
