import { ApolloError } from '@apollo/client'
import { Color } from '@mui/material'
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors'
import dayjs from 'dayjs'

const DEFAULT_COLOR = '#00acc1'
const defaultColorIndex: keyof Color = 600

const defaultColors = {
  [amber[defaultColorIndex]]: amber,
  [blue[defaultColorIndex]]: blue,
  [blueGrey[defaultColorIndex]]: blueGrey,
  [brown[defaultColorIndex]]: brown,
  [cyan[defaultColorIndex]]: cyan,
  [deepOrange[defaultColorIndex]]: deepOrange,
  [deepPurple[defaultColorIndex]]: deepPurple,
  [green[defaultColorIndex]]: green,
  /* [grey[defaultColorIndex]]: grey, */
  [indigo[defaultColorIndex]]: indigo,
  [lightBlue[defaultColorIndex]]: lightBlue,
  [lightGreen[defaultColorIndex]]: lightGreen,
  [lime[defaultColorIndex]]: lime,
  [orange[defaultColorIndex]]: orange,
  [pink[defaultColorIndex]]: pink,
  [purple[defaultColorIndex]]: purple,
  [red[defaultColorIndex]]: red,
  [teal[defaultColorIndex]]: teal,
  [yellow[defaultColorIndex]]: yellow,
}

export default {
  defaultColorIndex,
  getColor: (hex: string): Color => {
    if (Object.prototype.hasOwnProperty.call(defaultColors, hex)) {
      return defaultColors[hex]
    }
    return red
  },
  getColorValue: (hex: string, index: keyof Color): string => {
    if (Object.prototype.hasOwnProperty.call(defaultColors, hex)) {
      return defaultColors[hex][index]
    }
    return red[index]
  },
  getDefaultColors() {
    return defaultColors
  },
  getDefaultColorHex(): string {
    return DEFAULT_COLOR
  },
  getDateFormat,
  getDateTimeFormat,
  isTimeFormat12h,
  getGraphqlErrorMessages: (error: ApolloError): string[] => {
    if (!Array.isArray(error.graphQLErrors) || !error.graphQLErrors.length) {
      return [error.message]
    }
    return error.graphQLErrors.map((e) => e.message)
  },
}

function getDateFormat(): string {
  return dayjs.localeData().longDateFormat('L')
}

function getTimeFormat(): string {
  return dayjs.localeData().longDateFormat('LT')
}

function getDateTimeFormat(): string {
  return `${getDateFormat()} ${getTimeFormat()}`
}

function isTimeFormat12h(): boolean {
  return getTimeFormat().includes('h') // 'H' is 24h format, 'h' is 12h format
}
