import { gql } from '@apollo/client'
import OwnedApiNodes from './OwnedApiNode'

const OrganizersData = gql`
  fragment OrganizersData on Organizer {
    ...OwnedApiNodeData
    name
    surname
    email
    location
    comments
    trainer
  }
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_ORGANIZERS = gql`
  query getOrganizers {
    organizers {
      ...OrganizersData
    }
  }
  ${OrganizersData}
`
const CREATE_ORGANIZER = gql`
  mutation CreateOrganizerMutation($input: OrganizerInput!) {
    addOrganizer(input: $input) {
      ...OrganizersData
    }
  }
  ${OrganizersData}
`
const EDIT_ORGANIZER = gql`
  mutation OrganizerMutation($input: OrganizerInput!) {
    mutateOrganizer(input: $input) {
      ...OrganizersData
    }
  }
  ${OrganizersData}
`

const Organizers = {
  GET_ORGANIZERS,
  CREATE_ORGANIZER,
  EDIT_ORGANIZER,
}

export default Organizers
