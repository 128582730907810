/* eslint-disable */
import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject, Observable } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { persistCache } from 'apollo3-cache-persist'
import Cookies, { CookieAttributes } from 'js-cookie'
import AuthError from './AuthError'
import Config from '../Config'
import { StrictTypedTypePolicies } from '../generated/apollo-helpers'
import fragmentTypes from '../generated/fragmentTypes.json'
import {
  LoginError,
  LoginToken,
  RefreshMutationMutation,
  RefreshMutationMutationVariables,
  viewerQuery,
} from '../generated/graphql'
import log from '../log'
import User from '../schema/User'
import Viewers from '../schema/Viewers'

const typePolicies: StrictTypedTypePolicies = {}

interface SessionCookie {
  [key: string]: string
}

let sessionCookie: SessionCookie | null = null

function delay(t: number, val?: any) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(val)
    }, t)
  })
}

export default class Auth {
  /**
   * Set to true when doing a refresh action to prevent multiple refresh calls
   */
  static doingRefresh = false

  static getSessionCookie(): SessionCookie {
    if (!sessionCookie) {
      sessionCookie = JSON.parse(Cookies.get(Config.LOGIN.COOKIE_NAME) || '{}')
      // Set cookie to extends expiration
      Auth.setSessionCookie(sessionCookie)
    }
    return sessionCookie || {}
  }

  static refreshCookie(): SessionCookie {
    sessionCookie = null
    return Auth.getSessionCookie()
  }

  static setSessionCookie(data: SessionCookie): SessionCookie {
    sessionCookie = data || {}
    const extra: CookieAttributes = {}
    // If stay logged set expiration date
    if (data[Config.LOGIN.STAY_LOGGED]) {
      extra.expires = 30
    }
    Cookies.set(Config.LOGIN.COOKIE_NAME, JSON.stringify(data), extra)
    return sessionCookie
  }

  static getAccessToken() {
    return Auth.getSessionCookie()[Config.LOGIN.ACCESS_TOKEN]
  }

  static getRefreshToken() {
    return Auth.getSessionCookie()[Config.LOGIN.REFRESH_TOKEN]
  }

  static getStayLogged() {
    return !!Auth.getSessionCookie()[Config.LOGIN.STAY_LOGGED]
  }

  static setTokens(accessToken: string, refreshToken: string, persistent = false) {
    const c = Auth.getSessionCookie()
    const ret = false
    c[Config.LOGIN.ACCESS_TOKEN] = accessToken
    c[Config.LOGIN.REFRESH_TOKEN] = refreshToken
    if (persistent) {
      c[Config.LOGIN.STAY_LOGGED] = '1'
    } else {
      delete c[Config.LOGIN.STAY_LOGGED]
    }
    Auth.setSessionCookie(c)
    localStorage.setItem(Config.LOGIN.LOGIN_STORAGE_KEY, JSON.stringify(c))
    return ret
  }

  static logout() {
    Auth.clearTokens()
    localStorage.clear()
    localStorage.setItem(Config.LOGIN.LOGOUT_STORAGE_KEY, '1')
    Cookies.remove(Config.LOGIN.COOKIE_NAME)
    Auth.setSessionCookie({})
    // noinspection JSIgnoredPromiseFromCall
    client.resetStore()
  }

  static clearTokens() {
    const c = Auth.getSessionCookie()
    delete c[Config.LOGIN.ACCESS_TOKEN]
    delete c[Config.LOGIN.REFRESH_TOKEN]
    Auth.setSessionCookie(c)
  }

  static isLoggedIn(): boolean {
    return !!Auth.getAccessToken()
  }

  static async doLogin(username: string, password: string, rememberMe: boolean) {
    const response = await client.mutate({
      mutation: User.LOGIN,
      variables: {
        input: {
          username,
          password,
          clientId: Config.CLIENT_ID,
        },
      },
    })
    if (response.data.loginToken.__typename === User.TYPE_LOGIN_ERROR) {
      const { error, errorDescription } = response.data.loginToken
      throw new AuthError(error, errorDescription)
    }
    const lt = response.data.loginToken
    Auth.setTokens(lt.accessToken, lt.refreshToken, rememberMe)
    // Force login refetch
    await client.query<viewerQuery>({
      query: Viewers.GET_VIEWER,
      fetchPolicy: 'network-only',
    })
    return true
  }

  static async refreshAccessToken(): Promise<string | void> {
    let notExecute = false
    while (this.doingRefresh) {
      notExecute = true
      await delay(100)
    }
    if (notExecute) {
      return this.getAccessToken()
    }
    this.doingRefresh = true
    // Grab the refresh token from the store
    const refreshToken = Auth.getRefreshToken()
    // Clear tokens to prevent infinite loop when check if logged in in the commit refresh token fetch
    Auth.clearTokens()
    try {
      if (refreshToken) {
        const ret = await Auth.doRefreshToken(refreshToken)
        this.doingRefresh = false
        return ret
      }
    } catch (e) {
      log.error(e)
    }
    this.doingRefresh = false
  }

  static async doRefreshToken(refreshToken: string): Promise<string | void> {
    try {
      // Clear tokens to prevent infinite loop when check if logged in in the commit refresh token fetch
      Auth.clearTokens()
      const clientId = Config.CLIENT_ID
      const ret = await client.mutate<RefreshMutationMutation, RefreshMutationMutationVariables>({
        mutation: User.REFRESH,
        variables: {
          input: {
            refreshToken,
            clientId,
          },
        },
      })
      const loginResponse = ret.data && ret.data.refreshToken
      if (loginResponse) {
        if (loginResponse.__typename === User.TYPE_LOGIN_TOKEN) {
          const lt = loginResponse as LoginToken
          if (lt.accessToken && lt.refreshToken && lt.expiresIn) {
            Auth.setTokens(lt.accessToken, lt.refreshToken, Auth.getStayLogged())
            return lt.accessToken
          }
        } else if (loginResponse.__typename === User.TYPE_LOGIN_ERROR) {
          const le = loginResponse as LoginError
          log.error(le.errorDescription)
        } else {
          log.error('unknown error')
        }
      } else {
        log.error('unknown error')
      }
    } catch (e) {
      log.error(e)
    }
    // If refresh fails do logout
    Auth.logout()
  }

  static async initClientCache() {
    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    await persistCache({
      cache,
      storage: window.localStorage as any,
    })
  }

  static getClient(): ApolloClient<NormalizedCacheObject> {
    return client
  }
}

const cache = new InMemoryCache({
  typePolicies,
  possibleTypes: fragmentTypes.possibleTypes,
  dataIdFromObject: (value) => value.id as string,
})

const authLink = setContext((_, { headers }) => {
  const token = Auth.getAccessToken()
  return {
    headers: {
      ...headers,
      'authorization': token ? `Bearer ${token}` : '',
      'Apollo-Require-Preflight': 'true',
    },
  }
})

const link = createUploadLink({
  uri: Config.API_GRAPHQL,
})

const client = new ApolloClient<NormalizedCacheObject>({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (err?.extensions?.code === 'jwt expired') {
            return new Observable((observer) => {
              Auth.refreshAccessToken()
                .then(() => {
                  // Retry last failed request
                  forward(operation).subscribe(observer)
                })
                .catch((error: any) => {
                  // No refresh or client token available, we force user to login
                  observer.error(error)
                  Auth.logout()
                })
            })
          } else if (err?.extensions?.code === 'INACTIVE_USER') {
            // If inactive user do logout
            Auth.logout()
          }
        }
      }
      if (networkError) {
        log.log(networkError)
      }
    }),
    authLink,
    link,
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-first',
    },
  },
})
