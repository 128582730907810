import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React from 'react'

export type OptionValue = { inputValue: string; text: string }

interface AutocompleteFieldProps {
  value: OptionValue
  options: OptionValue[]
  fieldLabel: string
  onChange: (OptionValue: string) => void
}

const filter = createFilterOptions<OptionValue>()

export default function AutocompleteField({ value, options, fieldLabel, onChange }: AutocompleteFieldProps) {
  return (
    <Autocomplete<OptionValue>
      value={value || null}
      options={options || []}
      renderOption={(props, option) => {
        return (
          <span {...props} key={option.inputValue}>
            {option.text}
          </span>
        )
      }}
      renderInput={(params) => {
        return <TextField key={params.id} variant="standard" {...params} label={fieldLabel} />
      }}
      getOptionLabel={(option: OptionValue) => {
        return option.text || ''
      }}
      isOptionEqualToValue={(option: OptionValue, selValue: OptionValue) => {
        return selValue?.inputValue === option?.inputValue
      }}
      onChange={(event, newValue: OptionValue) => {
        if (newValue?.inputValue) {
          onChange(newValue.inputValue)
        } else {
          // newValue is null
          onChange('')
        }
      }}
      filterOptions={filter}
    />
  )
}
