import { gql } from '@apollo/client'

const OrganizationBranchFragment = gql`
  fragment OrganizationBranchData on OrganizationBranch {
    id
    name
    parentId
    canBeDeleted
    canBeUpdated
  }
`

const GET_ORGANIZATION_BRANCH = gql`
  query organizationBranches {
    organizationBranches {
      ...OrganizationBranchData
    }
  }
  ${OrganizationBranchFragment}
`

const GET_USER_BRANCHES = gql`
  query userBranches {
    userBranches {
      ...OrganizationBranchData
    }
  }
  ${OrganizationBranchFragment}
`

const EDIT_ORGANIZATION_BRANCH = gql`
  mutation OrganizationBranchMutation($input: OrganizationBranchInput!) {
    mutateOrganizationBranch(input: $input) {
      ...OrganizationBranchData
    }
  }
  ${OrganizationBranchFragment}
`

export const OrganizationBranches = {
  GET_ORGANIZATION_BRANCH,
  EDIT_ORGANIZATION_BRANCH,
  GET_USER_BRANCHES,
  fragments: {
    OrganizationBranchFragment,
  },
}
