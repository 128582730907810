import { gql } from '@apollo/client'
import Medias from './Medias'
import OwnedApiNodes from './OwnedApiNode'

const EventImportData = gql`
  fragment EventImportData on EventImport {
    id
    name
    file {
      ...MediaListData
    }
    log
    state
    tzname
    dateFormat
    geolocateLocation
    ...OwnedApiNodeData
  }
  ${Medias.fragments.MediaListData}
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_EVENT_IMPORTS = gql`
  query eventImports($branchFilter: NodeBranchFilterInput) {
    eventImports(branchFilter: $branchFilter) {
      ...EventImportData
    }
  }
  ${EventImportData}
`

const EDIT_EVENT_IMPORT = gql`
  mutation EventImportMutation($input: EventImportInput!) {
    mutateEventImport(input: $input) {
      ...EventImportData
    }
  }
  ${EventImportData}
`

const DO_EVENT_IMPORT = gql`
  mutation DoEventImportMutation($id: ID!) {
    importEvents(eventImportId: $id) {
      ...EventImportData
    }
  }
  ${EventImportData}
`

const GET_EVENT_IMPORT = gql`
  query eventImport($id: ID!) {
    eventImport(id: $id) {
      ...EventImportData
    }
  }
  ${EventImportData}
`

const EventImports = {
  GET_EVENT_IMPORTS,
  EDIT_EVENT_IMPORT,
  GET_EVENT_IMPORT,
  DO_EVENT_IMPORT,
  fragments: {
    EventImportData,
  },
}

export default EventImports
