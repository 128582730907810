import { gql } from '@apollo/client'

const RecurrenceInput = gql`
  fragment RecurrenceInput on Recurrence {
    occurrences {
      id
      start
    }
    type
  }
`

const Recurrence = {
  fragments: {
    RecurrenceInput,
  },
}

export default Recurrence
