import { gql } from '@apollo/client'
import OwnedApiNodes from './OwnedApiNode'

const NotificationTemplateData = gql`
  fragment NotificationTemplateData on NotificationTemplate {
    ...OwnedApiNodeData
    id
    active
    content
    default
    description
    locales {
      id
      locale
      subject
      content
    }
    method
    subject
    title
    type
    code
  }
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_NOTIFICATION_TEMPLATE = gql`
  query notificationTemplate($id: ID!) {
    notificationTemplate(id: $id) {
      ...NotificationTemplateData
    }
  }
  ${NotificationTemplateData}
`

const GET_NOTIFICATION_TEMPLATES = gql`
  query notificationTemplates($branchFilter: NodeBranchFilterInput) {
    notificationTemplates(branchFilter: $branchFilter) {
      ...NotificationTemplateData
    }
  }
  ${NotificationTemplateData}
`

const GET_PUBLIC_NOTIFICATION_TEMPLATES = gql`
  query publicNotificationTemplates {
    publicNotificationTemplates {
      ...NotificationTemplateData
    }
  }
  ${NotificationTemplateData}
`

const EDIT_NOTIFICATION_TEMPLATES_DEFAULT = gql`
  mutation NotificationTemplateDefaultMutation($input: NotificationTemplateDefaultInput!) {
    mutateNotificationTemplateDefault(input: $input) {
      nodeId
      method
      type
    }
  }
`

const EDIT_NOTIFICATION_TEMPLATES = gql`
  mutation NotificationTemplateMutation($input: NotificationTemplateInput!) {
    mutateNotificationTemplate(input: $input) {
      ...NotificationTemplateData
    }
  }
  ${NotificationTemplateData}
`
const NotificationTemplates = {
  GET_NOTIFICATION_TEMPLATES,
  GET_NOTIFICATION_TEMPLATE,
  EDIT_NOTIFICATION_TEMPLATES,
  GET_PUBLIC_NOTIFICATION_TEMPLATES,
  EDIT_NOTIFICATION_TEMPLATES_DEFAULT,
  fragments: {
    NotificationTemplateData,
  },
}

export default NotificationTemplates
