import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AsyncButton from '../AsyncButton'

export interface ConfirmDialogData {
  title: string
  text: string
  okText?: string
  koText?: string

  onConfirm(): void

  onCancel?(): void
}

interface Props extends ConfirmDialogData {
  open: boolean
  children?: React.ReactNode

  onClose(): void
}

const ConfirmDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel || props.onClose} color="primary">
            {props.koText || t('Cancel')}
          </Button>
          <AsyncButton onClick={props.onConfirm} autoFocus>
            {props.okText || t('Ok')}
          </AsyncButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog
