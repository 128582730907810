import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import ReactGoogleMapLoader from 'react-google-maps-loader'
import ReactGooglePlacesSuggest from 'react-google-places-suggest'
import { useTranslation } from 'react-i18next'
import Config from '../../Config'

type LocationData = {
  lat?: string | null
  lng?: string | null
  location: string
  placeId?: string
  postalCode?: string
}

interface Props {
  location?: string | null
  setLocation(locationData: LocationData): void
}

type GeocodedPrediction = google.maps.GeocoderResult

const LocationField: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [locationSearch, setLocationSearch] = useState('')
  const { location, setLocation } = props
  const handleLocationSelectSuggest = (geocodedPrediction: GeocodedPrediction) => {
    const { address_components: addressComponents } = geocodedPrediction
    const postalCode = addressComponents.find(({ types }) => types.includes('postal_code'))?.short_name
    setLocationSearch('')
    setLocation({
      location: geocodedPrediction.formatted_address,
      lat: geocodedPrediction.geometry.location.lat().toString(),
      lng: geocodedPrediction.geometry.location.lng().toString(),
      placeId: geocodedPrediction.place_id,
      postalCode,
    })
  }
  const handleLocationInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>,
  ) => {
    const value = e.target.value
    setLocationSearch(value)
    setLocation({
      location: value,
      lat: null,
      lng: null,
    })
  }

  return (
    <ReactGoogleMapLoader
      params={{
        key: Config.GOOGLE_API_KEY,
        libraries: 'places,geometry',
      }}
      render={(googleMaps, error) =>
        googleMaps ? (
          <div>
            {/* //! Show a custom error if SDK Authentication Error. See N/B 2 below. */}
            {error || (
              <ReactGooglePlacesSuggest
                autocompletionRequest={{ input: locationSearch }}
                googleMaps={googleMaps}
                onSelectSuggest={handleLocationSelectSuggest}
              >
                <TextField
                  label={t('Location')}
                  variant="standard"
                  type="text"
                  value={location || ''}
                  onChange={handleLocationInputChange}
                  fullWidth
                />
              </ReactGooglePlacesSuggest>
            )}
          </div>
        ) : (
          <div>
            {/* //! Check for network error so loading state ends if user lost connection. */}
            {error === 'Network Error' ? <p>{error}</p> : <p>{'isLoading...'}</p>}
          </div>
        )
      }
    />
  )
}

export default LocationField
