import { useMutation, useQuery } from '@apollo/client'
import { TabContext, TabPanel } from '@mui/lab'
import { Paper, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useRouter } from 'found'
import LocaleCode from 'locale-code'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import TemplateDataForm, { TemplateDataFormHandlerType } from './TemplateDataForm'
import AsyncButton from '../../components/AsyncButton'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import {
  constantsQuery,
  Locale,
  NotificationTemplateDataFragment,
  NotificationTemplateInput,
  NotificationTemplateLocaleInput,
  NotificationTemplateMutationMutation,
  NotificationTemplateMutationMutationVariables,
  notificationTemplatesQuery,
  NotificationType,
} from '../../generated/graphql'
import DataHelper from '../../helpers/DataHelper'
import GqlHelper from '../../helpers/GqlHelper'
import routes from '../../helpers/routes'
import Constants from '../../schema/Constants'
import NotificationTemplates from '../../schema/NotificationTemplates'

const useStyles = makeStyles()((theme) => {
  return {
    tabsPaper: {
      flex: 1,
      marginBottom: theme.spacing(1),
    },
    bottomButtons: {
      justifyContent: 'flex-end',
      display: 'flex',
      marginTop: theme.spacing(5),
    },
    withContentTabLabel: {
      color: 'black',
    },
  }
})

interface Props {
  template?: NotificationTemplateDataFragment
}

function getInitialState(template?: NotificationTemplateDataFragment): Partial<NotificationTemplateInput> {
  return {
    id: template?.id,
    active: template?.active ?? true,
    content: template?.content || '',
    description: template?.description || '',
    subject: template?.subject || '',
    code: template?.code || '',
    title: template?.title || '',
    template_locales:
      template?.locales.map((l): NotificationTemplateLocaleInput => {
        return {
          id: l.id,
          content: l.content,
          locale: l.locale,
          subject: l.subject,
        }
      }) || [],
    type: template?.type,
  }
}

const TemplateEditContent: React.FC<Props> = (props) => {
  const { classes } = useStyles()
  const { router } = useRouter()
  const { t } = useTranslation()
  const [state, setState] = useState<Partial<NotificationTemplateInput>>(getInitialState(props.template))
  const [error, setError] = React.useState()
  const [selectedTab, setSelectedTab] = React.useState('default')
  const handleTabChange = (event: React.ChangeEvent, newValue: string) => {
    setSelectedTab(newValue)
  }
  const {
    loading: constantsLoading,
    error: constantsError,
    data: constantsData,
  } = useQuery<constantsQuery>(Constants.GET_CONSTANTS)
  const [mutateTemplate] = useMutation<
    NotificationTemplateMutationMutation,
    NotificationTemplateMutationMutationVariables
  >(NotificationTemplates.EDIT_NOTIFICATION_TEMPLATES, {
    update: GqlHelper.getMutationReducer<NotificationTemplateMutationMutation, notificationTemplatesQuery>(
      'mutateNotificationTemplate',
      NotificationTemplates.GET_NOTIFICATION_TEMPLATES,
      'notificationTemplates',
    ),
  })
  const editTemplate = async (template: NotificationTemplateInput) => {
    try {
      setError(undefined)
      await mutateTemplate({
        variables: { input: DataHelper.cleanInputData(template) },
      })
      router.push(routes.app_settings_templates)
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    setState(getInitialState(props.template))
  }, [props.template])

  const handleChange = (attrName: keyof NotificationTemplateInput, value: any) => {
    setState({ ...state, [attrName]: value })
  }

  const handleContentChange: TemplateDataFormHandlerType = (locale, attrName, value) => {
    if (locale === 'default') {
      handleChange(attrName, value)
    } else {
      const template_locales = [...state.template_locales]
      let tc: NotificationTemplateLocaleInput = template_locales.find((tl) => tl.locale === locale)
      if (!tc) {
        tc = { locale, content: '', subject: '' }
        template_locales.push(tc)
      }
      tc[attrName] = value
      if (!tc.content && !tc.subject) {
        template_locales.splice(template_locales.indexOf(tc))
      }
      handleChange('template_locales', template_locales)
    }
  }

  if (constantsError) {
    return <GraphqlError error={constantsError} />
  }

  if (constantsLoading || !constantsData) {
    return <Loading />
  }
  const locales = constantsData.constants.locales.reduce((r, v) => {
    r[v] = LocaleCode.getLanguageName(v.replace('_', '-')) + ' (' + LocaleCode.getCountryName(v.replace('_', '-')) + ')'

    return r
  }, {} as { [key: string]: string })

  let createOnlyFields

  // Added ts-ignore because TS warnings about error being undefined, but ignores the use of ?
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasDupError = error?.message.includes('ER_DUP_ENTRY')

  if (state.id) {
    const type = constantsData.constants.notificationTypes.find((nt) => nt.key === state.type)
    createOnlyFields = (
      <>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label={t('Type')}
            type="text"
            value={(type && type.value) || ''}
            fullWidth
            disabled={true}
          />
        </Grid>
      </>
    )
  } else {
    createOnlyFields = (
      <>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            select
            label={t('Type')}
            required
            fullWidth
            value={state.type || ''}
            onChange={(e) => {
              const value = e.target.value as NotificationType
              handleChange('type', value)
            }}
            inputProps={{
              name: 'notification-type-id',
              id: 'notification-type-id',
            }}
          >
            {constantsData.constants.notificationTypes.map((cd) => (
              <MenuItem key={cd.key} value={cd.key}>
                <ListItemText primary={cd.value} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </>
    )
  }
  return (
    <div>
      <Grid container alignItems="stretch">
        {createOnlyFields}
        <Grid item xs={12} sm={6} paddingLeft={3}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={!!state.active}
                  onChange={(e) => handleChange('active', e.target.checked)}
                  value="Active"
                  color="primary"
                />
              }
              label={t('Active')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label={t('Title')}
            required
            type="text"
            value={state.title}
            onChange={(e) => handleChange('title', e.target.value)}
            fullWidth
          />
          <TextField
            variant="standard"
            label={t('Code')}
            required
            type="text"
            value={state.code}
            onChange={(e) => handleChange('code', e.target.value)}
            fullWidth
            error={hasDupError}
            helperText={hasDupError && 'Code already exists'}
          />
        </Grid>
        <Grid item xs={12} sm={6} paddingLeft={3}>
          <TextField
            variant="standard"
            label={t('Description')}
            type="text"
            value={state.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper square className={classes.tabsPaper}>
            <TabContext value={selectedTab}>
              <Tabs
                value={selectedTab}
                variant="scrollable"
                scrollButtons
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                allowScrollButtonsMobile
              >
                <Tab label="Default" value="default" classes={{ textColorPrimary: classes.withContentTabLabel }} />
                {Object.keys(locales).map((l) => {
                  const template = state.template_locales.find((tc) => tc.locale === l)
                  const className = template?.subject && template?.content ? classes.withContentTabLabel : null
                  return <Tab key={l} label={locales[l]} value={l} classes={{ textColorPrimary: className }} />
                })}
              </Tabs>
              <TabPanel value={'default'}>
                <TemplateDataForm
                  locale={'default'}
                  subject={state.subject}
                  content={state.content}
                  handleChange={handleContentChange}
                />
              </TabPanel>
              {Object.keys(locales).map((l: Locale) => (
                <TabPanel value={l} key={l}>
                  <TemplateDataForm
                    locale={l}
                    subject={state.template_locales.find((tc) => tc.locale === l)?.subject}
                    content={state.template_locales.find((tc) => tc.locale === l)?.content}
                    handleChange={handleContentChange}
                  />
                </TabPanel>
              ))}
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.bottomButtons}>
        <Button onClick={() => router.push(routes.app_settings_templates)} color="primary">
          {t('Cancel')}
        </Button>
        <AsyncButton
          type="submit"
          onClick={() => state && editTemplate(state as NotificationTemplateInput)}
          disabled={!state.title || !state.type || !state.subject || !state.content || !state.code}
        />
      </div>
    </div>
  )
}

export default TemplateEditContent
