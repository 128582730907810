import { gql } from '@apollo/client'
import OwnedApiNodes from './OwnedApiNode'
import Tags from './Tags'

const PathwayDataFragment = gql`
  fragment PathwayData on Pathway {
    id
    title
    description
    accessType
    start
    active
    registrationRequired
    location
    lat
    lng
    tags {
      ...TagData
    }
    ...OwnedApiNodeData
  }
  ${Tags.fragment.TagData}
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_PATHWAY = gql`
  query pathway($id: ID!) {
    pathway(id: $id) {
      ...PathwayData
    }
  }
  ${PathwayDataFragment}
`

const GET_PATHWAYS = gql`
  query pathways($branchFilter: NodeBranchFilterInput) {
    pathways(branchFilter: $branchFilter) {
      ...PathwayData
    }
  }
  ${PathwayDataFragment}
`

const EDIT_PATHWAY = gql`
  mutation PathwayMutation($input: PathwayInput!) {
    mutatePathway(input: $input) {
      ...PathwayData
    }
  }
  ${PathwayDataFragment}
`

const Pathways = {
  GET_PATHWAYS,
  GET_PATHWAY,
  EDIT_PATHWAY,
  fragments: {
    PathwayData: PathwayDataFragment,
  },
}

export default Pathways
