import { gql } from '@apollo/client'

const OrganizationProfileData = gql`
  fragment OrganizationProfileData on Profile {
    id
    name
    email
    locale
    active
    aclRoles {
      id
      name
    }
    organizationBranch {
      id
      name
    }
    user {
      id
      name
    }
    creator {
      id
      name
    }
    branch {
      id
      name
    }
    shareWithChildBranches
    createdAt
    updatedAt
    canBeDeleted
    canBeUpdated
  }
`

const GET_ORGANIZATION_USERS = gql`
  query organizationUsers($branchFilter: NodeBranchFilterInput) {
    organizationUsers(branchFilter: $branchFilter) {
      ...OrganizationProfileData
    }
  }
  ${OrganizationProfileData}
`

const CREATE_ORGANIZATION_USER = gql`
  mutation CreateProfileMutation($input: NewOrganizationUserInput!) {
    createOrganizationUser(input: $input) {
      ...OrganizationProfileData
    }
  }
  ${OrganizationProfileData}
`

const EDIT_ORGANIZATION_USER = gql`
  mutation ProfileMutation($input: OrganizationUserEditInput!) {
    mutateOrganizationUser(input: $input) {
      ...OrganizationProfileData
    }
  }
  ${OrganizationProfileData}
`

const VALIDATE_USER_EMAIL = gql`
  mutation ValidateEmail($input: String!) {
    validateEmail(input: $input) {
      email
      setPasswordToken
    }
  }
`

const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($input: String!) {
    requestPasswordReset(input: $input)
  }
`

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      email
    }
  }
`

const Profiles = {
  GET_ORGANIZATION_USERS,
  CREATE_ORGANIZATION_USER,
  EDIT_ORGANIZATION_USER,
  fragments: {
    OrganizationProfileData,
  },
  VALIDATE_USER_EMAIL,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
}

export default Profiles
