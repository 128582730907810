import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import MediaField from './MediaField'

interface Props {
  fullWidth?: boolean
  id?: string
  idMedia: string | undefined
  onChange: (id: string | undefined) => void
}

const useStyles = makeStyles()((theme) => {
  return {
    inputLabelRoot: {
      position: 'static',
    },
    imageMediaInput: {
      marginTop: theme.spacing(4),
    },
    imageMediaSelected: {
      marginTop: 0,
    },
  }
})

const ImageMediaField: React.FC<Props> = (props: Props) => {
  const { fullWidth = false, id, idMedia, onChange } = props
  const { classes } = useStyles()
  const { t } = useTranslation()

  const labelId = id ? `${id}-label` : undefined

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel classes={{ root: classes.inputLabelRoot }} htmlFor={id} id={labelId} shrink={!!idMedia}>
        {t('Upload featured image')}
      </InputLabel>
      <div className={idMedia ? classes.imageMediaSelected : classes.imageMediaInput}>
        <MediaField id={id} idMedia={idMedia} notDeleteMedia={true} onChange={onChange} showPreview={true} />
      </div>
    </FormControl>
  )
}

export default ImageMediaField
