import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import IconButton from '@mui/material/IconButton'
import SnackbarContent, { SnackbarContentProps } from '@mui/material/SnackbarContent'
import { amber, green } from '@mui/material/colors'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const variantIcon = {
  success: CheckCircleIcon,
  warningText: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles()((theme) => {
  return {
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }
})

export interface Props extends SnackbarContentProps {
  className?: string
  message?: string | React.ReactNode
  onClose?: () => void
  type: keyof typeof variantIcon
}

export default function Message(props: Props) {
  const { classes, cx } = useStyles()
  const { className, message, onClose, type, ...other } = props
  const Icon = variantIcon[type]
  let action
  if (props.onClose) {
    action = [
      <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} size="large">
        <CloseIcon className={classes.icon} />
      </IconButton>,
    ]
  }
  return (
    <SnackbarContent
      className={cx(classes[type], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={cx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={action}
      {...other}
    />
  )
}
