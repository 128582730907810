import AddBox from '@mui/icons-material/AddBox'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import Check from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Search from '@mui/icons-material/Search'
import ViewColumn from '@mui/icons-material/ViewColumn'
import { Icons } from 'material-table'
import React, { forwardRef } from 'react'

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const TableHelper = {
  getTableIcons(): Icons {
    return tableIcons
  },
}
export default TableHelper

export type WithTableData = { tableData?: { id?: number } }

export function getTableElementIndex<E extends WithTableData>(element: E): number | false {
  if ('tableData' in element && 'id' in element.tableData) {
    const oldIndex = element.tableData.id
    if (oldIndex >= 0) {
      return oldIndex
    }
  }
  return false
}

export function updateTableElement<E extends WithTableData>(newElement: E, oldElement: E, elements: E[]): E[] {
  const oldIndex = getTableElementIndex(oldElement)
  if (oldIndex !== false) {
    const newElements = [...elements]
    newElements[oldIndex] = newElement
    return newElements
  }
  return elements
}

export function deleteTableElement<E extends WithTableData>(element: E, elements: E[]): E[] {
  const oldIndex = getTableElementIndex(element)
  if (oldIndex !== false) {
    const newElements = [...elements]
    newElements.splice(oldIndex, 1)
    return newElements
  }
  return elements
}
