import { useMutation } from '@apollo/client'
import { useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AsyncButton from '../../components/AsyncButton'
import GraphqlError from '../../components/GraphqlError'
import {
  AttendeeMutationMutation,
  AttendeeMutationMutationVariables,
  attendeesQuery,
  AttendeeType,
  EventAttendeeDataFragment,
  EventAttendeeInput,
} from '../../generated/graphql'
import GqlHelper from '../../helpers/GqlHelper'
// import { getAllLanguagesFromISO } from '../../helpers/Languages'
import { TimeZones } from '../../helpers/Locale'
import EventAttendees from '../../schema/EventAttendees'

interface Props {
  open: boolean
  eventAttendee?: EventAttendeeDataFragment
  eventId: string
  language?: string

  onClose(): void
}

function getInitialState(props: Props, forceReset = false): EventAttendeeInput {
  if (props.eventAttendee && !forceReset) {
    return {
      id: props.eventAttendee.id,
      type: props.eventAttendee.type,
      eventId: props.eventAttendee.event.id,
      message: props.eventAttendee.message,
      email: props.eventAttendee.profile.email,
      language: props.language,
    }
  }
  return {
    type: AttendeeType.Invited,
    eventId: props.eventId,
    message: '',
    email: '',
    language: props.language,
  }
}

const EventAttendeeDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  // eslint-disable-next-line
  const [mutateAttendee, { loading, error }] = useMutation<AttendeeMutationMutation, AttendeeMutationMutationVariables>(
    EventAttendees.EDIT_ATTENDEE,
    {
      update: GqlHelper.getMutationReducer<AttendeeMutationMutation, attendeesQuery>(
        'mutateEventAttendee',
        EventAttendees.GET_ATTENDEES,
        'eventAttendees',
        { eventId: props.eventId },
      ),
    },
  )
  const editAttendee = async (input: EventAttendeeInput) => {
    await mutateAttendee({ variables: { input } })
  }
  const [state, setState] = useState(getInitialState(props))
  const createEventType = async () => {
    try {
      await editAttendee(state)
      setState(getInitialState(props, true))
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const close = () => {
    setState(getInitialState(props, true))
    onClose()
  }
  // const allLanguages = useMemo(() => getAllLanguagesFromISO(), [])
  const allLanguages = TimeZones

  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" maxWidth="md" fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">
        {props.eventAttendee ? t('Edit event attendee') : t('Add new attendee')}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          label={t('Attendee Email')}
          placeholder={t('Email to which we will send the invitation')}
          type="email"
          value={state.email || ''}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          fullWidth
        />
        <FormControl>
          <InputLabel htmlFor="select-multiple-checkbox">{t('Timezone')}</InputLabel>
          <Select
            variant="standard"
            value={state.language || ''}
            onChange={(event) => setState({ ...state, language: event.target.value as string })}
            input={<Input id="select-multiple-checkbox" />}
            displayEmpty={true}
            renderValue={(selected) => {
              const indexx = allLanguages.findIndex((locale) => locale.code === selected)
              return allLanguages[indexx]?.timezone || ''
            }}
          >
            {Object.keys(allLanguages).map((k) => (
              <MenuItem key={k} value={allLanguages[k].code || ''}>
                <ListItemText primary={allLanguages[k].timezone} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <TextField
          label={t('Message')}
          placeholder={t('Write a custom notification message')}
          type="text"
          multiline
          value={state.message || ''}
          onChange={(e) => setState({ ...state, message: e.target.value })}
          fullWidth
        /> */}
        <GraphqlError error={error} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {t('Cancel')}
        </Button>
        <AsyncButton onClick={createEventType} disabled={!state.email} />
      </DialogActions>
    </Dialog>
  )
}

export default EventAttendeeDialog
