import Config from '../Config'

export default {
  email_validation: '/validate-email',
  email_validation_param: '/validate-email/:validationToken',
  forgot_password: '/forgot',
  reset_password: '/reset-password/:token',
  invite_pacient_response: '/external_actions/invite_pacient_response/:tokenRecieved',
  register: Config.OPEN_USER_REGISTER ? '/register' : undefined,

  login: '/',
  app: {
    index: '/app',
    account: '/account',
    download: '/download',
    settings: {
      index: '/settings',
      types: '/types',
      users: '/users',
      roles: '/roles',
      document_types: '/document_types',
      media_library: '/media_library',
      templates: {
        index: '/templates',
        edit: '/edit',
        new: '/new',
      },
      pathways: {
        index: '/pathways',
        edit: '/edit',
        new: '/new',
      },
      import: {
        index: '/import',
        edit: '/edit',
        new: '/new',
      },
      export: '/export',
      organization: '/organization',
      organizer: '/organizer',
      domain: '/domain',
    },
  },
  app_settings: '/app/settings',
  app_account: '/app/account',
  app_download: '/app/download',
  app_settings_types: '/app/settings/types',
  app_settings_users: '/app/settings/users',
  app_settings_document_types: '/app/settings/document_types',
  app_settings_media_library: '/app/settings/media_library',
  app_settings_roles: '/app/settings/roles',
  app_settings_templates: '/app/settings/templates',
  app_settings_pathways: '/app/settings/pathways',
  app_settings_pathways_edit: '/app/settings/pathways/edit',
  app_settings_pathways_new: '/app/settings/pathways/new',
  app_settings_templates_edit: '/app/settings/templates/edit',
  app_settings_templates_new: '/app/settings/templates/new',
  app_settings_export: '/app/settings/export',
  app_settings_import: '/app/settings/import',
  app_settings_import_new: '/app/settings/import/new',
  app_settings_import_edit: '/app/settings/import/edit',
  app_settings_organization: '/app/settings/organization',
  app_settings_organizer: '/app/settings/organizer',
  app_settings_domain: '/app/settings/domain',
}
