import { useQuery } from '@apollo/client'
import { TextareaAutosize, TextField } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React, { useMemo, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import GraphqlError from '../../components/GraphqlError'
import { Locale, mediaDocumentTypesQuery, mediaDocumentTypesQueryVariables } from '../../generated/graphql'
import { useUserContext } from '../../providers/UserContext'
import Medias from '../../schema/Medias'

const useStyles = makeStyles()((theme) => {
  return {
    textArea: {
      minHeight: 300,
      border: '1px solid #F1F1F1',
      padding: 5,
      flexGrow: 1,
      width: '100%',
      // height: 'auto',
      // maxHeight: 700,
      // minRows: '500',
      resize: 'none',
      marginBottom: theme.spacing(2),
    },
    editor: {
      minHeight: 480,
      border: '1px solid #F1F1F1',
      padding: 5,
      flexGrow: 1,
      height: 'auto',
      marginBottom: theme.spacing(2),
    },
    editorBlock: {
      // height: '100%',
      cursor: 'text',
      display: 'flex',
      flexDirection: 'column',
    },
  }
})

const TEMPLATE_VARS: { [key: string]: string } = {
  'email': 'Attendee email',
  'locale': 'Attendee locale',
  'profile.name': 'Attendee name',
  'profile.tzname': 'Attendee timezone',
  'attendee.type': 'Attendee type (invited, attendee)',
  'attendee.confirmed': 'Invited attendee confirmed',
  'attendee.attended': 'Attendee assistance confirmed',
  'attendee.message': 'Attendee invitation message',
  'event.start': 'Event start unix time',
  'event.end': 'Event end unix time',
  'dates.long.start': 'Event start in user TZ with long format',
  'dates.long.end': 'Event end in user TZ with long format',
  'dates.time24h.start': 'Event start hour in user TZ with 24h format',
  'dates.time24h.end': 'Event end hour in user TZ with 24h format',
  'pacientInvite.responseLink': 'Event invitation link',
  'event.isFinished': 'Event is finished',
  'event.title': 'Event title',
  'event.description': 'Event description',
  'event.accessType': 'Event access type (private, public, invitation)',
  'event.location': 'Event location string',
  'event.lat': 'Event location lat',
  'event.lng': 'Event location lng',
  'event.locationName': 'Location Name',
  'organizers.names': 'Organizers Name',
  'organizers.emails': 'Organizers Emails',
  'organizers.namesandemails': 'Organizes Name: Email',
}

function getEditorState(content?: string) {
  const blocksFromHtml = htmlToDraft(content || '')
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  return EditorState.createWithContent(contentState)
}

export type TemplateDataFormHandlerType = (
  locale: Locale | 'default',
  attrName: 'content' | 'subject',
  value: string,
) => void

export interface TemplateDataFormProps {
  subject?: string
  content?: string
  locale: Locale | 'default'
  handleChange: TemplateDataFormHandlerType
}

export default function TemplateDataForm(props: TemplateDataFormProps) {
  const { subject, content, locale, handleChange } = props
  const { error, data } = useQuery<mediaDocumentTypesQuery, mediaDocumentTypesQueryVariables>(Medias.GET_DOCUMENT_TYPES)
  const docTypes = data?.mediaDocumentTypes
  const vars = useMemo(() => {
    return (
      docTypes?.reduce(
        (acc, item) => {
          acc[`docs.${item.code}.url`] = item.name
          return acc
        },
        { ...TEMPLATE_VARS },
      ) || TEMPLATE_VARS
    )
  }, [docTypes])
  const { t } = useTranslation()
  const { classes } = useStyles()
  const user = useUserContext()
  const [showEditor, setShowEditor] = useState(false)
  const [editorState, setEditorState] = useState(getEditorState(content))
  const onEditorStateChange = (newEditorState: any) => {
    handleChange(locale, 'content', draftToHtml(convertToRaw(newEditorState.getCurrentContent())))
    setEditorState(newEditorState)
  }
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12} md={8} sm={6}>
        <GraphqlError error={error} />
        <TextField
          variant="standard"
          required
          label={t('Subject')}
          type="text"
          value={subject}
          onChange={(e) => handleChange(locale, 'subject', e.target.value)}
          fullWidth
        />

        {/* <Grid item xs={4} sm={6}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="locale-id">{t('Locale')}</InputLabel>
          <Select
            required
            fullWidth
            value={locale || ''}
            onChange={(e) => {
              const value = e.target.value as Locale
              handleChange('locale', value)
            }}
            inputProps={{
              name: 'locale-id',
              id: 'locale-id',
            }}
          >
            {constantsData.constants.locales.map((locale) => (
              <MenuItem key={locale} value={locale}>
                <ListItemText primary={locales[locale]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}

        <FormLabel required>{t('Content')}</FormLabel>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={showEditor}
                onChange={(e) => {
                  const show = e.target.checked
                  if (show) {
                    setEditorState(getEditorState(content))
                  }
                  setShowEditor(show)
                }}
              />
            }
            label={t('Use WYSIWYG editor')}
          />
        </div>
        {showEditor && (
          <Editor
            localization={{
              locale: user.locale().split('_')[0],
            }}
            editorState={editorState}
            editorClassName={classes.editor}
            wrapperClassName={classes.editorBlock}
            onEditorStateChange={onEditorStateChange}
          />
        )}
        {!showEditor && (
          <TextareaAutosize
            className={classes.textArea}
            value={content}
            minRows={2}
            onChange={(event) => {
              handleChange(locale, 'content', event.target.value)
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} sm={6}>
        <FormLabel>{t('Available vars (click to insert)')}</FormLabel>
        <Paper>
          <List
            dense
            sx={{
              'width': '100%',
              // 'maxWidth': 360,
              'position': 'relative',
              'overflow': 'auto',
              'maxHeight': 715,
              '& ul': { padding: 0 },
            }}
          >
            {Object.keys(vars).map((k) => (
              <ListItem key={k}>
                <ListItemText
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const contentState = Modifier.replaceText(
                      editorState.getCurrentContent(),
                      editorState.getSelection(),
                      '{{' + k + '}}',
                      editorState.getCurrentInlineStyle(),
                    )
                    onEditorStateChange(EditorState.push(editorState, contentState, 'insert-characters'))
                  }}
                  primary={'{{' + k + '}}'}
                  secondary={vars[k]}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  )
}
