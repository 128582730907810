import { useMutation, useQuery } from '@apollo/client'
import AddBox from '@mui/icons-material/AddBox'
import Edit from '@mui/icons-material/Edit'
import { useRouter } from 'found'
import LocaleCode from 'locale-code'
import { Column } from 'material-table'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import TemplateSettingsTable from './TemplateSettingsTable'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import DialogTabs from '../../components/dialogs/DialogTabs'
import { AclTable } from '../../components/table/AclTable'
import {
  constantsQuery,
  KeyValue,
  NotificationTemplateDataFragment,
  NotificationTemplateDefaultInput,
  NotificationTemplateDefaultMutationMutation,
  NotificationTemplateDefaultMutationMutationVariables,
  notificationTemplatesQuery,
  notificationTemplatesQueryVariables,
} from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { useDeleteMutation } from '../../helpers/GqlHelper'
import TableHelper from '../../helpers/TableHelper'
import routes from '../../helpers/routes'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import Constants from '../../schema/Constants'
import NotificationTemplates from '../../schema/NotificationTemplates'

const ManageNotificationTemplatesScreen: React.FC = () => {
  const { t } = useTranslation()
  const { router } = useRouter()
  const branchFilter = useBranchFilteredQuery()
  const [deleteTemplate] = useDeleteMutation(NotificationTemplates.GET_NOTIFICATION_TEMPLATES, branchFilter.variables)

  const [mutateDefaultTemplate] = useMutation<
    NotificationTemplateDefaultMutationMutation,
    NotificationTemplateDefaultMutationMutationVariables
  >(NotificationTemplates.EDIT_NOTIFICATION_TEMPLATES_DEFAULT, {
    update: (cache, mutationResult) => {
      if (mutationResult.data && mutationResult.data.mutateNotificationTemplateDefault) {
        const { method, nodeId: currentId, type } = mutationResult.data.mutateNotificationTemplateDefault
        const { notificationTemplates } = cache.readQuery<notificationTemplatesQuery>({
          query: NotificationTemplates.GET_NOTIFICATION_TEMPLATES,
        }) || { notificationTemplates: [] }
        const activeTemplatesIds = notificationTemplates
          .filter(
            (temp) =>
              temp.active && temp.method === method && temp.type === type && temp.id !== currentId && temp.default,
          )
          .map((nt) => nt.id)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const updatedNotificationTemplates = notificationTemplates.map((nt) => {
          if (activeTemplatesIds.includes(nt.id)) {
            return { ...nt, default: false }
          }
          if (nt.id === currentId) {
            return { ...nt, default: true }
          }
          return nt
        })
        cache.writeQuery({
          query: NotificationTemplates.GET_NOTIFICATION_TEMPLATES,
          data: { notificationTemplates: updatedNotificationTemplates },
        })
      }
    },
  })
  const { loading, error, data } = useQuery<notificationTemplatesQuery, notificationTemplatesQueryVariables>(
    NotificationTemplates.GET_NOTIFICATION_TEMPLATES,
    branchFilter,
  )
  const {
    loading: constantsLoading,
    error: constantsError,
    data: constantsData,
  } = useQuery<constantsQuery>(Constants.GET_CONSTANTS)

  const [newNotificationTypeAdded, setNewNotificationTypeAdded] = useState(null)

  const mutateDefault = async (input: NotificationTemplateDefaultInput) => {
    const response = await mutateDefaultTemplate({
      variables: {
        input: DataHelper.cleanInputData(input),
      },
    })
    setNewNotificationTypeAdded(response.data.mutateNotificationTemplateDefault)
  }

  const deleteNode = async (id: string) => {
    await deleteTemplate({ variables: { input: { id } } })
  }

  if (error) {
    return <GraphqlError error={error} />
  }

  if (constantsError) {
    return <GraphqlError error={constantsError} />
  }

  if (loading || !data || constantsLoading || !constantsData) {
    return <Loading />
  }
  const locales = constantsData.constants.locales.reduce((r, v) => {
    r[v] = LocaleCode.getLanguageName(v.replace('_', '-'))
    return r
  }, {} as { [key: string]: string })
  const templates = data.notificationTemplates
  const types: KeyValue[] = constantsData.constants.notificationTypes
  const methods: KeyValue[] = constantsData.constants.notificationMethods
  const typesLookup = types.reduce((prev, current) => {
    return { ...prev, [current.key]: current.value }
  }, {} as { [key: string]: string })

  const columns: Column<NotificationTemplateDataFragment>[] = [
    { title: 'Code', field: 'code' },
    { title: 'Type', field: 'type', lookup: typesLookup },
    {
      title: 'Locale',
      field: 'locale',
      lookup: locales,
    },
    { title: 'Title', field: 'title' },
    { title: 'Description', field: 'description' },
    { title: 'Subject', field: 'subject' },
    { title: 'Active', field: 'active', type: 'boolean' },
  ]

  return (
    <div>
      <Helmet>
        <title>{t('Manage notification templates')}</title>
      </Helmet>
      <DialogTabs tabLabels={[t('Notification templates'), t('Template settings')]}>
        <AclTable<NotificationTemplateDataFragment>
          entityName={EntityNames.NotificationTemplate}
          icons={TableHelper.getTableIcons()}
          columns={columns}
          options={{
            showTitle: false,
          }}
          data={templates}
          paginateToElement={newNotificationTypeAdded}
          customAddAction={{
            icon: () => <AddBox />,
            tooltip: t('Add new template'),
            isFreeAction: true, // the action is not associated to specific rows
            onClick: () => {
              router.push(routes.app_settings_templates_new)
            },
          }}
          customEditAction={{
            icon: () => <Edit />,
            tooltip: t('Edit template'),
            onClick: (_event: any, rowData: NotificationTemplateDataFragment | NotificationTemplateDataFragment[]) => {
              let id
              if (Array.isArray(rowData)) {
                id = rowData[0].id
              } else {
                id = rowData.id
              }
              router.push(routes.app_settings_templates_edit + '/' + id)
            },
          }}
          editable={{
            onRowDelete: async (oldData) => {
              if (oldData.id) {
                await deleteNode(oldData.id)
              }
            },
          }}
        />
        <TemplateSettingsTable
          templates={templates}
          editTemplate={mutateDefault}
          defaultTemplatesIds={templates.filter((tpl) => tpl.default).map((tpl) => tpl.id)}
          methods={methods}
          types={types}
        />
      </DialogTabs>
    </div>
  )
}

export default ManageNotificationTemplatesScreen
