import Autocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { FilterOptionsState } from '@mui/material/useAutocomplete'
import React from 'react'
import { useTranslation } from 'react-i18next'

type OptionValue = string | { inputValue: string; text: string }

interface Props<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'onChange' | 'renderInput'> {
  fieldLabel: string
  onChange: (newValue: T) => void
}
type Value = OptionValue | string

const filter = createFilterOptions<Value>()

function TagAutocompleteField({
  value,
  options,
  fieldLabel,
  onChange,
  ...otherProps
}: Props<Value, false, true, true>) {
  const { t } = useTranslation()
  return (
    <Autocomplete<Value, false, true, true>
      value={value}
      options={options || []}
      renderInput={(params) => {
        return <TextField variant="standard" {...params} label={fieldLabel} />
      }}
      getOptionLabel={(option: Value) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue
        }
      }}
      onChange={(event, newValue: Value) => {
        if (typeof newValue === 'string') {
          onChange(newValue)
        } else if (newValue?.inputValue) {
          onChange(newValue.inputValue)
        } else {
          // newValue is null
          onChange('')
        }
      }}
      freeSolo
      filterOptions={(filterOptions: Value[], params: FilterOptionsState<Value>) => {
        const filtered = filter(filterOptions, params)
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            text: `${t('Add')} "${params.inputValue}"`,
          })
        }
        return filtered
      }}
      {...otherProps}
    />
  )
}

export default TagAutocompleteField
