import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ApiNodeInfoRow from './ApiNodeInfoRow'
import { OwnedApiNodeInterface } from '../../providers/UserContext'

export interface ApiNodeInfoProps {
  node: OwnedApiNodeInterface
}

function ApiNodeInfoPopover({ node }: ApiNodeInfoProps): React.ReactElement {
  const { t } = useTranslation()
  return (
    <Table size="small">
      <TableBody>
        {!!node.createdAt && <ApiNodeInfoRow label={t('Created At')} value={dayjs(node.createdAt).format('L')} />}
        {!!node.updatedAt && <ApiNodeInfoRow label={t('Updated At')} value={dayjs(node.updatedAt).format('L')} />}
        {!!node.creator && <ApiNodeInfoRow label={t('Creator')} value={node.creator?.name} />}
        {!!node.branch && <ApiNodeInfoRow label={t('Branch')} value={node.branch?.name} />}
        {!!node.branch && (
          <ApiNodeInfoRow label={t('Shared with subbranches?')} value={node.shareWithChildBranches ? 'Yes' : 'No'} />
        )}
      </TableBody>
    </Table>
  )
}

export default ApiNodeInfoPopover
