import MaterialTable, { Action, Column, MaterialTableProps } from 'material-table'
import React, { useEffect, useMemo, useRef } from 'react'
import { EntityNames } from '../../helpers/DataHelper'
import TableHelper from '../../helpers/TableHelper'
import { AclAction, CanBeNodeInterface, OwnedApiNodeInterface, useUserContext } from '../../providers/UserContext'
import ApiNodeInfo from '../owned-api-node/ApiNodeInfo'

interface Props<RowData extends CanBeNodeInterface> extends MaterialTableProps<RowData> {
  paginateToElement?: RowData
  entityName: EntityNames
  customEditAction?: Action<RowData>
  customAddAction?: Action<RowData>
  showDefaultActions?: boolean
  showInfoColumn?: boolean
  customCanEdit?(): boolean
  elementFinder?(RowData): number
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Actions<RowData extends Object> = (Action<RowData> | ((rowData: RowData) => Action<RowData>))[]

export function AclTable<RowData extends CanBeNodeInterface & OwnedApiNodeInterface>(props: Props<RowData>) {
  const {
    entityName,
    customEditAction,
    data,
    paginateToElement,
    customAddAction,
    editable,
    options,
    actions,
    customCanEdit,
    elementFinder,
    showDefaultActions = true,
    showInfoColumn = true,
    ...tableProps
  } = props

  const user = useUserContext()

  const editableData = useMemo(() => {
    // Remove creation action if no create permissions
    if (!((customCanEdit && customCanEdit()) || user.canDo(entityName, AclAction.create)) && editable) {
      const { onRowAdd, ...rest } = editable
      return rest
    }
    return editable
  }, [customCanEdit, editable, entityName, user])

  // Wrap custom actions with data control
  const fActions: Actions<RowData> = useMemo(() => {
    let finalActions: Actions<RowData> = []
    if (actions) {
      finalActions = actions
    }
    if (customEditAction) {
      finalActions.push((rowData) => {
        return {
          ...customEditAction,
          disabled: !user.can(rowData, AclAction.update),
        }
      })
    }
    if (customAddAction && ((customCanEdit && customCanEdit()) || user.canDo(entityName, AclAction.create))) {
      finalActions.push(customAddAction)
    }
    return finalActions
  }, [actions, customAddAction, customCanEdit, customEditAction, entityName, user])

  const conditionalProps: Partial<MaterialTableProps<RowData>> = useMemo(() => {
    if (fActions.length) {
      return { actions: fActions }
    }
    return {}
  }, [fActions])

  const tableRef = useRef(null)

  useEffect(() => {
    let timer

    function paginate() {
      const dataTable: RowData[] = tableRef.current.state.data
      const defaultFinder = (e) => {
        return e.id === paginateToElement.id
      }
      // Find element using provided finder or default one (by id)
      const index = dataTable.findIndex(elementFinder || defaultFinder)
      // Only change page if element is found
      if (index > -1) {
        const page = index ? Math.floor(index / tableRef.current.state.pageSize) : 0
        tableRef.current.onChangePage(null, page)
        return true
      }
      return false
    }

    if (paginateToElement) {
      if (!paginate()) {
        timer = setTimeout(() => {
          paginate()
        }, 100)
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [paginateToElement, tableRef, elementFinder])
  const finalData = useMemo(() => {
    /**
     Table modifies the objects of the array for its own purposes,
     so it's better to provide it a copy instead of the original
     **/
    return Array.isArray(data) ? data.map((d) => ({ ...d })) : data
  }, [data])

  const editableObject = showDefaultActions
    ? {
        isEditable: (rowData) => {
          return user.can(rowData, AclAction.update)
        },
        isDeletable: (rowData) => {
          return user.can(rowData, AclAction.delete)
        },
        ...editableData,
      }
    : {}

  const infoColumn: Column<RowData> = {
    title: 'Info',
    field: 'ownedNodeInfo',
    editable: 'never',
    render: (rowData) => {
      return <ApiNodeInfo node={rowData} />
    },
  }

  const columns: Column<RowData>[] = showInfoColumn ? [...props.columns, infoColumn] : [...props.columns]

  return (
    <MaterialTable<RowData>
      tableRef={tableRef}
      icons={TableHelper.getTableIcons()}
      options={{
        actionsColumnIndex: -1,
        ...options,
      }}
      data={finalData}
      editable={editableObject}
      {...tableProps}
      {...conditionalProps}
      columns={columns}
    />
  )
}
