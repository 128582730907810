import { gql } from '@apollo/client'
import OwnedApiNodes from './OwnedApiNode'

const CalendarInput = gql`
  fragment CalendarInput on Calendar {
    id
    name
    color
  }
`

const CalendarData = gql`
  fragment CalendarData on Calendar {
    ...CalendarInput
    ...OwnedApiNodeData
  }
  ${CalendarInput}
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_CALENDARS = gql`
  query calendars($branchFilter: NodeBranchFilterInput) {
    calendars(branchFilter: $branchFilter) {
      ...CalendarData
    }
  }
  ${CalendarData}
`

const EDIT_CALENDAR = gql`
  mutation CalendarMutation($input: CalendarInput!) {
    mutateCalendar(input: $input) {
      ...CalendarData
    }
  }
  ${CalendarData}
`
const Calendars = {
  GET_CALENDARS,
  EDIT_CALENDAR,
  fragments: {
    CalendarData,
    CalendarInput,
  },
}

export default Calendars
