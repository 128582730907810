import { gql } from '@apollo/client'
import EventAttendees from './EventAttendees'
import NodeMedias from './NodeMedias'
import OwnedApiNodes from './OwnedApiNode'
import Tags from './Tags'

const EventRecurrenceData = gql`
  fragment EventRecurrenceData on Recurrence {
    id
    occurrences {
      id
      start
    }
    start
    type
  }
`

const CalendarEventBasicData = gql`
  fragment CalendarEventBasicData on CalendarEvent {
    id
    title
    description
    start
    end
    language
    color
    allDay
    attendeeLimit
    hasAttendeeRegistration
    isOnline
    isFinished
    attendees {
      ...EventAttendeeData
    }
    location
    locationName
    lat
    lng
    accessType
    recurrenceDate
    id_type
    id_calendar
    calendar {
      id
      name
      color
    }
    organizers {
      id
      email
    }
    ...OwnedApiNodeData
  }
  ${EventAttendees.fragments.EventAttendeeData}
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const CalendarEventData = gql`
  fragment CalendarEventData on CalendarEvent {
    ...CalendarEventBasicData
    recurrence {
      ...EventRecurrenceData
    }
    tags {
      ...TagData
    }
    files {
      ...NodeMediaData
    }
    notificationTemplates {
      id
      notification {
        id
      }
    }
    featuredImage {
      id
    }
    eventImport {
      name
      id
    }
    reminders {
      offset
      template
    }
    organizers {
      id
    }
  }
  ${CalendarEventBasicData}
  ${EventRecurrenceData}
  ${Tags.fragment.TagData}
  ${NodeMedias.fragments.NodeMediaData}
  ${EventAttendees.fragments.EventAttendeeData}
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_EVENTS = gql`
  query events($fromDate: Int!, $toDate: Int!, $branchFilter: NodeBranchFilterInput) {
    events(input: { fromDate: $fromDate, toDate: $toDate }, branchFilter: $branchFilter) {
      ...CalendarEventBasicData
    }
  }
  ${CalendarEventBasicData}
`

const GET_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      ...CalendarEventData
    }
  }
  ${CalendarEventData}
`

const EDIT_EVENT = gql`
  mutation EventMutation($input: EventInput!) {
    mutateEvent(input: $input) {
      ...CalendarEventData
    }
  }
  ${CalendarEventData}
`

const CalendarEvents = {
  GET_EVENTS,
  GET_EVENT,
  EDIT_EVENT,
  fragments: {
    CalendarEventData,
    CalendarEventBasicData,
  },
}

export default CalendarEvents
