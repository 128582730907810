import React, { useContext, useState } from 'react'
import { OrganizationBranchDataFragment } from '../generated/graphql'

export interface BranchContextInterface {
  branch?: OrganizationBranchDataFragment
  setBranch(branch?: OrganizationBranchDataFragment): void
}

const BranchContext = React.createContext<BranchContextInterface>({
  branch: null,
  setBranch: () => {
    /* */
  },
})

export const BranchProvider: React.FC = ({ children }) => {
  const [branch, setBranch] = useState<OrganizationBranchDataFragment | null>(null)
  return <BranchContext.Provider value={{ branch, setBranch }}>{children}</BranchContext.Provider>
}

export const useBranchContext = () => useContext(BranchContext)
