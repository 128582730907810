import React, { FunctionComponent } from 'react'
import { useUserContext } from '../../providers/UserContext'

export const OrganizationsSection: FunctionComponent = (props) => {
  const user = useUserContext()
  if (!user.isOrganization()) {
    return null
  }
  return <>{props.children}</>
}
