import { NodeBranchFilterInput } from '../generated/graphql'
import { useBranchContext } from '../providers/BranchContext'

export function useBranchFilter(): NodeBranchFilterInput {
  const { branch } = useBranchContext()
  if (branch) {
    return {
      branch_ids: [branch.id],
    }
  }
  return null
}

export function useBranchFilteredQuery(): { variables: { branchFilter?: NodeBranchFilterInput } } {
  const input = useBranchFilter()
  return {
    variables: {
      branchFilter: input,
    },
  }
}
