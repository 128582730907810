import { useQuery } from '@apollo/client'
import { Column } from 'material-table'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MediaField from './MediaField'
import { MediaFieldView } from './MediaFieldView'
import NodeMediaLink from './NodeMediaLink'
import { mediaDocumentTypesQuery, mediaDocumentTypesQueryVariables, NodeMediaInput } from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { requiredField } from '../../helpers/validations'
import { CanBeNodeInterface } from '../../providers/UserContext'
import Medias from '../../schema/Medias'
import AutocompleteField from '../AutocompleteField'
import GraphqlError from '../GraphqlError'
import Loading from '../Loading'
import AlertDialog from '../dialogs/AlertDialog'
import { AclTable } from '../table/AclTable'

type NodeMediaRow = NodeMediaInput & CanBeNodeInterface

interface Props {
  ownerNode?: CanBeNodeInterface
  files: NodeMediaInput[]
  setFiles(tags: NodeMediaInput[]): void
}

const FilesTable: React.FC<Props> = (props) => {
  const { files, ownerNode, setFiles } = props
  const { t } = useTranslation()
  const { loading, error, data } = useQuery<mediaDocumentTypesQuery, mediaDocumentTypesQueryVariables>(
    Medias.GET_DOCUMENT_TYPES,
  )
  const [alert, setAlert] = useState<string | null>(null)
  const baseTypes = data?.mediaDocumentTypes
  const types = useMemo(() => {
    return (
      baseTypes?.map((dt) => ({
        inputValue: dt.id,
        text: dt.name,
      })) || []
    )
  }, [baseTypes])

  const finalFiles: NodeMediaRow[] = useMemo(() => {
    return files.map((f) => ({
      ...f,
      __typename: EntityNames.NodeMedia,
      canBeDeleted: ownerNode ? ownerNode.canBeUpdated : true,
      canBeUpdated: ownerNode ? ownerNode.canBeUpdated : true,
    }))
  }, [files, ownerNode])

  const columns: Column<NodeMediaRow>[] = useMemo(() => {
    return [
      {
        title: 'Document type',
        field: 'documentTypeId',
        render: (nodeData) => {
          return nodeData.documentTypeId && baseTypes?.find((mdt) => mdt.id === nodeData.documentTypeId)?.name
        },
        editComponent: (editProps) => {
          return (
            <AutocompleteField
              value={types.find((type) => editProps.value === type.inputValue) || null}
              options={types}
              fieldLabel={t('Type')}
              onChange={(val: string) => {
                editProps.onChange(val)
              }}
            />
          )
        },
      },
      {
        title: 'File',
        field: 'id_media',
        validate: requiredField('id_media'),
        editComponent: (editProps) => {
          // If the nodemedia record is saved (has id) the component must not delete the media register
          return (
            <MediaField
              ownerNodeId={ownerNode?.id}
              idMedia={editProps.value}
              notDeleteMedia={!!editProps.rowData.id}
              onChange={(id) => {
                editProps.onChange(id)
              }}
            />
          )
        },
        render: (nodeData) => {
          return (
            <NodeMediaLink id={nodeData && nodeData.id}>
              <MediaFieldView idMedia={nodeData.id_media} />
            </NodeMediaLink>
          )
        },
      },
    ]
  }, [baseTypes, ownerNode?.id, t, types])

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <AlertDialog open={!!alert} title={t('Validation error')} text={alert} onClose={() => setAlert(null)} />
      <AclTable<NodeMediaRow>
        title={t('Documents')}
        columns={columns}
        data={finalFiles}
        showInfoColumn={false}
        entityName={EntityNames.CalendarEvent}
        editable={{
          onRowAdd: async (newData) => {
            setFiles([...files, DataHelper.cleanInputData(newData)])
          },
          onRowUpdate: async (newData) => {
            setFiles(
              files.map((f) => {
                if (f.id === newData.id) {
                  return DataHelper.cleanInputData(newData)
                }
                return f
              }),
            )
          },
          onRowDelete: async (oldData) => {
            setFiles(
              files.filter((f) => {
                if (oldData.id) {
                  return f.id !== oldData.id
                }
                return (
                  f.id ||
                  (!f.id && f.id_media !== oldData.id_media) ||
                  (f.id && f.id_media === oldData.id_media && f.documentTypeId !== oldData.documentTypeId)
                )
              }),
            )
          },
        }}
      />
    </div>
  )
}

export default FilesTable
