import { useQuery } from '@apollo/client'
import { Link } from '@mui/material'
import { RouterState } from 'found'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { NodeMediaAccessUrlQuery, NodeMediaAccessUrlQueryVariables } from '../../generated/graphql'
import NodeMedias from '../../schema/NodeMedias'

const useStyles = makeStyles()(() => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      cursor: 'pointer',
    },
  }
})

const DownloadScreen: React.FC<RouterState> = (props) => {
  const { classes } = useStyles()
  const { data, error, loading } = useQuery<NodeMediaAccessUrlQuery, NodeMediaAccessUrlQueryVariables>(
    NodeMedias.GET_ACCESS_URL,
    {
      variables: {
        id: props.match.params.id,
      },
    },
  )
  const { t } = useTranslation()
  if (loading || !data) {
    return <Loading />
  }
  if (error) {
    return <GraphqlError error={error} />
  }
  const url = data.nodeMediaAccessUrl.url
  window.location.href = url
  return (
    <div style={{ height: 'calc(100vh - 150px)' }}>
      <Helmet>
        <title>{t('Download media')}</title>
      </Helmet>
      <div className={classes.container}>
        {t('Redirecting to')}&nbsp;
        <Link
          className={classes.link}
          onClick={() => {
            window.location.href = url
          }}
          underline="hover"
        >
          {data.nodeMediaAccessUrl.media.name}
        </Link>
        <Loading variant="inline" />
      </div>
    </div>
  )
}

export default DownloadScreen
