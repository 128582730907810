import { gql } from '@apollo/client'
import OwnedApiNodes from './OwnedApiNode'

export interface AclDefinitionRow {
  c?: boolean
  r?: boolean
  u?: boolean
  d?: boolean
  ro?: boolean
  uo?: boolean
  do?: boolean
}

export interface AclDefinition {
  [key: string]: AclDefinitionRow
}

const AclRoleData = gql`
  fragment AclRoleData on AclRole {
    id
    name
    active
    acl
    ...OwnedApiNodeData
  }
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_ACL_ROLES = gql`
  query aclRoles($branchFilter: NodeBranchFilterInput) {
    aclRoles(branchFilter: $branchFilter) {
      ...AclRoleData
    }
  }
  ${AclRoleData}
`

const EDIT_ACL_ROLES = gql`
  mutation AclRolesMutation($input: AclRoleInput!) {
    mutateAclRole(input: $input) {
      ...AclRoleData
    }
  }
  ${AclRoleData}
`

const AclRoles = {
  GET_ACL_ROLES,
  EDIT_ACL_ROLES,
  fragments: {
    AclRoleData,
  },
}

export default AclRoles
