import { gql } from '@apollo/client'

const TYPE_LOGIN_ERROR = 'LoginError'
const TYPE_LOGIN_TOKEN = 'LoginToken'

export interface LoginToken {
  username: string
  accessToken: string
  refreshToken: string
  expiresIn: number
}

const LOGIN = gql`
  mutation LoginMutation($input: LoginInput!) {
    loginToken(input: $input) {
      ... on LoginToken {
        accessToken
        refreshToken
        expiresIn
      }
      ... on LoginError {
        error
        errorDescription
      }
    }
  }
`

const REFRESH = gql`
  mutation RefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      ... on LoginToken {
        accessToken
        refreshToken
        expiresIn
      }
      ... on LoginError {
        error
        errorDescription
      }
    }
  }
`

const REGISTER = gql`
  mutation RegisterMutation($input: NewUserInput!) {
    createUser(input: $input) {
      id
      name
    }
  }
`
const User = {
  LOGIN,
  REFRESH,
  REGISTER,
  TYPE_LOGIN_ERROR,
  TYPE_LOGIN_TOKEN,
}

export default User
