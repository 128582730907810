import { gql } from '@apollo/client'
import OwnedApiNodes from './OwnedApiNode'

const MediaDocumentTypeData = gql`
  fragment MediaDocumentTypeData on MediaDocumentType {
    name
    code
    ...OwnedApiNodeData
  }
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const MediaListData = gql`
  fragment MediaListData on Media {
    filename
    mimeType
    name
    description
    altText
    global
    ...OwnedApiNodeData
  }
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const UPLOAD_FILE = gql`
  mutation MediaMutation($input: MediaInput!) {
    mutateMedia(input: $input) {
      ...MediaListData
    }
  }
  ${MediaListData}
`

const GET_MEDIA = gql`
  query media($id: ID!) {
    media(id: $id) {
      ...MediaListData
    }
  }
  ${MediaListData}
`

const GET_MEDIAS = gql`
  query medias($branchFilter: NodeBranchFilterInput, $node: String) {
    medias(branchFilter: $branchFilter, nodeId: $node) {
      ...MediaListData
    }
  }
  ${MediaListData}
`

const MUTATE_MEDIA = gql`
  mutation MutateMedia($input: MediaInput!) {
    mutateMedia(input: $input) {
      ...MediaListData
    }
  }
  ${MediaListData}
`

const GET_DOCUMENT_TYPES = gql`
  query mediaDocumentTypes($branchFilter: NodeBranchFilterInput) {
    mediaDocumentTypes(branchFilter: $branchFilter) {
      ...MediaDocumentTypeData
    }
  }
  ${MediaDocumentTypeData}
`

const MUTATE_DOCUMENT_TYPE = gql`
  mutation MutateMediaDocumentType($input: MediaDocumentTypeInput!) {
    mutateMediaDocumentType(input: $input) {
      ...MediaDocumentTypeData
    }
  }
  ${MediaDocumentTypeData}
`

const DELETE_NOT_GLOBAL = gql`
  mutation DeleteNotGlobalMedia($input: NodeSelectInput!) {
    deleteNotGlobalMedia(input: $input) {
      id
    }
  }
`

const GET_ACCESS_URL = gql`
  query MediaAccessUrl($id: ID!) {
    mediaAccessUrl(id: $id) {
      url
      expiration
      issued
      media {
        ...MediaListData
      }
    }
  }
  ${MediaListData}
`

const Medias = {
  UPLOAD_FILE,
  GET_MEDIA,
  GET_MEDIAS,
  MUTATE_MEDIA,
  DELETE_NOT_GLOBAL,
  GET_DOCUMENT_TYPES,
  MUTATE_DOCUMENT_TYPE,
  GET_ACCESS_URL,
  fragments: {
    MediaListData,
    MediaDocumentTypeData,
  },
}

export default Medias
