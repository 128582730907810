import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      minWidth: 400,
      justifyContent: 'space-between',
    },
    actionButton: {
      color: theme.palette.grey[500],
    },
    actionsContainer: {
      marginLeft: theme.spacing(2),
    },
  }
})

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose?: () => void
  title?: string
}

const ActionDialogTitle = (props: DialogTitleProps) => {
  const { classes } = useStyles()
  const { children, onClose, title } = props
  return (
    <DialogTitle className={classes.root}>
      <span>{title}</span>
      <div className={classes.actionsContainer}>
        {children}
        {onClose ? (
          <IconButton aria-label="close" className={classes.actionButton} onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  )
}

export default ActionDialogTitle
