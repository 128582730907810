import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useRouter } from 'found'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PathwayEventTab from './PathwayEventTab'
import AsyncButton from '../../components/AsyncButton'
import DialogTabs from '../../components/dialogs/DialogTabs'
import LocationField from '../../components/forms/LocationField'
import {
  EventAccessType,
  PathwayDataFragment,
  PathwayInput,
  PathwayMutationMutation,
  PathwayMutationMutationVariables,
  pathwaysQuery,
} from '../../generated/graphql'
import DH from '../../helpers/DH'
import DataHelper from '../../helpers/DataHelper'
import GqlHelper from '../../helpers/GqlHelper'
import routes from '../../helpers/routes'
import Pathways from '../../schema/Pathways'

interface Props {
  pathway?: PathwayDataFragment
}

function getInitialState(pathway?: PathwayDataFragment, forceReset = false): PathwayInput {
  if (pathway && !forceReset) {
    const { id, title, description, start, accessType, active, location, lat, lng } = pathway
    return {
      id,
      title,
      description,
      start,
      accessType,
      active,
      location,
      lat,
      lng,
    }
  }
  return {
    title: '',
    description: '',
    start: dayjs().unix(),
    accessType: EventAccessType.Public,
    active: true,
    location: null,
    lat: null,
    lng: null,
  }
}

const PathwayEditContent: React.FC<Props> = (props) => {
  const { router } = useRouter()
  const { t } = useTranslation()
  const [state, setState] = useState<PathwayInput>(getInitialState(props.pathway))

  const [mutatePathway] = useMutation<PathwayMutationMutation, PathwayMutationMutationVariables>(
    Pathways.EDIT_PATHWAY,
    {
      update: GqlHelper.getMutationReducer<PathwayMutationMutation, pathwaysQuery>(
        'mutatePathway',
        Pathways.GET_PATHWAYS,
        'pathways',
      ),
    },
  )
  const editPathway = async (pathway: PathwayInput) => {
    await mutatePathway({ variables: { input: DataHelper.removeTypeName(pathway) } })
    router.push(routes.app_settings_pathways)
  }

  useEffect(() => {
    setState(getInitialState(props.pathway))
  }, [props.pathway])

  return (
    <DialogTabs tabLabels={[t('Details'), t('Associated events')]}>
      <div>
        <TextField
          required
          autoFocus
          variant="standard"
          label={t('Title')}
          type="text"
          value={state.title}
          onChange={(e) => setState({ ...state, title: e.target.value })}
          fullWidth
        />
        <FormControl required>
          <DatePicker
            label={t('Starting Day')}
            inputFormat={DH.getDateFormat()}
            renderInput={(params) => <TextField variant="standard" {...params} />}
            value={state && dayjs.unix(state.start).toDate()}
            onChange={(date: Dayjs) => {
              if (date) {
                setState({ ...state, start: date.unix() })
              }
            }}
          />
        </FormControl>
        <TextField
          label={t('Description')}
          type="text"
          variant="standard"
          multiline
          value={state.description || ''}
          onChange={(e) => setState({ ...state, description: e.target.value })}
          fullWidth
        />
        <FormControl>
          <FormLabel>{t('Access Type')}</FormLabel>
          <RadioGroup
            row
            defaultValue={EventAccessType.Public}
            id="access-type"
            onChange={(e) => {
              setState({
                ...state,
                accessType: (e.target as EventTarget & HTMLInputElement).value as EventAccessType,
              })
            }}
          >
            {Object.values(EventAccessType).map((val) => (
              <FormControlLabel key={val} value={val} control={<Radio />} label={t(val)} />
            ))}
          </RadioGroup>
        </FormControl>
        <div>
          <FormControl fullWidth>
            <LocationField
              location={state.location}
              setLocation={(dataLocation) => {
                const { lat, lng, location } = dataLocation
                setState({ ...state, lat, lng, location })
              }}
            />
          </FormControl>
        </div>

        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button onClick={() => router.push(routes.app_settings_pathways)} color="primary">
            {t('Cancel')}
          </Button>
          <AsyncButton onClick={() => state && editPathway(state)} disabled={!state.title} />
        </div>
      </div>
      {state.id && <PathwayEventTab pathwayId={state.id} />}
    </DialogTabs>
  )
}

export default PathwayEditContent
