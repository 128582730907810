import { gql } from '@apollo/client'
import Medias from './Medias'

const NodeMediaData = gql`
  fragment NodeMediaData on NodeMedia {
    canBeDeleted
    canBeUpdated
    id
    nodeId
    documentType {
      ...MediaDocumentTypeData
    }
    file {
      ...MediaListData
    }
  }
  ${Medias.fragments.MediaListData}
  ${Medias.fragments.MediaDocumentTypeData}
`

const GET_ACCESS_URL = gql`
  query NodeMediaAccessUrl($id: ID!) {
    nodeMediaAccessUrl(id: $id) {
      url
      expiration
      issued
      media {
        ...MediaListData
      }
    }
  }
  ${Medias.fragments.MediaListData}
`

const NodeMedias = {
  GET_ACCESS_URL,
  fragments: {
    NodeMediaData,
  },
}

export default NodeMedias
