import { gql } from '@apollo/client'
import Profiles from './Profiles'

const ProfileData = gql`
  fragment ProfileData on Profile {
    id
    name
    role
    locale
    username
    isOrganization
    organization {
      name
    }
    ...OrganizationProfileData
    permissions {
      entity
      c
      u
      d
      r
    }
    tzname
    canBeDeleted
    canBeUpdated
  }
  ${Profiles.fragments.OrganizationProfileData}
`

const GET_VIEWER = gql`
  query viewer {
    viewer {
      ...ProfileData
    }
  }
  ${ProfileData}
`

const EDIT_VIEWER = gql`
  mutation mutateViewer($input: ViewerEditInput!) {
    mutateViewer(input: $input) {
      ...ProfileData
    }
  }
  ${ProfileData}
`

const Viewers = {
  GET_VIEWER,
  EDIT_VIEWER,
}

export default Viewers
