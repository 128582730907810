import { TreeItem } from '@mui/x-tree-view/TreeItem'
import React from 'react'
import BranchListItem from './BranchListItem'
import { OrganizationBranchDataFragment } from '../../generated/graphql'

interface Props {
  list?: OrganizationBranchDataFragment[]
  fullList: OrganizationBranchDataFragment[]
  parentId?: string

  commitBranch(input: { id: string; name: string }): Promise<void>
}

function BranchList({ list = [], commitBranch, fullList }: Props): React.ReactElement {
  if (!list.length) {
    list = fullList.filter((branch) => !branch.parentId)
  }

  return (
    <>
      {list.map((branch) => {
        const childrenList = fullList.filter((twig) => {
          return twig.parentId === branch.id
        })
        return (
          <TreeItem
            key={branch.id}
            itemId={branch.id}
            label={<BranchListItem branch={branch} onCommit={commitBranch} />}
          >
            {!!childrenList.length && (
              <BranchList commitBranch={commitBranch} fullList={fullList} list={childrenList} parentId={branch.id} />
            )}
          </TreeItem>
        )
      })}
    </>
  )
}

export default BranchList
