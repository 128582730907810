/* eslint-disable */
import { useLazyQuery } from '@apollo/client'
import { Button, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { TFunction, useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import * as XLSX from 'xlsx'
import GraphqlError from '../../components/GraphqlError'
import { eventsQuery } from '../../generated/graphql'
import DH from '../../helpers/DH'
import { getAllLanguagesFromISO } from '../../helpers/Languages'
import { useBranchFilter } from '../../hooks/BranchFilterHook'
import CalendarEvents from '../../schema/CalendarEvents'

interface ExportEvents {
  from: Date | null
  to: Date | null
  exportAttendee: boolean
}

const useStyles = makeStyles()(() => {
  return {
    right: {
      float: 'right',
    },
  }
})

function downloadData(data: eventsQuery, t: TFunction, groupByAttendee = false) {
  const fields = [
    t('Date'),
    t('Time'),
    t('Title'),
    t('Online'),
    t('Location name'),
    t('Location'),
    t('Language'),
    t('Attendee max limit'),
    t('Attendee registered'),
    t('Branch'),
    t('Organizers'),
  ]
  if (groupByAttendee) {
    fields.push(t('Attendee email'), t('Attended'))
  }
  const rows = [fields]
  const langs = getAllLanguagesFromISO()
  const locale = window.navigator.language
  dayjs.locale(locale)
  data.events.forEach((e) => {
    const eventData = [
      dayjs.unix(e.start).format('L'),
      e.allDay ? '' : dayjs.unix(e.start).format('HH:mm'),
      e.title,
      e.isOnline ? t('Yes') : t('No'),
      e.locationName,
      e.location,
      e.language?.map((l) => langs[l] || l).join(', ') || '',
      e.attendeeLimit.toString(),
      e.attendees.length.toString(),
      e.branch?.name ? e.branch.name : '',
      e.organizers?.length ? e.organizers?.map((org) => org.email).join(', ') || '' : '',
    ]
    if (groupByAttendee) {
      e.attendees?.forEach((a) => {
        rows.push([...eventData, a.profile.email, a.attended ? t('Yes') : t('No')])
      })
    } else {
      rows.push(eventData)
    }
  })
  const ws = XLSX.utils.aoa_to_sheet(rows, { cellDates: true })
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Events')
  XLSX.writeFile(wb, 'event-export.xlsx')
}

export default function EventExportScreen(): React.ReactElement {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const branchFilter = useBranchFilter()
  const [state, setState] = useState<ExportEvents>({
    from: null,
    to: null,
    exportAttendee: true,
  })
  const [loadEvents, { loading, error }] = useLazyQuery<eventsQuery>(CalendarEvents.GET_EVENTS, {
    variables: {
      fromDate: dayjs(state.from).unix(),
      toDate: dayjs(state.to).unix(),
      branchFilter,
    },
    onCompleted: (data) => {
      downloadData(data, t, state.exportAttendee)
    },
  })

  return (
    <div>
      <Helmet>
        <title>{t('Export events')}</title>
      </Helmet>
      <Typography variant="body1" gutterBottom>
        {t('Please select the events date range to export')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <FormControl required fullWidth>
            <DatePicker
              label={t('From')}
              inputFormat={DH.getDateFormat()}
              renderInput={(params) => <TextField variant="standard" {...params} required />}
              value={state.from}
              onChange={(date: any) => {
                setState({
                  ...state,
                  from: date,
                })
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl required fullWidth>
            <DatePicker
              label={t('To')}
              inputFormat={DH.getDateFormat()}
              renderInput={(params) => <TextField variant="standard" {...params} required />}
              value={state.to}
              onChange={(date: any) => {
                setState({
                  ...state,
                  to: date,
                })
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.exportAttendee}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    exportAttendee: event.target.checked,
                  })
                }}
              />
            }
            label="Export attendees"
          />
        </Grid>
      </Grid>
      <GraphqlError error={error} />
      <Button
        startIcon={loading && <CircularProgress size={22} />}
        className={classes.right}
        onClick={() => loadEvents()}
        color="primary"
        disabled={!state.from || !state.to}
      >
        {t('Export')}
      </Button>
    </div>
  )
}
