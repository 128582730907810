import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'
import Typography, { TypographyProps } from '@mui/material/Typography'
import React, { FunctionComponent } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => {
  return {
    wrapIcon: {
      display: 'flex',
      alignItems: 'center',
    },
  }
})

interface Props extends TypographyProps {
  icon: React.ComponentType<SvgIconProps>
  iconProps?: SvgIconProps
  text: string
}

const IconWithText: FunctionComponent<Props> = (props) => {
  const { classes } = useStyles()
  const { icon, text, iconProps, ...other } = props
  const Icon = icon
  return (
    <Typography {...other} className={classes.wrapIcon}>
      <Icon {...iconProps} /> {text}
    </Typography>
  )
}

export default IconWithText
