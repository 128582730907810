import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import React, { useContext, useMemo } from 'react'
import GraphqlError from '../components/GraphqlError'
import Loading from '../components/Loading'
import { Locale, ProfileDataFragment, Role, viewerQuery } from '../generated/graphql'
import { EntityNames } from '../helpers/DataHelper'
import i18n from '../i18n'
import Viewers from '../schema/Viewers'

export enum AclAction {
  create = 'c',
  read = 'r',
  update = 'u',
  delete = 'd',
}

export interface CanBeInterface {
  canBeUpdated: boolean
  canBeDeleted: boolean
}

export interface CanBeNodeInterface extends CanBeInterface {
  id?: string
  __typename: string
}

export interface OwnedApiNodeInterface extends CanBeNodeInterface {
  id?: string
  createdAt?: number
  updatedAt?: number
  user?: { id: string; name: string }
  creator?: { id: string; name: string }
  branch?: { name: string; id: string }
  shareWithChildBranches?: boolean
}

export class User {
  readonly viewer?: ProfileDataFragment | null
  private readonly refetch?: () => any

  constructor(viewer?: ProfileDataFragment | null, refetch?: () => any) {
    this.viewer = viewer
    this.refetch = refetch
  }

  get id(): string | undefined {
    return this.viewer?.id
  }

  canDo(entityName: EntityNames, action: AclAction | AclAction[]): boolean {
    if (this.viewer && this.viewer.permissions) {
      const p = this.viewer.permissions
      const er = p.find((pi) => pi.entity === entityName)
      if (er) {
        if (Array.isArray(action)) {
          return action.reduce((can, singleAction) => {
            return can || er[singleAction]
          }, false as boolean)
        }
        return er[action]
      }
    }
    return false
  }

  can(entity: CanBeNodeInterface, action: AclAction): boolean {
    switch (action) {
      case AclAction.create:
      case AclAction.read:
        return this.canDo(entity.__typename as EntityNames, action)
      case AclAction.update:
        return entity.canBeUpdated
      case AclAction.delete:
        return entity.canBeDeleted
    }
    return false
  }

  getTitle(): string {
    let title = ''
    if (this.viewer) {
      title = this.viewer.name
      if (this.viewer.organization) {
        title += ' / ' + this.viewer.organization.name
      }
    }
    return title
  }

  getName(): string | undefined {
    return this.viewer?.name
  }

  getEmail(): string | undefined {
    return this.viewer?.email
  }

  getTz(): string | undefined {
    return this.viewer?.tzname
  }

  isOrganization(): boolean {
    return !!this.viewer && !!(this.viewer.organization || this.viewer.isOrganization)
  }

  isUser() {
    return !!this.viewer && this.viewer.role === Role.User
  }

  refresh() {
    if (this.refetch) {
      this.refetch()
    }
  }

  locale(): Locale | undefined {
    if (this.viewer) {
      return this.viewer.locale || (i18n.language as Locale)
    }
    return i18n.language as Locale
  }
}

const UserContext = React.createContext<User>(new User())

export const UserProvider: React.FC = ({ children }) => {
  const { loading, error, data, refetch } = useQuery<viewerQuery>(Viewers.GET_VIEWER)
  const user = useMemo(() => {
    if (data && data.viewer) {
      Sentry.configureScope(function (scope) {
        scope.setUser({
          username: data.viewer.username,
          id: data.viewer.id,
        })
        scope.setTag('locale', data.viewer.locale)
        scope.setTag('role', data.viewer.role)
      })
    }
    return new User(data?.viewer, refetch)
  }, [data, refetch])
  if (loading) {
    return <Loading />
  } else if (error) {
    return <GraphqlError error={error} />
  }
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)
