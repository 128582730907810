import { ApolloCache, useMutation, useQuery } from '@apollo/client'
import { AddBox, Check, Close, Error, MoreHoriz } from '@mui/icons-material'
import { Dialog, useMediaQuery } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { Column } from 'material-table'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ActionDialogTitle from './ActionDialogTitle'
import EventAttendeeDialog from './EventAttendeeDialog'
import Loading from '../../components/Loading'
import { CanBeUpdated } from '../../components/acl/CanBe'
import { AclTable } from '../../components/table/AclTable'
import {
  AttendanceMutationMutation,
  AttendanceMutationMutationVariables,
  attendeesQuery,
  CalendarEventBasicDataFragment,
  EventAttendeeAttendanceInput,
  EventAttendeeDataFragment,
  EventInput,
} from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'
import GqlHelper, { useDeleteMutation } from '../../helpers/GqlHelper'
import CalendarEvents from '../../schema/CalendarEvents'
import EventAttendees from '../../schema/EventAttendees'

interface Props {
  open: boolean
  event: CalendarEventBasicDataFragment

  onClose(): void

  editEvent(input: EventInput): void
}

const EventAttendanceRegisterDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [newAttendeeOpen, setNewAttendeeOpen] = useState(false)
  const isEventFinished = props.event.isFinished

  const [mutateAttendance] = useMutation<AttendanceMutationMutation, AttendanceMutationMutationVariables>(
    EventAttendees.EDIT_ATTENDANCE,
    {
      update: GqlHelper.getMutationReducer<AttendanceMutationMutation, attendeesQuery>(
        'mutateEventAttendeeAttendance',
        EventAttendees.GET_ATTENDEES,
        'eventAttendees',
        { eventId: props.event.id },
      ),
    },
  )

  const { loading, error, data } = useQuery<attendeesQuery>(EventAttendees.GET_ATTENDEES, {
    variables: { eventId: props.event.id },
  })

  const deleteUpdate = (cache: ApolloCache<any>) => {
    // Load updated attendee list
    const updatedData: any = cache.readQuery({
      query: EventAttendees.GET_ATTENDEES,
      variables: { eventId: props.event.id },
    }) || { eventAttendees: [] }
    // Update event attendee list
    cache.writeFragment({
      id: props.event.id,
      fragment: CalendarEvents.fragments.CalendarEventData,
      fragmentName: 'CalendarEventData',
      data: {
        ...props.event,
        attendees: updatedData ? updatedData.eventAttendees : [],
      },
    })
  }
  const [deleteAttendee] = useDeleteMutation(EventAttendees.GET_ATTENDEES, { eventId: props.event.id }, deleteUpdate)

  if (loading || error || !data) {
    return <Loading />
  }

  const editAttendance = async (input: EventAttendeeAttendanceInput) => {
    await mutateAttendance({ variables: { input } })
  }

  const deleteNode = async (id: string) => {
    await deleteAttendee({ variables: { input: { id } } })
  }

  const eventAttendees: EventAttendeeDataFragment[] = data.eventAttendees

  const dateNow = new Date()
  const dateEvent = dayjs.unix(props.event.start).toDate()

  const columns: Column<EventAttendeeDataFragment>[] = [
    { title: 'Name', field: 'name', defaultSort: 'asc' },
    { title: 'Email', field: 'email' },
    {
      title: 'Confirmed',
      field: 'confirmed',
      editable: 'never',
      render: (itemData) => {
        return itemData?.type.toLocaleLowerCase() === 'attendee' ? (
          <Check /> // register PP
        ) : itemData?.type.toLocaleLowerCase() === 'invited' ? (
          <MoreHoriz /> // invited si confirmed 0
        ) : itemData?.type.toLocaleLowerCase() === 'unsubscribed' ? (
          <Close />
        ) : (
          <Error />
        ) // invited si confirmed 0
      },
    },
    {
      title: 'Attended?',
      field: 'attended',
      type: 'boolean',
      render: (attendeeData) => (
        <Checkbox
          disabled={!(props.event.hasAttendeeRegistration && !props.event.isFinished)}
          checked={attendeeData.attended}
          onChange={async () => {
            await editAttendance({
              id: attendeeData.id,
              attended: !attendeeData.attended,
            })
          }}
        />
      ),
    },
  ]

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="attendance-dialog-title"
      fullScreen={fullScreen}
    >
      <ActionDialogTitle id="attendance-dialog-title" title={t('Attendance Registration')} onClose={props.onClose} />
      <DialogContent>
        <EventAttendeeDialog
          open={newAttendeeOpen}
          eventId={props.event.id}
          language={props.event.language[0]}
          onClose={() => setNewAttendeeOpen(false)}
        />
        <AclTable<EventAttendeeDataFragment>
          entityName={EntityNames.EventAttendee}
          columns={columns}
          customCanEdit={() => {
            return props.event.canBeUpdated
          }}
          data={eventAttendees.map((ea) => {
            return {
              ...ea,
              name: ea.profile.name,
              email: ea.profile.email,
            }
          })}
          customAddAction={{
            disabled: eventAttendees.length >= props.event.attendeeLimit,
            icon: () => <AddBox />,
            tooltip:
              eventAttendees.length >= props.event.attendeeLimit ? t('Attendee limit reached') : t('Add new attendee'),
            isFreeAction: true, // the action is not associated to specific rows
            onClick: () => setNewAttendeeOpen(true),
          }}
          options={{ showTitle: false }}
          editable={{
            onRowDelete: async (oldData) => {
              if (oldData.id) {
                await deleteNode(oldData.id)
              }
            },
          }}
        />
        <CanBeUpdated entity={props.event}>
          <div>
            <Checkbox
              checked={!!props.event.isFinished}
              disabled={dateNow < dateEvent}
              onChange={async () => {
                if (!isEventFinished) {
                  const {
                    id,
                    title,
                    description,
                    allDay,
                    id_calendar,
                    id_type,
                    location,
                    color,
                    accessType,
                    attendeeLimit,
                    start,
                    end,
                    hasAttendeeRegistration,
                  } = props.event
                  await props.editEvent({
                    id,
                    title,
                    description,
                    allDay,
                    id_calendar,
                    id_type,
                    location,
                    color,
                    accessType,
                    attendeeLimit,
                    start,
                    end,
                    hasAttendeeRegistration,
                    isFinished: true,
                  })
                }
              }}
            />
            {t('Event has finished')}
          </div>
        </CanBeUpdated>
      </DialogContent>
    </Dialog>
  )
}

export default EventAttendanceRegisterDialog
