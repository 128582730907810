import { useMutation, useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import AclRolesTable from './AclRolesTable'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import {
  aclEntitiesQuery,
  AclRoleInput,
  AclRolesMutationMutation,
  AclRolesMutationMutationVariables,
  aclRolesQuery,
  aclRolesQueryVariables,
} from '../../generated/graphql'
import DataHelper from '../../helpers/DataHelper'
import GqlHelper, { useDeleteMutation } from '../../helpers/GqlHelper'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import AclRoles from '../../schema/AclRoles'
import Constants from '../../schema/Constants'

const ManageRolesScreen: React.FC = () => {
  const { t } = useTranslation()
  const branchFilter = useBranchFilteredQuery()
  const [deleteAclRole] = useDeleteMutation(AclRoles.GET_ACL_ROLES, branchFilter.variables)
  const [mutateAclRole] = useMutation<AclRolesMutationMutation, AclRolesMutationMutationVariables>(
    AclRoles.EDIT_ACL_ROLES,
    {
      update: GqlHelper.getMutationReducer<AclRolesMutationMutation, aclRolesQuery>(
        'mutateAclRole',
        AclRoles.GET_ACL_ROLES,
        'aclRoles',
        branchFilter.variables,
      ),
    },
  )
  const { loading, error, data } = useQuery<aclRolesQuery, aclRolesQueryVariables>(AclRoles.GET_ACL_ROLES, branchFilter)
  const {
    loading: entityListLoading,
    error: entityListError,
    data: entityListData,
  } = useQuery<aclEntitiesQuery>(Constants.GET_ACL_ENTITIES)

  const createAclRole = async (role: AclRoleInput) => {
    await mutateAclRole({ variables: { input: DataHelper.cleanInputData(role) } })
    return true
  }
  const deleteNode = async (id: string) => {
    await deleteAclRole({ variables: { input: { id } } })
    return true
  }

  const entityMap: Record<string, string> = useMemo(() => {
    if (entityListData) {
      const entities = entityListData.constants.aclRolesEntities
      return entities.reduce((prev, entity) => {
        return { ...prev, [entity.key]: entity.value }
      }, {})
    }
    return {}
  }, [entityListData])

  if (error) {
    return <GraphqlError error={error} />
  }

  if (entityListError) {
    return <GraphqlError error={entityListError} />
  }

  if (loading || entityListLoading || !data || !entityListData) {
    return <Loading />
  }

  return (
    <div>
      <Helmet>
        <title>{t('Roles')}</title>
      </Helmet>
      <AclRolesTable
        entities={entityMap}
        data={data.aclRoles}
        createRole={createAclRole}
        deleteRole={deleteNode}
        editRole={createAclRole}
      />
    </div>
  )
}

export default ManageRolesScreen
