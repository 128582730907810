import AttendeesIcon from '@mui/icons-material/People'
import Badge from '@mui/material/Badge'
import React, { FunctionComponent, ReactElement } from 'react'
import { EventProps } from 'react-big-calendar'
import { makeStyles } from 'tss-react/mui'
import { CalendarEventDataFragment } from '../../generated/graphql'
import DH from '../../helpers/DH'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      borderRadius: 5,
      padding: '2px 5px',
    },
    text: {
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    badge: {
      alignSelf: 'flex-end',
    },
    badgeContent: {
      height: theme.spacing(1.5),
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
      minWidth: theme.spacing(1.5),
      fontSize: 'xx-small',
    },
  }
})

const CalendarEventView: FunctionComponent<EventProps<CalendarEventDataFragment>> = ({ event, title }) => {
  const { classes } = useStyles()
  const attendeeCount = event.attendees.length
  let badge: ReactElement | null = null
  if (attendeeCount) {
    badge = (
      <Badge
        badgeContent={attendeeCount}
        color="primary"
        className={classes.badge}
        classes={{
          badge: classes.badgeContent,
        }}
      >
        <AttendeesIcon fontSize="small" />
      </Badge>
    )
  }
  return (
    <span
      className={classes.root}
      style={{
        backgroundColor: DH.getColorValue(event.color, 400),
      }}
    >
      <strong className={classes.text}>{title}</strong>
      {badge}
    </span>
  )
}

export default CalendarEventView
