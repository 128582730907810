import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import React from 'react'

/* const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8 */
const MenuProps = {
  PaperProps: {
    style: {
      /* maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250, */
    },
  },
}

interface Props {
  value: string[]
  label?: string
  options: {
    [key: string]: string
  }
  fullWidth?: boolean

  setValue(value: string[]): void
}

export default function MultipleSelect({ options, value, setValue, label }: Props) {
  function handleChange(event: SelectChangeEvent<string[]>) {
    const val = Array.isArray(event.target.value) ? event.target.value : [event.target.value]
    setValue(val)
  }

  return (
    <FormControl fullWidth>
      {label && <InputLabel htmlFor="select-multiple-checkbox">{label}</InputLabel>}
      <Select<string[]>
        variant="standard"
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={(selected) => (selected as string[]).map((k) => options[k]).join(', ')}
        MenuProps={MenuProps}
      >
        {Object.keys(options).map((k) => (
          <MenuItem key={k} value={k}>
            <Checkbox checked={value.indexOf(k) > -1} />
            <ListItemText primary={options[k]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
