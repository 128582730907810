import CheckIcon from '@mui/icons-material/Check'
import { Color } from '@mui/material'
import Radio from '@mui/material/Radio'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import DH from '../helpers/DH'

const useStyles = makeStyles()((theme) => {
  return {
    swatch: {
      width: 432,
      marginTop: 10,
    },
    radio: {
      padding: 0,
    },
    radioIcon: {
      width: 48,
      height: 48,
    },
    radioIconSelected: {
      width: 48,
      height: 48,
      border: '1px solid white',
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})

interface Props {
  color: string
  onChange: (color: string) => void
  colorIndex?: keyof Color
}

const ColorSelector: React.FC<Props> = ({ color, onChange, colorIndex = 600 }) => {
  const { classes } = useStyles()

  function handleColorChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  return (
    <div className={classes.swatch}>
      {Object.values(DH.getDefaultColors()).map((c: Color) => {
        const hex = c[DH.defaultColorIndex]
        const backgroundColor = c[colorIndex]
        return (
          <Tooltip placement="right" title={hex} key={hex}>
            <Radio
              className={classes.radio}
              color="default"
              checked={color === hex}
              onChange={handleColorChange}
              value={hex}
              name="color"
              aria-labelledby={`tooltip-color-${hex}`}
              icon={<div className={classes.radioIcon} style={{ backgroundColor }} />}
              checkedIcon={
                <div className={classes.radioIconSelected} style={{ backgroundColor }}>
                  <CheckIcon style={{ fontSize: 30 }} />
                </div>
              }
            />
          </Tooltip>
        )
      })}
    </div>
  )
}

ColorSelector.defaultProps = {
  colorIndex: 600,
}

export default ColorSelector
