import { gql } from '@apollo/client'

/**
 * Any change here should be done in Profiles.ts → OrganizationProfileData
 */
const OwnedApiNodeData = gql`
  fragment OwnedApiNodeData on OwnedApiNode {
    id
    user {
      id
      name
    }
    creator {
      id
      name
    }
    branch {
      id
      name
    }
    shareWithChildBranches
    createdAt
    updatedAt
    canBeDeleted
    canBeUpdated
  }
`

const EDIT_NODE_BRANCH = gql`
  mutation mutateNodeBranch($input: NodeBranchInput!) {
    mutateNodeBranch(input: $input) {
      ...OwnedApiNodeData
    }
  }
  ${OwnedApiNodeData}
`

const OwnedApiNodes = {
  fragments: {
    OwnedApiNodeData,
  },
  EDIT_NODE_BRANCH,
}

export default OwnedApiNodes
