import dayjs from 'dayjs'
import React from 'react'

interface Props {
  start: number
  end: number
}

const DateRange: React.FC<Props> = (props) => {
  const start = dayjs.unix(props.start).format('L')
  const end = dayjs.unix(props.end).format('L')
  return <>{`${start} - ${end}`}</>
}

export default DateRange
