import MaterialLink, { LinkProps as MaterialLinkProps } from '@mui/material/Link'
import { Link as FoundLink, LinkPropsNodeChild } from 'found'
import React from 'react'

function CustomLink(props: MaterialLinkProps) {
  return <MaterialLink variant="body2" underline="hover" {...props} />
}

export default function LinkBody2(props: LinkPropsNodeChild) {
  return <FoundLink as={CustomLink} {...props} />
}
