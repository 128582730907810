import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import {
  KeyValue,
  NotificationMethod,
  NotificationTemplateDataFragment,
  NotificationTemplateDefaultInput,
  NotificationType,
} from '../../generated/graphql'

const useStyles = makeStyles()(() => {
  return {
    table: {
      marginTop: 0,
    },
    headerBlock: {
      display: 'flex',
      flexDirection: 'column',
    },
  }
})

interface Props {
  templates: NotificationTemplateDataFragment[]
  types: KeyValue[]
  methods: KeyValue[]
  defaultTemplatesIds: string[]

  editTemplate(data: NotificationTemplateDefaultInput): void
}

const TemplateSettingsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { templates, types, methods, defaultTemplatesIds } = props
  const headers: string[] = [t('Type'), t('Default template'), t('Use default?')]

  return (
    <div>
      {methods.map((methodObj) => {
        const methodValue = methodObj.value
        const methodKey = methodObj.key
        return (
          <React.Fragment key={methodKey}>
            <Toolbar>
              <Typography variant="h6" id="tableTitle">
                {methodValue}
              </Typography>
            </Toolbar>
            <Table size="small" aria-labelledby="tableTitle" className={classes.table}>
              <TableHead>
                <TableRow>
                  {headers.map((h, idx) => (
                    <TableCell key={'h' + idx}>{h}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {types.map((typeObj) => {
                  const typeValue = typeObj.value
                  const typeKey = typeObj.key
                  const activeTemplates = templates.filter(
                    (template) => template.active && template.type === typeKey && template.method === methodKey,
                  )
                  const defaultTemplate = activeTemplates.find((template) => defaultTemplatesIds.includes(template.id))
                  return (
                    <TableRow key={methodKey + typeKey}>
                      <TableCell>{typeValue}</TableCell>
                      <TableCell>
                        <Select
                          native
                          variant="standard"
                          value={defaultTemplate && defaultTemplate.id}
                          disabled={!defaultTemplate}
                          onChange={async (event) => {
                            const newDefaultId = event.target.value
                            if (!defaultTemplate || newDefaultId !== defaultTemplate.id) {
                              const newDefaultTemplate: NotificationTemplateDataFragment | undefined =
                                activeTemplates.find((tpl) => tpl.id === newDefaultId)
                              if (newDefaultTemplate) {
                                await props.editTemplate({
                                  method: methodKey as NotificationMethod,
                                  type: typeKey as NotificationType,
                                  id: newDefaultTemplate.id,
                                })
                              }
                            }
                          }}
                        >
                          {activeTemplates.map((template) => (
                            <option key={template.id} value={template.id}>
                              {template.title}
                            </option>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={!defaultTemplate}
                          onChange={(_event: object, checked: boolean) => {
                            props.editTemplate({
                              type: typeKey as NotificationType,
                              method: methodKey as NotificationMethod,
                              id: checked ? undefined : activeTemplates[0].id,
                            })
                          }}
                          // use-default checkbox is (true and) disabled if
                          // there are no active private templates for that type && method
                          disabled={!activeTemplates.length}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default TemplateSettingsTable
