import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TagAutocompleteField from './TagAutocompleteField'
import { tagNamesQuery } from '../generated/graphql'
import Tags from '../schema/Tags'

interface Props {
  value: string
  onChange: (newValue: string) => void
}

function TagKeyField({ value, onChange }: Props): React.ReactElement {
  const { data } = useQuery<tagNamesQuery>(Tags.GET_ALL_TAGS_NAMES)
  const { t } = useTranslation()
  return (
    <TagAutocompleteField
      value={value || null}
      options={data?.tagNames}
      fieldLabel={t('Key')}
      onChange={(label: string) => onChange(label)}
    />
  )
}

export default TagKeyField
