import { ApolloError } from '@apollo/client'
import { SnackbarContentProps } from '@mui/material/SnackbarContent'
import React, { useEffect } from 'react'
import Message from './Message'
import log from '../log'

interface Props extends SnackbarContentProps {
  error?: ApolloError
}

export default function GraphqlError(props: Props) {
  const { error, ...otherProps } = props
  useEffect(() => {
    if (error && Array.isArray(error.graphQLErrors)) {
      error.graphQLErrors.forEach((e) => log.error(e))
    }
    if (error && error.networkError) {
      log.error(error.networkError)
    }
  })
  if (!error) {
    return null
  }
  if (!Array.isArray(error.graphQLErrors) || !error.graphQLErrors.length) {
    if (!error.message) {
      return null
    }
    return <Message {...otherProps} type={'error'} message={error.message} />
  }

  return (
    <div>
      {error.graphQLErrors.map((e, index) => (
        <Message key={index} {...otherProps} type={'error'} message={e.message} />
      ))}
    </div>
  )
}
