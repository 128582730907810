import { useMutation, useQuery } from '@apollo/client'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SpeedDialIcon } from '@mui/material'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import BranchDialog from './BranchDialog'
import BranchList from './BranchList'
import { ConditionalComponent } from '../../components/ConditionalComponent'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { UserCanCreate } from '../../components/acl/UserCanDo'
import {
  organizationBranchesQuery,
  OrganizationBranchInput,
  OrganizationBranchMutationMutation,
  OrganizationBranchMutationMutationVariables,
} from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'
import { OrganizationBranches } from '../../schema/OrganizationBranches'

const useStyles = makeStyles()((theme) => {
  return {
    speedDial: {
      'position': 'absolute',
      '&$directionUp, &$directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(3),
      },
      '&$directionDown, &$directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(3),
      },
    },
    directionUp: {},
    directionRight: {},
    directionDown: {},
    directionLeft: {},
  }
})

function OrganizationBranchScreen(): React.ReactElement {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const speedDialClassName = cx(classes.speedDial, classes.directionUp)
  const [showDialog, setShowDialog] = useState(false)
  const { loading, error, data } = useQuery<organizationBranchesQuery>(OrganizationBranches.GET_ORGANIZATION_BRANCH)
  const [mutateBranch] = useMutation<OrganizationBranchMutationMutation, OrganizationBranchMutationMutationVariables>(
    OrganizationBranches.EDIT_ORGANIZATION_BRANCH,
    {
      refetchQueries: [
        {
          query: OrganizationBranches.GET_ORGANIZATION_BRANCH,
        },
      ],
    },
  )
  const createOrEditBranch = useCallback(
    async (input: OrganizationBranchInput) => {
      await mutateBranch({
        variables: {
          input,
        },
      })
    },
    [mutateBranch],
  )

  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  return (
    <div>
      <Helmet>
        <title>{t('Manage Organization branches')}</title>
      </Helmet>

      <SimpleTreeView
        slots={{
          expandIcon: ChevronRightIcon,
          collapseIcon: ExpandMoreIcon,
        }}
      >
        <BranchList
          fullList={data.organizationBranches}
          commitBranch={(input: OrganizationBranchInput) => createOrEditBranch(input)}
        />
      </SimpleTreeView>

      <ConditionalComponent show={showDialog}>
        <div>
          <BranchDialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            commit={(input: OrganizationBranchInput) => createOrEditBranch(input)}
          />
        </div>
      </ConditionalComponent>
      <UserCanCreate entityName={EntityNames.OrganizationBranch}>
        <SpeedDialAction
          title={t('Add root branch')}
          open
          icon={<SpeedDialIcon />}
          onClick={() => setShowDialog(true)}
          className={speedDialClassName}
        />
      </UserCanCreate>
    </div>
  )
}

export default OrganizationBranchScreen
