function removeTypeName<T>(item: T): Omit<T, '__typename'> {
  const { __typename, ...data }: any = item
  return data
}

export function cleanInputData<T extends { [key: string]: any }>(
  data: T,
): Omit<
  T,
  | '__typename'
  | 'canBeDeleted'
  | 'canBeUpdated'
  | 'tableData'
  | 'frontInactive'
  | 'user'
  | 'creator'
  | 'branch'
  | 'shareWithChildBranches'
  | 'createdAt'
  | 'updatedAt'
> {
  // TODO Hacer una limpieza real para mapear al input type
  const {
    __typename,
    canBeDeleted,
    canBeUpdated,
    tableData,
    frontInactive,
    user,
    creator,
    branch,
    shareWithChildBranches,
    createdAt,
    updatedAt,
    ...finalData
  } = data
  return finalData
}

export default {
  removeTypeName,
  cleanInputData,
}

export enum EntityNames {
  EventType = 'EventType',
  EventAttendee = 'EventAttendee',
  EventImport = 'EventImport',
  Profile = 'Profile',
  AclRole = 'AclRole',
  Media = 'Media',
  NodeMedia = 'NodeMedia',
  NotificationTemplate = 'NotificationTemplate',
  Pathway = 'Pathway',
  CalendarEvent = 'CalendarEvent',
  Calendar = 'Calendar',
  OrganizationBranch = 'OrganizationBranch',
  Organizer = 'Organizer',
  MediaDocumentType = 'MediaDocumentType',
  CustomDomainNameRequest = 'CustomDomainNameRequest',
}
