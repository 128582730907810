import React, { PropsWithChildren } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

interface UploadMediaProps extends DropzoneOptions {
  id?: string
  children?: JSX.Element
}

export default function UploadMedia(props: PropsWithChildren<UploadMediaProps>) {
  const { id, children, ...options } = props
  const { t } = useTranslation()
  const { getRootProps, getInputProps } = useDropzone(options)
  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input id={props.id} {...getInputProps()} />
      {!!children && (
        <p>
          <strong>{children}</strong> {t(' (Drop a file here, or click to replace this)')}
        </p>
      )}
      {!children && <p>{t('Drop a file here, or click to select a file')}</p>}
    </div>
  )
}
