import { red } from '@mui/material/colors'
import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles'
import { CSSProperties } from '@mui/material/styles/createMixins'

// noinspection ES6UnusedImports
import type {} from '@mui/x-date-pickers/themeAugmentation'
declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
declare module '@mui/material/styles/createMixins' {
  // Allow for custom mixins to be added
  interface Mixins {
    urlLink?: CSSProperties
  }
}

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(68, 138, 255)',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    fontSize: 13,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true,
        margin: 'normal',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInputAdornment: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
      },
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
