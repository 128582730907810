import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TagAutocompleteField from './TagAutocompleteField'
import { tagValuesQuery } from '../generated/graphql'
import Tags from '../schema/Tags'

interface Props {
  value: string
  onChange: (newValue: string) => void
  rowData: any
  columnDef?: any
}

function TagValueField({ value, onChange, rowData }: Props): React.ReactElement {
  const { t } = useTranslation()
  const key = useMemo(() => rowData?.key, [rowData])
  const { data } = useQuery<tagValuesQuery>(Tags.GET_ALL_TAG_VALUES, { variables: { tagKey: key || '' } })
  return (
    <TagAutocompleteField
      value={value || null}
      options={data?.tagValues}
      fieldLabel={t('Value')}
      onChange={(label: string) => onChange(label)}
    />
  )
}

export default TagValueField
