import { useMutation, useQuery } from '@apollo/client'
import AddBox from '@mui/icons-material/AddBox'
import Edit from '@mui/icons-material/Edit'
import RoomIcon from '@mui/icons-material/Room'
import { Link } from 'found'
import { filter } from 'graphql-anywhere'
import { Column } from 'material-table'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import EventTypeDialog from './EventTypeDialog'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import ColorAvatars from '../../components/colors/ColorAvatars'
import { AclTable } from '../../components/table/AclTable'
import {
  eventsTypesQuery,
  eventsTypesQueryVariables,
  EventTypeDataFragment,
  EventTypeInput,
  EventTypeMutationMutation,
  EventTypeMutationMutationVariables,
} from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import GqlHelper, { useDeleteMutation } from '../../helpers/GqlHelper'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import EventsTypes from '../../schema/EventsTypes'
import Tags from '../../schema/Tags'

const useStyles = makeStyles()((theme) => {
  return {
    color: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
  }
})

const ColorAvatar: React.FC<any> = (props) => {
  const { classes } = useStyles()
  const ColorAvatarComponent = ColorAvatars.getColorAvatar(props.color)
  return <ColorAvatarComponent className={classes.color} />
}

const EventTypeScreen: React.FC = () => {
  const columns: Column<EventTypeDataFragment>[] = [
    { title: 'Color', field: 'color', render: (rowData) => <ColorAvatar color={rowData.color} /> },
    { title: 'Code', field: 'code' },
    { title: 'Name', field: 'name' },
    {
      title: 'Location',
      field: 'location',
      render: (itemData) => {
        return (
          <div style={{ display: 'flex' }}>
            {itemData.location}
            {itemData.lat && itemData.lng && (
              <Link
                to={'https://www.google.com/maps/place/' + itemData.lat + ',' + itemData.lng}
                target="_blank"
                title={itemData.lat + ',' + itemData.lng}
              >
                <RoomIcon fontSize="small" />
              </Link>
            )}
          </div>
        )
      },
    },
    { title: 'Access Type', field: 'accessType' },
  ]

  const { t } = useTranslation()
  const [newEventTypeOpen, setNewEventTypeOpen] = useState(false)
  const [newEventTypeAdded, setNewEventTypeAdded] = useState(null)
  const [inEditEventType, setInEditEventType] = useState<EventTypeDataFragment | null>(null)
  const branchFilter = useBranchFilteredQuery()
  const [deleteEventType] = useDeleteMutation(EventsTypes.GET_EVENTS_TYPES, branchFilter.variables)
  const [mutateEventType] = useMutation<EventTypeMutationMutation, EventTypeMutationMutationVariables>(
    EventsTypes.EDIT_EVENT_TYPE,
    {
      update: GqlHelper.getMutationReducer<EventTypeMutationMutation, eventsTypesQuery>(
        'mutateEventType',
        EventsTypes.GET_EVENTS_TYPES,
        'eventsTypes',
        branchFilter.variables,
      ),
    },
  )

  const { loading, error, data } = useQuery<eventsTypesQuery, eventsTypesQueryVariables>(
    EventsTypes.GET_EVENTS_TYPES,
    branchFilter,
  )

  const createEventType = async (input: EventTypeInput) => {
    const response = await mutateEventType({
      variables: {
        input: {
          ...DataHelper.cleanInputData<EventTypeInput>(input),
          tags: filter(Tags.fragment.TagData, input.tags ?? []),
          reminders: input.reminders?.map((r) => ({ offset: r.offset, template: r.template })),
        },
      },
    })
    setNewEventTypeAdded(response.data.mutateEventType)
  }
  const deleteNode = async (id: string) => {
    await deleteEventType({
      variables: {
        input: { id },
      },
    })
  }

  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  return (
    <div>
      <Helmet>
        <title>{t('Event Types')}</title>
      </Helmet>
      <EventTypeDialog open={newEventTypeOpen} onClose={() => setNewEventTypeOpen(false)} create={createEventType} />
      {!!inEditEventType && (
        <EventTypeDialog
          open={true}
          eventType={inEditEventType}
          onClose={() => setInEditEventType(null)}
          create={createEventType}
        />
      )}
      <AclTable<EventTypeDataFragment>
        title={t('Event Types')}
        entityName={EntityNames.EventType}
        columns={columns}
        data={data?.eventsTypes}
        paginateToElement={newEventTypeAdded}
        customEditAction={{
          icon: () => <Edit />,
          tooltip: t('Edit Event Type'),
          onClick: (event, rowData) => {
            if (Array.isArray(rowData)) {
              setInEditEventType(rowData[0])
            } else {
              setInEditEventType(rowData)
            }
          },
        }}
        customAddAction={{
          icon: () => <AddBox />,
          tooltip: t('Add New Event Type'),
          isFreeAction: true, // the action is not associated to specific rows
          onClick: () => setNewEventTypeOpen(true),
        }}
        editable={{
          onRowDelete: async (oldData) => {
            if (oldData.id) {
              await deleteNode(oldData.id)
            }
          },
        }}
      />
    </div>
  )
}

export default EventTypeScreen
