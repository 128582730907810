import Avatar, { AvatarProps } from '@mui/material/Avatar'
import React from 'react'
import { withStyles } from 'tss-react/mui'
import DH from '../../helpers/DH'
import log from '../../log'

const colorAvatars: Record<string, any> = {}

Object.keys(DH.getDefaultColors()).forEach((colorName) => {
  colorAvatars[colorName] = withStyles(
    ({ children, ...otherProps }: AvatarProps) => <Avatar {...otherProps}>{children}</Avatar>,
    () => ({
      root: {
        backgroundColor: DH.getColor(colorName)[400],
      },
    }),
  )
})

export default {
  getColorAvatar: (color: string): typeof Avatar => {
    let avatar = colorAvatars[color]
    if (!avatar) {
      avatar = colorAvatars[DH.getDefaultColorHex()]
      log.error('Try to get not defined color', color)
    }
    return avatar
  },
}
