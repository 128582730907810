export default {
  API_ENDPOINT: process.env.REACT_APP_API_URL,
  API_GRAPHQL: process.env.REACT_APP_API_URL + '/graphql',
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || 'patient_api',
  APP_VERSION: process.env.REACT_APP_RELEASE_VERSION || 'unknown',
  GOOGLE_API_KEY: 'AIzaSyAqduyJ5hbyJHoJta0F2Yd6hgspWU7Rg6c',
  LOGIN: {
    COOKIE_NAME: 'l',
    ACCESS_TOKEN: 'pjwt',
    REFRESH_TOKEN: 'prjwt',
    STAY_LOGGED: 'sl',
    LOGOUT_STORAGE_KEY: 'logout',
    LOGIN_STORAGE_KEY: 'login',
  },
  OPEN_USER_REGISTER: !!Number(process.env.REACT_APP_OPEN_USER_REGISTER),
}
