import { useMutation } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/HighlightOff'
import IconButton from '@mui/material/IconButton'
import React, { useCallback, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { MediaFieldView } from './MediaFieldView'
import {
  DeleteNotGlobalMediaMutation,
  DeleteNotGlobalMediaMutationVariables,
  MediaMutationMutation,
  MediaMutationMutationVariables,
} from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'
import Medias from '../../schema/Medias'
import MediaLibrarySelectorControl from '../../screens/media/MediaLibrarySelectorControl'
import GraphqlError from '../GraphqlError'
import Loading from '../Loading'
import Message from '../Message'
import { NodeCanBeDeleted } from '../acl/CanBe'
import { UserCanCreate } from '../acl/UserCanDo'

interface Props {
  id?: string
  idMedia?: string
  notDeleteMedia?: boolean
  ownerNodeId?: string
  showPreview?: boolean
  showGallery?: boolean
  options?: DropzoneOptions
  onChange(id?: string | null, file?: File): void
}

const useStyles = makeStyles()(() => {
  return {
    fieldView: {
      display: 'flex',
      alignItems: 'center',
    },
    galleryButton: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }
})

const MediaField: React.FC<Props> = ({
  id,
  idMedia,
  onChange,
  notDeleteMedia,
  ownerNodeId,
  showPreview = false,
  showGallery = true,
  options,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [mutateMedia, { error, loading }] = useMutation<MediaMutationMutation, MediaMutationMutationVariables>(
    Medias.UPLOAD_FILE,
  )

  const [openGallery, setOpenGallery] = useState(false)

  const fOpenGallery = (ev) => {
    ev.stopPropagation()
    setOpenGallery(true)
  }

  const closeGallery = (ev) => {
    if (ev) {
      ev.stopPropagation()
    }
    setOpenGallery(false)
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file: File = acceptedFiles[0]
      if (file) {
        const d = await mutateMedia({
          variables: {
            input: {
              file,
              name: file.name,
              global: false,
            },
          },
        })
        if (d.data) {
          onChange(d.data.mutateMedia.id, file)
        }
      }
    },
    [mutateMedia, onChange],
  )
  const { getRootProps, getInputProps } = useDropzone({ ...options, onDrop, multiple: false })
  const [deleteNode, { loading: deleteLoading }] = useMutation<
    DeleteNotGlobalMediaMutation,
    DeleteNotGlobalMediaMutationVariables
  >(Medias.DELETE_NOT_GLOBAL)

  function deleteMedia() {
    if (idMedia) {
      if (!notDeleteMedia) {
        onChange(null)
        // noinspection JSIgnoredPromiseFromCall
        deleteNode({
          variables: {
            input: {
              id: idMedia,
            },
          },
        })
      } else {
        // Others will handle delete
        onChange(null)
      }
    }
  }

  if (loading) {
    return <Loading variant="inline" />
  }
  let errorComponent
  if (error) {
    errorComponent = <GraphqlError error={error} />
  }
  let content
  if (idMedia) {
    content = (
      <div id={id} className={classes.fieldView}>
        <MediaFieldView idMedia={idMedia} showPreview={showPreview} />
        <NodeCanBeDeleted entityId={idMedia}>
          {deleteLoading ? (
            <Loading variant="inline" />
          ) : (
            <IconButton aria-label={t('Delete')} onClick={deleteMedia} size="large">
              <DeleteIcon />
            </IconButton>
          )}
        </NodeCanBeDeleted>
      </div>
    )
  } else {
    const librarySelect = openGallery && (
      <MediaLibrarySelectorControl id={id} onChange={onChange} closeFunction={closeGallery} ownerNodeId={ownerNodeId} />
    )

    const buttonLibrarySelect = showGallery && (
      <a href="#" className={classes.galleryButton} onClick={fOpenGallery}>
        {t('or select from gallery')}
      </a>
    )

    content = (
      <UserCanCreate
        entityName={EntityNames.Media}
        denyComponent={<Message type={'error'} message={t('You are not allowed to create media files')} />}
      >
        <div {...getRootProps({ className: 'dropzone' })}>
          <input id={id} {...getInputProps()} />
          <p>
            {t('Drop a file here, or click to select a file')} {buttonLibrarySelect}
          </p>
        </div>
        {librarySelect}
      </UserCanCreate>
    )
  }
  return (
    <div>
      {errorComponent}
      {content}
    </div>
  )
}

export default MediaField
