import { gql } from '@apollo/client'
import OwnedApiNodes from './OwnedApiNode'

const DomainInput = gql`
  fragment DomainInput on CustomDomainNameRequest {
    id
    domainName
    fromEmail
    fromEmailName
    status
    customDomainNameDnsRecords {
      id
      type
      name
      value
    }
  }
`

const DomainData = gql`
  fragment DomainData on CustomDomainNameRequest {
    ...DomainInput
    ...OwnedApiNodeData
  }
  ${DomainInput}
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
`

const GET_DOMAIN = gql`
  query getDomain {
    getCustomDomainNameRequest {
      ...DomainData
    }
  }
  ${DomainData}
`

const EDIT_DOMAIN = gql`
  mutation DomainEdit($input: CustomDomainNameRequestInput!) {
    mutateCustomDomainNameRequest(input: $input) {
      ...DomainData
    }
  }
  ${DomainData}
`

const CANCEL_DOMAIN = gql`
  mutation DomainCancel($input: NodeSelectInput!) {
    mutateCancelCustomDomainNameRequest(input: $input) {
      ...DomainData
    }
  }
  ${DomainData}
`

const IN_PROGRESS_DOMAIN = gql`
  mutation DomainInProgress($input: NodeSelectInput!) {
    mutateInProgressCustomDomainNameRequest(input: $input) {
      ...DomainData
    }
  }
  ${DomainData}
`

const Domain = {
  GET_DOMAIN,
  EDIT_DOMAIN,
  CANCEL_DOMAIN,
  IN_PROGRESS_DOMAIN,
}

export default Domain
