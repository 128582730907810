import Popover, { PopoverProps } from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import React, { ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }
})

interface Props {
  children: ReactNode
  popover: ReactNode
  popoverProps?: Partial<PopoverProps>
  onOpen?(): void
  onClose?(): void
}

export default function MouseOverPopover(props: Props) {
  const { children, popover, popoverProps = {}, onOpen, onClose } = props
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorEl(event.currentTarget)
    if (onOpen) {
      onOpen()
    }
  }

  function handlePopoverClose() {
    setAnchorEl(null)
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <Typography
        aria-owns={'mouse-over-popover'}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ display: 'inline' }}
      >
        {children}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
        {...popoverProps}
      >
        {popover}
      </Popover>
    </>
  )
}
