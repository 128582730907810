import { useQuery } from '@apollo/client'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { OrganizationBranchDataFragment, userBranchesQuery } from '../../generated/graphql'
import { useBranchContext } from '../../providers/BranchContext'
import { useUserContext } from '../../providers/UserContext'
import { OrganizationBranches } from '../../schema/OrganizationBranches'
import GraphqlError from '../GraphqlError'

const useStyles = makeStyles()((theme) => {
  return {
    title: {
      display: 'none',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }
})

export default function BranchButton(): JSX.Element {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const user = useUserContext()
  const { branch, setBranch } = useBranchContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { data, error } = useQuery<userBranchesQuery>(OrganizationBranches.GET_USER_BRANCHES)
  const userBranch = user.viewer?.organizationBranch
  const text = useMemo(() => {
    if (branch) {
      return t('{{name}} only', { name: branch.name })
    }
    if (userBranch) {
      if (data?.userBranches?.length > 1) {
        return t('{{name}} and children', { name: userBranch.name })
      }
      return userBranch.name
    }
    if (user.isOrganization()) {
      return t('All')
    }
    return ''
  }, [user, branch, userBranch, data, t])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (newBranch?: OrganizationBranchDataFragment) => {
    setBranch(newBranch)
    setAnchorEl(null)
  }
  if (!user.isOrganization() && !userBranch) {
    return null
  }
  return (
    <div className={classes.container}>
      <GraphqlError error={error} />
      <AccountTreeIcon />
      <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
        {text}
      </Typography>
      {data?.userBranches?.length > 1 && (
        <>
          <IconButton
            aria-label={t('Organization filter')}
            color="inherit"
            aria-controls="branch-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="large"
          >
            <ExpandMoreIcon />
          </IconButton>
          <Menu
            id="branch-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleClose(null)}>{t('All')}</MenuItem>
            {data?.userBranches?.map((d) => (
              <MenuItem key={d.id} onClick={() => handleClose(d)}>
                {d.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  )
}
