import AttendeesIcon from '@mui/icons-material/People'
import RoomIcon from '@mui/icons-material/Room'
import Badge from '@mui/material/Badge/Badge'
import { green, orange, yellow } from '@mui/material/colors'
import React, { FunctionComponent, ReactElement } from 'react'
import { EventProps } from 'react-big-calendar'
import { makeStyles } from 'tss-react/mui'
import IconWithText from '../../components/IconWithText'
import ColorAvatars from '../../components/colors/ColorAvatars'
import { CalendarEventDataFragment } from '../../generated/graphql'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      borderRadius: 5,
      padding: '2px 5px',
      alignItems: 'center',
    },
    text: {
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    badge: {
      marginRight: 10,
    },
    badgeContent: {
      height: theme.spacing(1.5),
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
      minWidth: theme.spacing(1.5),
      fontSize: 'xx-small',
    },
    badgeOrange: {
      backgroundColor: orange.A700,
      color: theme.palette.text.primary,
    },
    badgeYellow: {
      backgroundColor: yellow.A700,
      color: theme.palette.text.primary,
    },
    badgeGreen: {
      backgroundColor: green.A700,
      color: theme.palette.text.primary,
    },
  }
})

const AgendaCalendarEventView: FunctionComponent<EventProps<CalendarEventDataFragment>> = ({ event, title }) => {
  const { classes, cx } = useStyles()
  const ColorAvatar = ColorAvatars.getColorAvatar(event.color)
  let badge: ReactElement
  let location: ReactElement | null = null
  if (event.location) {
    location = (
      <IconWithText text={event.location} variant="caption" icon={RoomIcon} iconProps={{ fontSize: 'small' }} />
    )
  }

  if (event.hasAttendeeRegistration) {
    const attendeeCount = event.attendees.length
    let badgeText: string = attendeeCount.toString()
    const maxAttendee = event.attendeeLimit
    let percent = 0
    if (maxAttendee) {
      // Calculate attendee occupancy
      if (attendeeCount) {
        percent = (attendeeCount * 100) / maxAttendee
        badgeText = percent + '%'
      }
    }
    badge = (
      <Badge
        badgeContent={badgeText}
        color="primary"
        className={classes.badge}
        overlap="circular"
        classes={{
          badge: cx(classes.badgeContent, {
            [classes.badgeGreen]: percent < 30,
            [classes.badgeYellow]: percent < 70 && percent >= 30,
            [classes.badgeOrange]: percent >= 70,
          }),
        }}
      >
        <ColorAvatar style={{ width: 25, height: 25 }}>
          <AttendeesIcon fontSize="small" />
        </ColorAvatar>
      </Badge>
    )
  } else {
    badge = <ColorAvatar style={{ width: 25, height: 25, marginRight: 10 }} />
  }
  return (
    <div className={classes.root}>
      {badge}
      <strong className={classes.text}>{title}</strong>
      {location}
    </div>
  )
}

export default AgendaCalendarEventView
