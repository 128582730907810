import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import TextField from '@mui/material/TextField'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { StaticEventOccurrenceInput } from '../../generated/graphql'
import DH from '../../helpers/DH'

interface Props {
  allDay?: boolean
  dates: StaticEventOccurrenceInput[]
  defaultDate?: number
  minDate: number

  onEdit(events: StaticEventOccurrenceInput[]): void
}

const useStyles = makeStyles()((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(5),
      position: 'relative',
      minWidth: '340px',
      [theme.breakpoints.down('md')]: {
        maxWidth: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '480px',
      },
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(-4),
      right: theme.spacing(2),
      whiteSpace: 'nowrap',
    },
    initialFab: {
      left: theme.spacing(2),
      position: 'absolute',
      whiteSpace: 'nowrap',
    },
    listItem: {
      marginBottom: theme.spacing(1),
    },
    picker: {
      minWidth: '150px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }
})

const StaticRecurrenceDatesTable: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { allDay, dates: recurrenceDates, minDate, onEdit } = props
  const defaultDate = props.defaultDate ?? minDate
  const [errorMinDate, setErrorMinDate] = useState<boolean>(false)

  const onUpdate = useCallback(
    (idx?: number, date?: number) => {
      if (idx === undefined) {
        // Add date
        recurrenceDates.push({ start: defaultDate })
      } else if (date === undefined) {
        // Delete date
        recurrenceDates.splice(idx, 1)
      } else {
        // Edit date
        recurrenceDates[idx].start = date
      }
      // Update any change
      onEdit(recurrenceDates)
    },
    [defaultDate, onEdit, recurrenceDates],
  )

  return (
    <Box className={classes.container}>
      <List>
        {recurrenceDates.map((event, idx) => {
          return (
            <ListItem alignItems="flex-start" className={classes.listItem} disableGutters={true} key={idx}>
              <FormControl className={classes.picker} required error={errorMinDate}>
                <DatePicker
                  inputFormat={DH.getDateFormat()}
                  renderInput={(params) => <TextField variant="standard" {...params} error={errorMinDate} />}
                  minDate={dayjs.unix(minDate)}
                  onError={(reason) => {
                    if (reason === 'minDate') {
                      setErrorMinDate(true)
                    }
                  }}
                  onChange={(d: Dayjs) => d && onUpdate(idx, d.unix())}
                  value={dayjs.unix(event.start)}
                />
                {errorMinDate && <FormHelperText>{t('Not before original event')}</FormHelperText>}
              </FormControl>
              {allDay ? null : (
                <FormControl className={classes.picker} required>
                  <TimePicker
                    ampm={DH.isTimeFormat12h()}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    onChange={(d: Dayjs) => d && onUpdate(idx, d.unix())}
                    value={dayjs.unix(event.start)}
                  />
                </FormControl>
              )}
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => onUpdate(idx)} size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
      <Fab
        aria-label="add"
        className={recurrenceDates.length ? classes.fab : classes.initialFab}
        color="primary"
        onClick={() => onUpdate()}
        size="medium"
        variant="extended"
      >
        <AddIcon /> {t('Add date')}
      </Fab>
    </Box>
  )
}

export default StaticRecurrenceDatesTable
