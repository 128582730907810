import dayjs from 'dayjs'
import enGbLocale from 'dayjs/locale/en-gb'
import esLocale from 'dayjs/locale/es'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import timezone from 'dayjs/plugin/timezone'
import I18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(minMax)
dayjs.extend(timezone)

I18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default I18n

const availableLocales: Record<string, string> = { en: 'en-GB', es: 'es' }

const LOCALE_MAP = {
  es: esLocale,
  en: enGbLocale,
}

function getLocale(locale: string) {
  if (!locale) {
    return LOCALE_MAP[mapLocale(availableLocales.en)]
  }
  const localeString = mapLocale(locale)
  return LOCALE_MAP[localeString]
}

function mapLocale(localeString: string): string {
  let localeKey = localeString.split('-')[0]
  if (!Object.keys(availableLocales).includes(localeKey)) {
    localeKey = 'en'
  }
  return availableLocales[localeKey].toLowerCase()
}

export const i18nHelper = {
  getLocale,
}
