import { useMutation } from '@apollo/client'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ListIcon from '@mui/icons-material/List'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import { List, ListItemButton, ListItemText } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { filter } from 'graphql-anywhere'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CalendarListItem from './CalendarListItem'
import { CalendarDataFragment } from '../../generated/graphql'
import { useDeleteMutation } from '../../helpers/GqlHelper'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import { useCalendars } from '../../providers/CalendarContext'
import Calendars from '../../schema/Calendars'
import CalendarDialog from '../../screens/calendar/CalendarDialog'
import { ConditionalComponent } from '../ConditionalComponent'
import ConfirmDialog from '../dialogs/ConfirmDialog'

const CalendarsList: React.FC = () => {
  const { t } = useTranslation()
  const [myCalendarsOpen, setMyCalendarsOpen] = React.useState(false)
  const [inEditCalendar, setInEditCalendar] = useState<CalendarDataFragment | null>(null)
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<CalendarDataFragment | null>(null)
  const branchFilter = useBranchFilteredQuery()
  const [deleteCalendar] = useDeleteMutation(Calendars.GET_CALENDARS, branchFilter.variables)
  const [editCalendar] = useMutation<CalendarDataFragment>(Calendars.EDIT_CALENDAR)
  const { calendars, toggleSelectedCalendar } = useCalendars()
  const [defCalendar, setdefCalendar] = useState({ id: 'default' })
  const [selectAllorNone, setselectAllorNone] = useState(false)

  const deleteAction = async (calendar: CalendarDataFragment) => {
    await deleteCalendar({
      variables: {
        input: {
          id: calendar.id,
        },
      },
    })
  }
  const editAction = async (calendar: CalendarDataFragment) => {
    await editCalendar({
      variables: { input: filter(Calendars.fragments.CalendarInput, calendar) },
    })
  }

  const setDefaultAction = async (calendar) => {
    setdefCalendar(calendar)
  }

  async function allItemsOnListModify(event: React.MouseEvent<HTMLElement>, optionSelected) {
    setselectAllorNone(optionSelected)
    const actionToPerformVariables = {
      optionSelected,
      defaultMyCalendar: defCalendar.id === 'default' ? calendars[0].id : defCalendar.id,
    }
    toggleSelectedCalendar(actionToPerformVariables)
  }

  return (
    <>
      <ListItem
        button
        onClick={() => {
          setMyCalendarsOpen(!myCalendarsOpen)
        }}
      >
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary={t('My calendars')} />
        {myCalendarsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={myCalendarsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {!selectAllorNone && (
            <ListItemButton
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                allItemsOnListModify(event, true)
              }}
            >
              <ListItemIcon>
                <UnpublishedIcon />
              </ListItemIcon>
              <ListItemText primary="Select none" />
            </ListItemButton>
          )}
          {selectAllorNone && (
            <ListItemButton
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                allItemsOnListModify(event, false)
              }}
            >
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Select all" />
            </ListItemButton>
          )}

          {calendars.map((cal) => (
            <CalendarListItem
              checked={cal.isSelected}
              toggleChecked={() => toggleSelectedCalendar(cal.id)}
              key={cal.id}
              calendar={cal}
              setDefaultAction={(calendar: CalendarDataFragment) => setDefaultAction(calendar)}
              deleteAction={(calendar: CalendarDataFragment) => setShowDeleteConfirmDialog(calendar)}
              editAction={(calendar: CalendarDataFragment) => setInEditCalendar(calendar)}
            />
          ))}
        </List>
      </Collapse>
      <ConditionalComponent show={inEditCalendar}>
        <CalendarDialog
          open={true}
          // eslint-disable-next-line
          calendar={inEditCalendar!}
          onClose={() => setInEditCalendar(null)}
          commit={editAction}
        />
      </ConditionalComponent>
      <ConfirmDialog
        open={!!showDeleteConfirmDialog}
        onClose={() => setShowDeleteConfirmDialog(null)}
        onConfirm={() => {
          if (showDeleteConfirmDialog) {
            deleteAction(showDeleteConfirmDialog)
            setShowDeleteConfirmDialog(null)
          }
        }}
        title={t('Delete calendar')}
        text={t('Are you sure you want to delete this calendar? All related events will be deleted')}
      />
    </>
  )
}

export default CalendarsList
