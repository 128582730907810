import I18n from 'i18next'

export function requiredField(field: string) {
  return (rowData) =>
    !rowData[field] || rowData[field] === null || rowData[field] === undefined || rowData[field].match(/^ *$/) !== null
      ? I18n.t('Required')
      : true
}

export function requiredEmail(field: string) {
  return (rowData) => {
    if (!rowData[field]) {
      return I18n.t('Required')
    } else if (!/^\S+@\S+(\.\S+)+$/.test(rowData[field])) {
      return I18n.t('Enter a valid email address')
    } else {
      return true
    }
  }
}
