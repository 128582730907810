import { useQuery } from '@apollo/client'
import AddBox from '@mui/icons-material/AddBox'
import Edit from '@mui/icons-material/Edit'
import RoomIcon from '@mui/icons-material/Room'
import { Link, useRouter } from 'found'
import { Column } from 'material-table'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { AclTable } from '../../components/table/AclTable'
import { PathwayDataFragment, pathwaysQuery, pathwaysQueryVariables } from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'
import { useDeleteMutation } from '../../helpers/GqlHelper'
import routes from '../../helpers/routes'
import { useBranchFilteredQuery } from '../../hooks/BranchFilterHook'
import Pathways from '../../schema/Pathways'

const columns: Column<PathwayDataFragment>[] = [
  { title: 'Title', field: 'title' },
  {
    title: 'Location',
    field: 'location',
    render: (data) => {
      return (
        <div style={{ display: 'flex' }}>
          {data.location}{' '}
          {data.lat && data.lng && (
            <Link
              to={'http://www.google.com/maps/place/' + data.lat + ',' + data.lng}
              target="_blank"
              title={data.lat + ',' + data.lng}
            >
              <RoomIcon fontSize="small" />
            </Link>
          )}
        </div>
      )
    },
  },
  { title: 'Access Type', field: 'accessType' },
]

const ManagePathwaysScreen: React.FC = () => {
  const { router } = useRouter()
  const { t } = useTranslation()
  const branchFilter = useBranchFilteredQuery()
  const [deletePathway] = useDeleteMutation(Pathways.GET_PATHWAYS, branchFilter.variables)
  const { loading, error, data } = useQuery<pathwaysQuery, pathwaysQueryVariables>(Pathways.GET_PATHWAYS, branchFilter)

  const deleteNode = async (id: string) => {
    await deletePathway({ variables: { input: { id } } })
    return true
  }

  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  const pathways = data.pathways
  return (
    <div>
      <Helmet>
        <title>{t('Pathways')}</title>
      </Helmet>
      <AclTable<PathwayDataFragment>
        customAddAction={{
          icon: () => <AddBox />,
          tooltip: t('Add New Pathway'),
          isFreeAction: true, // the action is not associated to specific rows
          onClick: () => {
            router.push(routes.app_settings_pathways_new)
          },
        }}
        customEditAction={{
          icon: () => <Edit />,
          tooltip: t('Edit Pathway'),
          onClick: (_event: any, rowData: PathwayDataFragment | PathwayDataFragment[]) => {
            let id
            if (Array.isArray(rowData)) {
              id = rowData[0].id
            } else {
              id = rowData.id
            }
            router.push(routes.app_settings_pathways_edit + '/' + id)
          },
        }}
        entityName={EntityNames.Pathway}
        title={t('Pathways')}
        columns={columns}
        options={{
          showTitle: false,
        }}
        data={pathways}
        editable={{
          onRowDelete: async (oldData) => {
            if (oldData.id) {
              await deleteNode(oldData.id)
            }
          },
        }}
      />
    </div>
  )
}

export default ManagePathwaysScreen
