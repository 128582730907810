import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React from 'react'

interface RowProps {
  label: string
  value: string
}

function ApiNodeInfoRow({ label, value }: RowProps): React.ReactElement {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>{value || ''}</TableCell>
    </TableRow>
  )
}

export default ApiNodeInfoRow
