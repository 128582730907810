import { gql } from '@apollo/client'
import NodeMedias from './NodeMedias'
import OwnedApiNodes from './OwnedApiNode'
import Tags from './Tags'

const EventTypeData = gql`
  fragment EventTypeData on EventType {
    id
    code
    name
    title
    language
    description
    featuredImage {
      id
    }
    duration
    hasAttendeeRegistration
    isOnline
    location
    locationName
    accessType
    lat
    lng
    attendeeLimit
    color
    tags {
      ...TagData
    }
    files {
      ...NodeMediaData
    }
    notificationTemplates {
      id
      notification {
        id
      }
    }
    recurrence {
      id
      type
      rrule
    }
    reminders {
      offset
      template
    }
    organizers {
      id
    }
    ...OwnedApiNodeData
  }
  ${Tags.fragment.TagData}
  ${OwnedApiNodes.fragments.OwnedApiNodeData}
  ${NodeMedias.fragments.NodeMediaData}
`

const GET_EVENTS_TYPES = gql`
  query eventsTypes($branchFilter: NodeBranchFilterInput) {
    eventsTypes(branchFilter: $branchFilter) {
      ...EventTypeData
    }
  }
  ${EventTypeData}
`

const EDIT_EVENT_TYPE = gql`
  mutation EventTypeMutation($input: EventTypeInput!) {
    mutateEventType(input: $input) {
      ...EventTypeData
    }
  }
  ${EventTypeData}
`
const EventsTypes = {
  GET_EVENTS_TYPES,
  EDIT_EVENT_TYPE,
  fragments: {
    EventTypeData,
  },
}

export default EventsTypes
