import { useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EventTypeForm from './EventTypeForm'
import AsyncButton from '../../components/AsyncButton'
import RemindersTable from '../../components/RemindersTable'
import TagsTable from '../../components/TagsTable'
import DialogTabs from '../../components/dialogs/DialogTabs'
import FilesTable from '../../components/files/FilesTable'
import { EventAccessType, EventTypeDataFragment, EventTypeInput, RecurrenceType } from '../../generated/graphql'
import DH from '../../helpers/DH'
import TemplateSettingsTab from '../notifications/TemplateSettingsTab'

interface Props {
  open: boolean
  eventType?: EventTypeDataFragment

  onClose(): void
  create(input: EventTypeInput): void
}

function getInitialState(props: Props, forceReset = false): EventTypeInput {
  if (props.eventType && !forceReset) {
    const { featuredImage, files, organizers, ...event } = props.eventType
    return {
      ...event,
      files:
        files &&
        files.map((f) => ({
          id: f.id,
          id_media: f.file.id,
          documentTypeId: f.documentType?.id,
        })),
      organizersIds: organizers.map((o) => o.id),
      featuredImageId: featuredImage ? featuredImage.id : undefined,
      tags: props.eventType.tags && props.eventType.tags.map((t) => ({ key: t.key, value: t.value })),
      notificationTemplates:
        props.eventType.notificationTemplates && props.eventType.notificationTemplates.map((nt) => nt.notification.id),
    }
  }
  return {
    code: '',
    name: '',
    title: '',
    description: '',
    duration: 0,
    hasAttendeeRegistration: false,
    isOnline: false,
    language: [],
    accessType: EventAccessType.Private,
    attendeeLimit: 0,
    color: DH.getDefaultColorHex(),
    recurrenceType: RecurrenceType.No_Recurrence,
    organizersIds: [],
  }
}

const EventTypeDialog: React.FC<Props> = (props) => {
  const { open, onClose, create } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const [createError, setCreateError] = useState(null)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [state, setState] = useState(getInitialState(props))
  const createEventType = async () => {
    try {
      setCreateError(null)
      await create(state)
      setState(getInitialState(props, true))
      onClose()
    } catch (e) {
      setCreateError(e)
    }
  }
  const tabLabels = [t('Details'), t('Documents'), t('Tags'), t('Reminders'), t('Notification templates')]
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
      fullScreen={fullScreen}
    >
      <DialogTitle id="form-dialog-title">
        {props.eventType ? t('Edit event type') : t('Create event type')}
      </DialogTitle>
      <DialogTabs tabLabels={tabLabels}>
        <DialogContent>
          {!props.eventType && <DialogContentText>{t('Please fill the new event type details')}</DialogContentText>}
          <EventTypeForm state={state} setState={setState} error={createError} />
        </DialogContent>
        <DialogContent>
          <FilesTable
            ownerNode={props.eventType}
            files={state.files || []}
            setFiles={(files) => setState({ ...state, files })}
          />
        </DialogContent>
        <DialogContent>
          <TagsTable tags={state.tags || []} setTags={(tags) => setState({ ...state, tags })} />
        </DialogContent>
        <DialogContent>
          <RemindersTable
            reminders={state.reminders || []}
            setReminders={(reminders) => setState({ ...state, reminders })}
          />
        </DialogContent>
        <DialogContent>
          <TemplateSettingsTab
            defaultTemplates={state.notificationTemplates || []}
            setDefaultTemplates={(notificationTemplates) => setState({ ...state, notificationTemplates })}
          />
        </DialogContent>
      </DialogTabs>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('Cancel')}
        </Button>
        <AsyncButton onClick={createEventType} disabled={!state.name || !state.title || !state.code} />
      </DialogActions>
    </Dialog>
  )
}

export default EventTypeDialog
