import { RouterState } from 'found'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import EventImportEditContent from './EventImportEditContent'
import CreateOrEditComponent from '../../components/CreateOrEditComponent'
import { UserCanCreateOrEditScreen } from '../../components/acl/UserCanDo'
import { eventImportQuery } from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'
import EventImports from '../../schema/EventImports'

export default function EventImportEditScreen(props: RouterState) {
  const { t } = useTranslation()
  return (
    <div>
      <Helmet>
        <title>{t(props.match.params.id ? 'Event import' : 'New event import')}</title>
      </Helmet>
      <UserCanCreateOrEditScreen entityName={EntityNames.EventImport}>
        <CreateOrEditComponent<eventImportQuery>
          query={EventImports.GET_EVENT_IMPORT}
          id={props.match.params.id}
          render={(query?: eventImportQuery) => {
            return <EventImportEditContent eventImport={query && query.eventImport} />
          }}
        />
      </UserCanCreateOrEditScreen>
    </div>
  )
}
