import MaterialTable, { Column } from 'material-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TagKeyField from './TagKeyField'
import TagValueField from './TagValueField'
import TableHelper, { deleteTableElement, updateTableElement, WithTableData } from '../helpers/TableHelper'
import { requiredField } from '../helpers/validations'

const columns: Column<Tag>[] = [
  {
    title: 'Key',
    field: 'key',
    editComponent: TagKeyField,
    validate: requiredField('key'),
    cellStyle: {
      minWidth: 250,
    },
  },
  {
    title: 'Value',
    field: 'value',
    editComponent: TagValueField,
    validate: requiredField('value'),
    cellStyle: {
      minWidth: 250,
    },
  },
]

type Tag = { key: string; value: string }

interface Props {
  tags: Tag[]

  setTags(tags: Tag[]): void
}

const TagsTable: React.FC<Props> = ({ tags, setTags }) => {
  const { t } = useTranslation()
  return (
    <MaterialTable<Tag & WithTableData>
      title={t('Tags')}
      columns={columns}
      icons={TableHelper.getTableIcons()}
      data={[...tags]}
      options={{
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowAdd: async (tag) => {
          setTags([...tags, tag])
        },
        onRowUpdate: async (newTag, oldTag) => {
          setTags(updateTableElement<Tag & WithTableData>(newTag, oldTag, tags))
        },
        onRowDelete: async (tag) => {
          setTags(deleteTableElement<Tag & WithTableData>(tag, tags))
        },
      }}
    />
  )
}

export default TagsTable
