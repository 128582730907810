import { gql } from '@apollo/client'

const EventAttendeeData = gql`
  fragment EventAttendeeData on EventAttendee {
    id
    profile {
      id
      name
      email
    }
    event {
      id
      title
    }
    type
    confirmed
    attended
    message
    canBeDeleted
    canBeUpdated
  }
`

const EDIT_ATTENDANCE = gql`
  mutation AttendanceMutation($input: EventAttendeeAttendanceInput!) {
    mutateEventAttendeeAttendance(input: $input) {
      ...EventAttendeeData
    }
  }
  ${EventAttendeeData}
`

const EDIT_ATTENDEE = gql`
  mutation AttendeeMutation($input: EventAttendeeInput!) {
    mutateEventAttendee(input: $input) {
      ...EventAttendeeData
    }
  }
  ${EventAttendeeData}
`

const GET_ATTENDEES = gql`
  query attendees($eventId: String!) {
    eventAttendees(id: $eventId) {
      ...EventAttendeeData
    }
  }
  ${EventAttendeeData}
`

const INVITE_ATTENDEE_RESPONSE = gql`
  mutation DecodeAndUpdateResponse($input: TokenInvitePatientInput!) {
    decodeAndUpdateResponse(input: $input) {
      response
    }
  }
`

const INVITE_ATTENDEE_LOCALE_GET = gql`
  mutation DecodeAndFindLocale($input: TokenInvitePatientInput!) {
    decodeAndFindLocale(input: $input) {
      response
    }
  }
`

const EventAttendees = {
  EDIT_ATTENDANCE,
  EDIT_ATTENDEE,
  GET_ATTENDEES,
  INVITE_ATTENDEE_RESPONSE,
  INVITE_ATTENDEE_LOCALE_GET,
  fragments: {
    EventAttendeeData,
  },
}

export default EventAttendees
