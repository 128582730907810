import { ExpandLess, ExpandMore } from '@mui/icons-material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CalendarToday from '@mui/icons-material/CalendarToday'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DescriptionIcon from '@mui/icons-material/Description'
import DomainIcon from '@mui/icons-material/Domain'
import ExitToApp from '@mui/icons-material/ExitToApp'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import GroupIcon from '@mui/icons-material/Group'
import PersonIcon from '@mui/icons-material/Person'
import Settings from '@mui/icons-material/Settings'
import StyleIcon from '@mui/icons-material/Style'
import TimelineIcon from '@mui/icons-material/Timeline'
import { List, ListItemText } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useRouter } from 'found'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import CalendarsList from './CalendarsList'
import { EntityNames } from '../../helpers/DataHelper'
import Auth from '../../helpers/auth'
import routes from '../../helpers/routes'
import { useUserContext } from '../../providers/UserContext'
import { OrganizationsSection } from '../acl/OrganizationsSection'
import { UserCanRead } from '../acl/UserCanDo'

const useStyles = makeStyles()((theme) => {
  return {
    nested: {
      paddingLeft: theme.spacing(4),
    },
    list: {
      width: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
    },
    accountItems: {
      marginTop: 'auto',
      width: '100%',
    },
  }
})

export default function SidebarMainMenu() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const user = useUserContext()
  const { router, match } = useRouter()
  const [isSettingsExpanded, setSettingsExpanded] = useState(false)
  return (
    <List component="nav" className={classes.list}>
      <ListItem
        button
        selected={match.location.pathname === routes.app.index}
        onClick={() => {
          router.push(routes.app.index)
        }}
      >
        <ListItemIcon>
          <CalendarToday />
        </ListItemIcon>
        <ListItemText primary={t('Calendar')} />
      </ListItem>
      <CalendarsList />
      <ListItem button onClick={() => setSettingsExpanded(!isSettingsExpanded)}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary={t('Settings')} />
        {isSettingsExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isSettingsExpanded} timeout={'auto'} unmountOnExit>
        <List disablePadding dense>
          <UserCanRead entityName={EntityNames.EventType}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_types}
              onClick={() => {
                router.push(routes.app_settings_types)
              }}
            >
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary={t('Appointment Types')} />
            </ListItem>
          </UserCanRead>
          <OrganizationsSection>
            <UserCanRead entityName={EntityNames.Profile}>
              <ListItem
                button
                className={classes.nested}
                selected={match.location.pathname === routes.app_settings_users}
                onClick={() => {
                  router.push(routes.app_settings_users)
                }}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={t('Users')} />
              </ListItem>
            </UserCanRead>
          </OrganizationsSection>
          <OrganizationsSection>
            <UserCanRead entityName={EntityNames.AclRole}>
              <ListItem
                button
                className={classes.nested}
                selected={match.location.pathname === routes.app_settings_roles}
                onClick={() => {
                  router.push(routes.app_settings_roles)
                }}
              >
                <ListItemIcon>
                  <StyleIcon />
                </ListItemIcon>
                <ListItemText primary={t('Roles')} />
              </ListItem>
            </UserCanRead>
          </OrganizationsSection>

          <UserCanRead entityName={EntityNames.MediaDocumentType}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_document_types}
              onClick={() => {
                router.push(routes.app_settings_document_types)
              }}
            >
              <ListItemIcon>
                <AttachFileIcon />
              </ListItemIcon>
              <ListItemText primary={t('Document Types')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.Media}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_media_library}
              onClick={() => {
                router.push(routes.app_settings_media_library)
              }}
            >
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary={t('Media Library')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.NotificationTemplate}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_templates}
              onClick={() => {
                router.push(routes.app_settings_templates)
              }}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={t('Notification templates')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.Pathway}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_pathways}
              onClick={() => {
                router.push(routes.app_settings_pathways)
              }}
            >
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary={t('Pathways')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.EventImport}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_import}
              onClick={() => {
                router.push(routes.app_settings_import)
              }}
            >
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
              <ListItemText primary={t('Import events')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.CalendarEvent}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_export}
              onClick={() => {
                router.push(routes.app_settings_export)
              }}
            >
              <ListItemIcon>
                <CloudDownloadIcon />
              </ListItemIcon>
              <ListItemText primary={t('Export events')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.OrganizationBranch}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_organization}
              onClick={() => {
                router.push(routes.app_settings_organization)
              }}
            >
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary={t('Organization tree')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.Organizer}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_organizer}
              onClick={() => {
                router.push(routes.app_settings_organizer)
              }}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={t('Organizer')} />
            </ListItem>
          </UserCanRead>
          <UserCanRead entityName={EntityNames.CustomDomainNameRequest}>
            <ListItem
              button
              className={classes.nested}
              selected={match.location.pathname === routes.app_settings_domain}
              onClick={() => {
                router.push(routes.app_settings_domain)
              }}
            >
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText primary={t('Domain')} />
            </ListItem>
          </UserCanRead>
        </List>
      </Collapse>
      <List component="div" disablePadding className={classes.accountItems}>
        <Divider />
        <ListItem
          button
          onClick={() => {
            router.push(routes.app_account)
          }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary={t('My account')} secondary={user.getTitle() || <span>&nbsp;</span>} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            Auth.logout()
            router.push(routes.login)
          }}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={t('Logout')} />
        </ListItem>
      </List>
    </List>
  )
}
