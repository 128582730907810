import { useQuery } from '@apollo/client'
import { MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import MaterialTable, { Column } from 'material-table'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlertDialog from './dialogs/AlertDialog'
import {
  notificationTemplatesQuery,
  notificationTemplatesQueryVariables,
  NotificationType,
  ReminderItemInput,
} from '../generated/graphql'
import TableHelper, { deleteTableElement, updateTableElement, WithTableData } from '../helpers/TableHelper'
import { useBranchFilteredQuery } from '../hooks/BranchFilterHook'
import NotificationTemplates from '../schema/NotificationTemplates'

interface RemindersTableProps {
  reminders: ReminderItemInput[]

  setReminders(reminders: ReminderItemInput[]): void
}

function getTime(days: number): string {
  return `${days * 3600 * 24}`
}

export default function RemindersTable({ reminders, setReminders }: RemindersTableProps) {
  const { t } = useTranslation()
  const [alert, setAlert] = useState<string | null>(null)
  const branchFilter = useBranchFilteredQuery()
  const { data } = useQuery<notificationTemplatesQuery, notificationTemplatesQueryVariables>(
    NotificationTemplates.GET_NOTIFICATION_TEMPLATES,
    branchFilter,
  )
  const templates = useMemo(() => {
    return (data?.notificationTemplates || []).reduce((acc, i) => {
      // if the reminder is used add it to list no matter is disabled
      const used = reminders.find((r) => r.template === i.id)
      if (used || (i.active && i.type === NotificationType.ATTENDEE_EVENT_REMINDER)) {
        acc[i.id] = i.title
      }
      return acc
    }, {})
  }, [data, reminders])

  const columns = useMemo((): Column<ReminderItemInput>[] => {
    return [
      {
        title: 'When?',
        lookup: {
          '3600': t('1 hour after'),
          [getTime(0.25)]: t('6 hours after'),
          [getTime(1)]: t('1 day after'),
          [getTime(7)]: t('7 day after'),
          [getTime(28)]: t('4 weeks after'),
          '-3600': t('1 hour before'),
          [getTime(-0.25)]: t('6 hours before'),
          [getTime(-0.5)]: t('12 hours before'),
          [getTime(-1)]: t('1 day before'),
          [getTime(-2)]: t('2 days before'),
          [getTime(-3)]: t('3 days before'),
          [getTime(-4)]: t('4 days before'),
          [getTime(-5)]: t('5 days before'),
          [getTime(-6)]: t('6 days before'),
          [getTime(-7)]: t('1 week before'),
          [getTime(-14)]: t('2 weeks before'),
        },
        field: 'offset',
        editComponent: (editProps) => {
          return (
            <Select
              labelId="timeReminder"
              id="timeReminder"
              value={editProps.value || ''}
              label="Time"
              fullWidth
              variant="standard"
              onChange={(val) => {
                editProps.onChange(val.target.value)
              }}
            >
              <MenuItem value={3600}>{t('1 hour after')}</MenuItem>
              <MenuItem value={getTime(0.25)}>{t('6 hours after')}</MenuItem>
              <MenuItem value={getTime(1)}>{t('1 day after')}</MenuItem>
              <MenuItem value={getTime(7)}>{t('7 day after')}</MenuItem>
              <MenuItem value={getTime(28)}>{t('4 weeks after')}</MenuItem>
              <MenuItem value={getTime(-3600)}>{t('1 hour before')}</MenuItem>
              <MenuItem value={getTime(-0.25)}>{t('6 hours before')}</MenuItem>
              <MenuItem value={getTime(-0.5)}>{t('12 hours before')}</MenuItem>
              <MenuItem value={getTime(-1)}>{t('1 days before')}</MenuItem>
              <MenuItem value={getTime(-2)}>{t('2 days before')}</MenuItem>
              <MenuItem value={getTime(-3)}>{t('3 days before')}</MenuItem>
              <MenuItem value={getTime(-4)}>{t('4 days before')}</MenuItem>
              <MenuItem value={getTime(-5)}>{t('5 days before')}</MenuItem>
              <MenuItem value={getTime(-6)}>{t('6 days before')}</MenuItem>
              <MenuItem value={getTime(-7)}>{t('1 week before')}</MenuItem>
              <MenuItem value={getTime(-14)}>{t('2 weeks before')}</MenuItem>
            </Select>
          )
        },
      },
      {
        title: 'Template',
        field: 'template',
        lookup: templates,
        editComponent: (editProps) => {
          return (
            <Select
              labelId="timeReminder"
              id="timeReminder"
              value={editProps.value || ''}
              label="Time"
              fullWidth
              variant="standard"
              onChange={(val) => {
                editProps.onChange(val.target.value)
              }}
            >
              {Object.keys(templates).map((k) => (
                <MenuItem key={k} value={k}>
                  {templates[k]}
                </MenuItem>
              ))}
            </Select>
          )
        },
        hidden: Object.keys(templates).length === 0,
        emptyValue: t('No custom template'),
      },
    ]
  }, [t, templates])
  return (
    <div>
      <AlertDialog open={!!alert} title={t('Validation error')} text={alert} onClose={() => setAlert(null)} />
      <MaterialTable<ReminderItemInput & WithTableData>
        title={t('Reminders')}
        columns={columns}
        icons={TableHelper.getTableIcons()}
        data={reminders.map((r) => ({ ...r }))}
        options={{
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: async (reminder) => {
            if (!reminder.offset) {
              setAlert(t('Please set when should be send the reminder'))
              throw new Error('Validation error')
            }
            reminder.offset = parseInt(reminder.offset.toString())
            setReminders([...reminders, reminder])
          },
          onRowUpdate: async (newReminder, oldReminder) => {
            if (!newReminder.offset) {
              setAlert(t('Please set when should be send the reminder'))
              throw new Error('Validation error')
            }
            newReminder.offset = parseInt(newReminder.offset.toString())
            setReminders(updateTableElement<ReminderItemInput & WithTableData>(newReminder, oldReminder, reminders))
          },
          onRowDelete: async (reminder) => {
            setReminders(deleteTableElement<ReminderItemInput & WithTableData>(reminder, reminders))
          },
        }}
      />
    </div>
  )
}
