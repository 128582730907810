import { useQuery } from '@apollo/client'
import { TextField } from '@mui/material'
import { Column } from 'material-table'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { AclTable } from '../../components/table/AclTable'
import {
  CreateOrganizerMutationMutation,
  getOrganizersQuery,
  getOrganizersQueryVariables,
  mutateNodeBranchMutation,
  MutationmutateOrganizerArgs,
  OrganizerInput,
  OrganizersDataFragment,
} from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { useDeleteMutation, useListMutation } from '../../helpers/GqlHelper'
import { requiredEmail, requiredField } from '../../helpers/validations'
import Organizers from '../../schema/Organizers'

const OrganizerScreen: React.FC = () => {
  const { t } = useTranslation()
  const [deleteOrganizer, { error: deleteOrganizerError }] = useDeleteMutation(Organizers.GET_ORGANIZERS)
  const [createNewOrganizer, { error: organizerCreateError }] = useListMutation<
    CreateOrganizerMutationMutation,
    MutationmutateOrganizerArgs
  >(Organizers.CREATE_ORGANIZER, Organizers.GET_ORGANIZERS)
  const [mutateOrganizer, { error: organizerEditError }] = useListMutation<
    mutateNodeBranchMutation,
    MutationmutateOrganizerArgs
  >(Organizers.EDIT_ORGANIZER, Organizers.GET_ORGANIZERS)
  const [newOrganizerTypeAdded, setNewOrganizerTypeAdded] = useState(null)
  const { loading, error, data } = useQuery<getOrganizersQuery, getOrganizersQueryVariables>(Organizers.GET_ORGANIZERS)

  const createOrganizer = async (input: OrganizerInput) => {
    const response = await createNewOrganizer({
      variables: { input: DataHelper.cleanInputData(input) },
    })
    setNewOrganizerTypeAdded(response.data.addOrganizer)
  }
  const editOrganizer = async (input: OrganizerInput) => {
    const response = await mutateOrganizer({
      variables: { input: DataHelper.cleanInputData(input) },
    })
    setNewOrganizerTypeAdded(response.data.mutateNodeBranch)
  }
  const deleteNode = async (id: string) => {
    await deleteOrganizer({ variables: { input: { id } } })
  }

  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  const organizers = data.organizers

  const columns: Column<OrganizersDataFragment>[] = [
    {
      title: 'Name',
      field: 'name',
      validate: requiredField('name'),
      editComponent: (editProps) => {
        return (
          <TextField
            variant="standard"
            value={editProps.value || ''}
            label="Name"
            fullWidth
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          />
        )
      },
    },
    {
      title: 'Surname',
      field: 'surname',
      editComponent: (editProps) => {
        return (
          <TextField
            variant="standard"
            value={editProps.value || ''}
            label="Surname"
            fullWidth
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          />
        )
      },
    },
    {
      title: 'Email',
      field: 'email',
      validate: requiredEmail('email'),
      editComponent: (editProps) => {
        return (
          <TextField
            variant="standard"
            value={editProps.value || ''}
            label="Email"
            fullWidth
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          />
        )
      },
    },
    {
      title: 'Location',
      field: 'location',
      editComponent: (editProps) => {
        return (
          <TextField
            variant="standard"
            value={editProps.value || ''}
            label="Location"
            fullWidth
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          />
        )
      },
    },
    {
      title: 'Comments',
      field: 'comments',
      editComponent: (editProps) => {
        return (
          <TextField
            variant="standard"
            value={editProps.value || ''}
            label="Comments"
            fullWidth
            onChange={(val) => {
              editProps.onChange(val.target.value)
            }}
          />
        )
      },
    },
    {
      title: 'Trainer',
      field: 'trainer',
      type: 'boolean',
      initialEditValue: true,
    },
  ]
  return (
    <div>
      <Helmet>
        <title>{t('Manage Organizers')}</title>
      </Helmet>
      <GraphqlError error={organizerEditError} />
      <GraphqlError error={organizerCreateError} />
      <GraphqlError error={deleteOrganizerError} />
      <AclTable<OrganizersDataFragment>
        entityName={EntityNames.Organizer}
        title={t('Organizers')}
        columns={columns}
        data={organizers?.map((organizer) => {
          return {
            ...organizer,
          }
        })}
        paginateToElement={newOrganizerTypeAdded}
        editable={{
          onRowAdd: async (newData) => {
            await createOrganizer({
              ...newData,
            })
          },
          onRowUpdate: async (newData) => {
            await editOrganizer({
              ...newData,
            })
          },
          onRowDelete: async (oldData) => {
            if (oldData.id) {
              await deleteNode(oldData.id)
            }
          },
        }}
      />
    </div>
  )
}

export default OrganizerScreen
