import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import AppContainer from '../components/AppContainer'
import PrivateWrapper from '../components/PrivateWrapper'

const MainScreen: React.FC = ({ children }) => {
  const [title, setTitle] = useState('Scheduling')
  const { t } = useTranslation()
  return (
    <PrivateWrapper>
      <AppContainer title={t(title)}>
        {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
        <Helmet onChangeClientState={(newState: any) => setTitle(newState.title)} />
        {children}
      </AppContainer>
    </PrivateWrapper>
  )
}

export default MainScreen
