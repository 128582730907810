import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { AclDefinition, AclDefinitionRow } from '../../schema/AclRoles'

const useStyles = makeStyles()(() => {
  return {
    table: {
      marginTop: -15,
    },
    headerBlock: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerCheckbox: {
      alignSelf: 'center',
    },
  }
})

interface Props {
  value: AclDefinition
  entityMap: Record<string, string>

  onChange(aclDefinition: AclDefinition): void
}

type actionKey = keyof AclDefinitionRow

const headers: { label: string; action: actionKey }[] = [
  {
    label: 'Create',
    action: 'c',
  },
  {
    label: 'Read',
    action: 'r',
  },
  {
    label: 'Update',
    action: 'u',
  },
  {
    label: 'Delete',
    action: 'd',
  },
  {
    label: 'Read own',
    action: 'ro',
  },
  {
    label: 'Update own',
    action: 'uo',
  },
  {
    label: 'Delete own',
    action: 'do',
  },
]

const AclRolePermissionsTableEditor: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { defaults, ...entities } = props.value
  const [aclDefinition, setAclDefinition] = useState<AclDefinition>(entities || {})
  const [defaultValue, setDefault] = useState(defaults)
  useEffect(() => {
    const { defaults: currentDefaults, ...currentEntities } = props.value
    setDefault(currentDefaults)
    setAclDefinition(currentEntities || {})
  }, [props.value])
  const handleChange =
    <T extends keyof AclDefinitionRow>(entity: string, name: T) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange({
        ...aclDefinition,
        defaults: defaultValue,
        [entity]: {
          ...aclDefinition[entity],
          [name]: event.target.checked,
        },
      })
    }
  const handleChangeDefault =
    <T extends keyof AclDefinitionRow>(name: T) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange({
        ...aclDefinition,
        defaults: {
          ...defaults,
          [name]: event.target.checked,
        },
      })
    }
  const handleChangeAll =
    <T extends keyof AclDefinitionRow>(name: T) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedValues = Object.keys(aclDefinition).reduce(
        (def, key) => {
          def[key][name] = checked
          return def
        },
        { ...aclDefinition },
      )
      props.onChange({ defaults: defaultValue, ...updatedValues })
    }

  function getAllValue<T extends keyof AclDefinitionRow>(name: T, checkAll = false, mustBeTrue = true): boolean {
    // noinspection UnnecessaryLocalVariableJS
    const allValue = Object.keys(aclDefinition).reduce((all, key) => {
      return checkAll ? all && !!aclDefinition[key][name] : all || !!aclDefinition[key][name]
    }, checkAll)
    return checkAll && !mustBeTrue ? !allValue : allValue
  }

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell />
          {headers.map((d) => (
            <TableCell key={d.action} align="center">
              {t(d.label)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ verticalAlign: 'middle' }}>{t('Global permissions')}</TableCell>
          {headers.map((d) => (
            <TableCell key={d.action} align="center">
              <Checkbox checked={defaultValue[d.action]} onChange={handleChangeDefault(d.action)} />
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
      <TableHead>
        <TableRow>
          <TableCell style={{ verticalAlign: 'bottom' }}>{t('Entity')}</TableCell>
          {headers.map((d) => (
            <TableCell key={d.action} align="center">
              <div className={classes.headerBlock}>
                <Checkbox
                  checked={getAllValue(d.action, true, true)}
                  indeterminate={getAllValue(d.action) && !getAllValue(d.action, true)}
                  onChange={handleChangeAll(d.action)}
                  disabled={defaultValue[d.action]}
                  className={classes.headerCheckbox}
                />
                {t(d.label)}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(aclDefinition).map((k) => {
          const acl: AclDefinitionRow = aclDefinition[k]
          return (
            <TableRow key={k}>
              <TableCell style={{ verticalAlign: 'middle' }} component="th" scope="row">
                {t(props.entityMap[k] || k)}
              </TableCell>
              {headers.map((d) => (
                <TableCell key={d.action} align="center">
                  <Checkbox
                    checked={acl[d.action]}
                    onChange={handleChange(k, d.action)}
                    disabled={defaultValue[d.action]}
                  />
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default AclRolePermissionsTableEditor
