import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import BranchDialog from './BranchDialog'
import { ConditionalComponent } from '../../components/ConditionalComponent'
import { CanBeUpdated } from '../../components/acl/CanBe'
import { UserCanCreate } from '../../components/acl/UserCanDo'
import { OrganizationBranchDataFragment } from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'

const useStyles = makeStyles()((theme) => {
  return {
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelText: {
      fontWeight: 'inherit',
      marginRight: theme.spacing(2),
    },
  }
})

interface Branch {
  name: string
  id?: string
}

interface Props {
  branch: OrganizationBranchDataFragment
  onCommit?(input: Branch): Promise<void>
}

function BranchListItem({ branch, onCommit }: Props): React.ReactElement {
  const [showDialog, setShowDialog] = useState(false)
  const [state, setState] = useState({ id: undefined, parentId: undefined, name: undefined })
  const { classes } = useStyles()
  return (
    <div>
      <div className={classes.labelRoot}>
        <Typography className={classes.labelText}>{branch.name}</Typography>
        <CanBeUpdated entity={branch}>
          <IconButton
            onClick={() => {
              setState({ id: branch.id, parentId: undefined, name: branch.name })
              setShowDialog(true)
            }}
            size="large"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </CanBeUpdated>
        <UserCanCreate entityName={EntityNames.OrganizationBranch}>
          <IconButton
            onClick={() => {
              setState({ id: undefined, parentId: branch.id, name: undefined })
              setShowDialog(true)
            }}
            size="large"
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </UserCanCreate>
      </div>
      <ConditionalComponent show={showDialog}>
        <div>
          <BranchDialog
            open={showDialog}
            id={state.id}
            name={state.name}
            parentId={state.parentId}
            onClose={() => setShowDialog(false)}
            commit={(input: Branch) => onCommit(input)}
          />
        </div>
      </ConditionalComponent>
    </div>
  )
}

export default BranchListItem
