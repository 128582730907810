import { useMutation } from '@apollo/client'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import React, { useMemo, useState } from 'react'
import ApiNodeInfoEditDialog from './ApiNodeInfoEditDialog'
import ApiNodeInfoPopover, { ApiNodeInfoProps } from './ApiNodeInfoPopover'
import { NodeBranchInput } from '../../generated/graphql'
import { AclAction, useUserContext } from '../../providers/UserContext'
import OwnedApiNodes from '../../schema/OwnedApiNode'
import { ConditionalComponent } from '../ConditionalComponent'
import MouseOverPopover from '../MouseOverPopover'

function ApiNodeInfo(props: ApiNodeInfoProps): React.ReactElement {
  const [showEditOption, setEditOption] = useState<boolean>(false)
  const [showEditDialog, setEditDialog] = useState<boolean>(false)
  const user = useUserContext()
  const canEdit = useMemo(() => {
    return props.node?.id && user.can(props.node, AclAction.update)
  }, [user, props.node])
  const icon = useMemo(() => {
    if (showEditOption && canEdit) {
      return (
        <IconButton
          color="primary"
          onClick={() => {
            setEditDialog(true)
          }}
          size="large"
        >
          <EditIcon />
        </IconButton>
      )
    }

    return (
      <IconButton size="large">
        <InfoIcon />
      </IconButton>
    )
  }, [showEditOption, canEdit])

  const [mutateBranchNode] = useMutation(OwnedApiNodes.EDIT_NODE_BRANCH)

  const editNode = async (input: NodeBranchInput) => {
    await mutateBranchNode({
      variables: {
        input,
      },
    })
  }
  if (!props.node?.id) {
    return null
  }
  return (
    <>
      <MouseOverPopover
        popover={<ApiNodeInfoPopover {...props} />}
        popoverProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
        onOpen={() => setEditOption(true)}
        onClose={() => setEditOption(false)}
      >
        {icon}
      </MouseOverPopover>
      <ConditionalComponent show={showEditDialog}>
        <ApiNodeInfoEditDialog
          open={showEditDialog}
          onClose={() => setEditDialog(false)}
          commit={editNode}
          {...props}
        />
      </ConditionalComponent>
    </>
  )
}

export default ApiNodeInfo
