import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    progress: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    container: {
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  }
})

interface Props {
  variant?: 'screen' | 'inline'
  size?: number
}

const Loading: React.FC<Props> = ({ variant, size }) => {
  const { classes } = useStyles()
  const loader = <CircularProgress className={classes.progress} size={size || (variant === 'inline' ? 15 : 100)} />
  if (variant === 'inline') {
    return loader
  }
  return <div className={classes.container}>{loader}</div>
}

export default Loading
