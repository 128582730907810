import { useRouter } from 'found'
import * as React from 'react'
import { useEffect } from 'react'
import Loading from './Loading'
import Config from '../Config'
import Auth from '../helpers/auth'
import routes from '../helpers/routes'
import { useUserContext } from '../providers/UserContext'

const PrivateWrapper: React.FC = (props: any) => {
  const { router, match } = useRouter()
  const user = useUserContext()

  useEffect(() => {
    function syncLogout(event: any) {
      if (
        event.key === Config.LOGIN.LOGOUT_STORAGE_KEY &&
        event.key === localStorage.getItem(Config.LOGIN.LOGOUT_STORAGE_KEY)
      ) {
        Auth.logout()
        router.push(routes.login)
      } else if (
        event.key === Config.LOGIN.LOGIN_STORAGE_KEY &&
        event.key === localStorage.getItem(Config.LOGIN.LOGIN_STORAGE_KEY)
      ) {
        router.push(match.location)
      }
    }

    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
    }
  }, [router, match.location])

  useEffect(() => {
    if (!user.isUser()) {
      Auth.logout()
      router.push(routes.login)
    }
  }, [router, user])

  // If login success render component
  if (Auth.isLoggedIn()) {
    return <>{props.children}</>
  }
  router.replace(routes.login)
  // While login show loading
  return <Loading />
}

export default PrivateWrapper
