import ListAltIcon from '@mui/icons-material/ListAlt'
import { Column } from 'material-table'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AclRolePermissionsTableEditor from './AclRolePermissionsTableEditor'
import AclRolePopover from './AclRolePopover'
import MouseOverPopover from '../../components/MouseOverPopover'
import AlertDialog from '../../components/dialogs/AlertDialog'
import { AclTable } from '../../components/table/AclTable'
import { AclRoleDataFragment, AclRoleInput } from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { requiredField } from '../../helpers/validations'
import { CanBeNodeInterface, OwnedApiNodeInterface } from '../../providers/UserContext'
import { AclDefinition, AclDefinitionRow } from '../../schema/AclRoles'

const BASE_PERMS: AclDefinitionRow = {
  c: false,
  r: false,
  u: false,
  d: false,
  ro: false,
  uo: false,
  do: false,
}

function createBaseMatrix(entityObject: Record<string, string>) {
  const entityKeysList = Object.keys(entityObject)
  entityKeysList.push('defaults')
  return entityKeysList.reduce((prev, entityKey) => {
    prev[entityKey] = {
      ...BASE_PERMS,
    }
    return prev
  }, {} as AclDefinition)
}

class Row implements Partial<AclRoleDataFragment>, CanBeNodeInterface, OwnedApiNodeInterface {
  id!: string
  name!: string
  active!: boolean
  acl!: string
  parsedAcl!: AclDefinition
  __typename!: 'AclRole'
  canBeDeleted!: boolean
  canBeUpdated!: boolean

  createdAt?: number
  updatedAt?: number
  user?: { __typename: 'Profile'; id: string; name: string }
  creator?: { __typename: 'Profile'; id: string; name: string }
  branch?: { __typename: 'OrganizationBranch'; name: string; id: string }
  shareWithChildBranches?: boolean

  constructor(baseMatrix: Record<string, AclDefinitionRow>, acl: AclRoleDataFragment) {
    Object.assign(this, acl)
    this.parsedAcl = {}
    const selfAcl = this.parsedAcl
    const parsedAcl = acl.acl ? JSON.parse(acl.acl) : {}
    Object.entries(baseMatrix).forEach(([entityKey, basePerms]) => {
      selfAcl[entityKey] = parsedAcl[entityKey] ?? { ...basePerms }
    })
    this.acl = JSON.stringify(this.parsedAcl)
  }
}

interface Props {
  data: AclRoleDataFragment[]
  entities: Record<string, string>

  createRole(role: AclRoleInput): Promise<boolean>
  editRole(role: AclRoleInput): Promise<boolean>
  deleteRole(id: string): Promise<boolean>
}

const AclRolesTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { entities } = props
  const [alert, setAlert] = useState<string | null>(null)
  const baseMatrix = useMemo(() => createBaseMatrix(entities), [entities])
  const rows = props.data ? props.data.map((r) => new Row(baseMatrix, r)) : []
  const columns: Column<Row>[] = [
    { title: 'Name', field: 'name', validate: requiredField('name') },
    {
      title: 'Permissions',
      headerStyle: {
        width: '60%',
      },
      field: 'parsedAcl',
      type: 'string',
      render: (data) => {
        return (
          <MouseOverPopover popover={<AclRolePopover entityMap={entities} aclDefinition={data.parsedAcl} />}>
            <ListAltIcon />
          </MouseOverPopover>
        )
      },
      editComponent: (editComponentProps) => {
        const { value, onChange } = editComponentProps
        return <AclRolePermissionsTableEditor entityMap={entities} value={value || baseMatrix} onChange={onChange} />
      },
    },
    { title: 'Active', field: 'active', type: 'boolean', initialEditValue: true },
  ]
  return (
    <div>
      {/* Hack to align vertically the cells and remove the padding of the info button */}
      <style>
        {
          '.MuiTableCell-root.MuiTableCell-body{\
        vertical-align:top\
        }\
        .MuiTableCell-root.MuiTableCell-body >p > button{\
        padding:0\
        }\
        '
        }
      </style>
      <AlertDialog open={!!alert} title={t('Validation error')} text={alert} onClose={() => setAlert(null)} />
      <AclTable<Row>
        entityName={EntityNames.AclRole}
        title={t('Roles & Permissions')}
        columns={columns}
        data={rows}
        editable={{
          onRowAdd: async (newData) => {
            if (!newData.name) {
              setAlert(t('Name is required. Please fill the Name field.'))
              throw new Error('Validation error')
            }
            const { parsedAcl, ...data } = newData
            data.acl = JSON.stringify(parsedAcl)
            await props.createRole(data)
          },
          onRowUpdate: async (newData) => {
            if (!newData.name) {
              setAlert(t('Name is required. Please fill the Name field.'))
              throw new Error('Validation error')
            }
            const { parsedAcl, ...data } = newData
            data.acl = JSON.stringify(parsedAcl)
            await props.editRole(DataHelper.cleanInputData<AclRoleInput>(data))
          },
          onRowDelete: async (oldData) => {
            await props.deleteRole(oldData.id)
          },
        }}
      />
    </div>
  )
}

export default AclRolesTable
