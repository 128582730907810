import { Link } from 'found'
import React from 'react'
import routes from '../../helpers/routes'

interface Props {
  id?: string | null
  activeClassName?: string
  activeStyle?: Record<string, unknown>
}

const NodeMediaLink: React.FC<Props> = (props) => {
  const { id, children, ...linkProps } = props
  if (id) {
    return (
      <Link
        {...linkProps}
        to={{
          pathname: routes.app_download + '/' + id,
        }}
      >
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export default NodeMediaLink
