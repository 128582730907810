import { useMutation, useQuery } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import {
  CustomDomainRequestStatus,
  DomainCancelMutation,
  DomainCancelMutationVariables,
  getDomainQuery,
  getDomainQueryResult,
} from '../../generated/graphql'
import DH from '../../helpers/DH'
import Domain from '../../schema/Domain'

const useStyles = makeStyles()(() => {
  return {
    title: {
      fontWeight: '500',
    },
  }
})
export default function RequestStatus() {
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const { data } = useQuery<getDomainQuery, getDomainQueryResult>(Domain.GET_DOMAIN, {
    fetchPolicy: 'network-only',
  })
  const [mutateCancelCustomDomainNameRequest] = useMutation<DomainCancelMutation, DomainCancelMutationVariables>(
    Domain.CANCEL_DOMAIN,
    {
      refetchQueries: [Domain.GET_DOMAIN],
    },
  )
  const domain = data?.getCustomDomainNameRequest

  let statusText = ''
  let disableButtonText = ''
  if (domain) {
    if (domain?.status === CustomDomainRequestStatus.requested) {
      statusText = t('Wait Eventoom to generate the records')
      disableButtonText = t('Cancel request')
    } else if (domain?.status === CustomDomainRequestStatus.inProgress) {
      statusText = t('Wait for Eventoom to check the records')
      disableButtonText = t('Cancel request')
    } else if (domain?.status === CustomDomainRequestStatus.done) {
      statusText = t('The custom domain is up and running')
      disableButtonText = t('Disable custom domain')
    } else if (domain?.status === CustomDomainRequestStatus.configured) {
      statusText = t('Please follow the configuration instructions')
      disableButtonText = t('Cancel request')
    }
  }

  async function handleSubmitCancel(event) {
    event.preventDefault()
    try {
      await mutateCancelCustomDomainNameRequest({
        variables: {
          input: {
            id: domain.id,
          },
        },
      })
      setOpen(false)
    } catch (ex) {
      enqueueSnackbar(DH.getGraphqlErrorMessages(ex).join('<br>'), { variant: 'error' })
    }
  }

  return (
    <Card variant="outlined">
      <CardHeader title={t('Custom domain request')} />
      <CardContent>
        <Grid container spacing={2} sx={{ fontSize: '18px' }}>
          <Grid item xs={12} md={4}>
            <span className={classes.title}>{t('Domain name: ')} </span>
            {domain?.domainName}
          </Grid>
          <Grid item xs={12} md={4}>
            <span className={classes.title}>{t('From email: ')}</span>
            {domain?.fromEmail}
          </Grid>
          <Grid item xs={12} md={4}>
            <span className={classes.title}>{t('From name: ')}</span>
            {domain?.fromEmailName}
          </Grid>
          <Grid item xs={12}>
            <Alert severity={domain?.status === CustomDomainRequestStatus.done ? 'success' : 'info'}>
              {statusText}
            </Alert>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button color={'error'} onClick={() => setOpen(true)}>
          {disableButtonText}
        </Button>
      </CardActions>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('Cancel custom domain request')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Are you sure you want to cancel the custom domain request?')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitCancel} color="error">
            {t('Yes')}
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            {t('No')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
