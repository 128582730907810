import { gql } from '@apollo/client'

const DELETE_NODE = gql`
  mutation DeleteNodeMutation($input: NodeSelectInput!) {
    deleteNode(input: $input) {
      id
    }
  }
`

const DELETE_NODE_ORGANIZATION_USERS = gql`
  mutation DeleteNodeOrganizationUsersMutation($input: NodeSelectInput!) {
    deleteNodeOrganizationUsers(input: $input) {
      id
    }
  }
`

const GET_NODE_PERMISSIONS = gql`
  query GetNodePermissions($input: NodeSelectInput!) {
    node(input: $input) {
      id
      canBeDeleted
      canBeUpdated
    }
  }
`

const ApiNodes = {
  DELETE_NODE,
  GET_NODE_PERMISSIONS,
  DELETE_NODE_ORGANIZATION_USERS,
}

export default ApiNodes
