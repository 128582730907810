import { useQuery } from '@apollo/client'
import React from 'react'
import TemplateSettingsTable from './TemplateSettingsTable'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { constantsQuery, NotificationTemplateDefaultInput, notificationTemplatesQuery } from '../../generated/graphql'
import Constants from '../../schema/Constants'
import NotificationTemplates from '../../schema/NotificationTemplates'

interface Props {
  defaultTemplates: string[]

  setDefaultTemplates(notificationTemplates: string[]): void
}

const TemplateSettingsTab: React.FC<Props> = (props) => {
  const { loading, error, data } = useQuery<notificationTemplatesQuery>(
    NotificationTemplates.GET_NOTIFICATION_TEMPLATES,
  )
  const {
    loading: constantsLoading,
    error: constantsError,
    data: constantsData,
  } = useQuery<constantsQuery>(Constants.GET_CONSTANTS)

  if (error) {
    return <GraphqlError error={error} />
  }
  if (constantsError) {
    return <GraphqlError error={constantsError} />
  }
  if (loading || !data || constantsLoading || !constantsData) {
    return <Loading />
  }
  const { defaultTemplates } = props
  const templates = data.notificationTemplates

  return (
    <TemplateSettingsTable
      types={constantsData.constants.notificationTypes}
      methods={constantsData.constants.notificationMethods}
      defaultTemplatesIds={defaultTemplates}
      templates={templates}
      editTemplate={(input: NotificationTemplateDefaultInput) => {
        const oldDefault = templates.filter((t) => t.active && t.type === input.type && t.method === input.method)
        oldDefault.forEach((def) => {
          const idx = defaultTemplates.findIndex((dt) => dt === def.id)
          if (idx >= 0) {
            defaultTemplates.splice(idx, 1)
          }
        })
        if (input.id) {
          defaultTemplates.push(input.id)
        }
        props.setDefaultTemplates(defaultTemplates)
      }}
    />
  )
}

export default TemplateSettingsTab
