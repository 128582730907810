import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl, TextField } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { CustomDomainNameRequestInput, DomainEditMutation, DomainEditMutationVariables } from '../../generated/graphql'
import DH from '../../helpers/DH'
import Domain from '../../schema/Domain'
import AsyncButton from '../AsyncButton'

const schema = yup
  .object({
    domainName: yup.string().required(),
    fromEmail: yup.string().email().required(),
    fromEmailName: yup.string().required(),
  })
  .required()

export default function NewDomainRequestForm() {
  const { register, handleSubmit, formState } = useForm<CustomDomainNameRequestInput>({
    mode: 'all',
    resolver: yupResolver(schema),
  })
  const [mutateCustomDomainNameRequest] = useMutation<DomainEditMutation, DomainEditMutationVariables>(
    Domain.EDIT_DOMAIN,
    {
      refetchQueries: [Domain.GET_DOMAIN],
    },
  )
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  async function save(value: CustomDomainNameRequestInput) {
    try {
      await mutateCustomDomainNameRequest({
        variables: {
          input: {
            domainName: value.domainName,
            fromEmail: value.fromEmail,
            fromEmailName: value.fromEmailName,
          },
        },
      })
    } catch (ex) {
      enqueueSnackbar(DH.getGraphqlErrorMessages(ex).join('<br>'), { variant: 'error' })
    }
  }

  return (
    <Card variant="outlined" sx={{ maxWidth: 400, margin: 'auto' }}>
      <CardHeader title={t('Custom Domain Name Request')} />
      <CardContent>
        <FormControl required={true} fullWidth>
          <TextField variant="standard" {...register('domainName', { required: true })} label={t('Domain Name')} />
        </FormControl>
        <FormControl required={true} fullWidth>
          <TextField
            variant="standard"
            {...register('fromEmail', { required: true })}
            label={t('From Email')}
            type="email"
            error={Boolean(formState.errors.fromEmail)}
          />
        </FormControl>
        <FormControl required={true} fullWidth>
          <TextField
            variant="standard"
            {...register('fromEmailName', { required: true })}
            label={t('From Email Name')}
          />
        </FormControl>
      </CardContent>
      <CardActions>
        <AsyncButton
          disabled={!formState.isValid}
          onClick={handleSubmit((data) => save(data))}
          style={{ margin: 'auto' }}
        >
          {t('Request custom domain')}
        </AsyncButton>
      </CardActions>
    </Card>
  )
}
