import React, { FunctionComponent } from 'react'

interface Props {
  show: any
}

export const ConditionalComponent: FunctionComponent<Props> = (props) => {
  if (!props.show) {
    return null
  }
  return <>{props.children}</>
}
