import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { ReactElement, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Link from '../../components/navigation/Link'
import { ResetPasswordMutation, ResetPasswordMutationVariables } from '../../generated/graphql'
import routes from '../../helpers/routes'
import log from '../../log'
import Profiles from '../../schema/Profiles'

const useStyles = makeStyles()((theme) => {
  return {
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -8,
      marginLeft: -8,
    },
    error: {
      backgroundColor: 'rgb(253, 236, 234)',
      borderRadius: '4px',
      color: 'rgb(97, 26, 21)',
      margin: theme.spacing(3),
      padding: '6px 16px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    success: {
      backgroundColor: 'rgb(237, 247, 237)',
      borderRadius: '4px',
      color: 'rgb(30, 70, 32)',
      margin: theme.spacing(3),
      padding: '6px 16px',
    },
    warningText: {
      backgroundColor: 'rgb(255, 244, 229)',
      borderRadius: '4px',
      color: 'rgb(102, 60, 0)',
      margin: theme.spacing(3),
      padding: '6px 16px',
    },
    wrapper: {
      position: 'relative',
    },
  }
})

export interface PasswordResetFormProps {
  token: string
}

export default function PasswordResetForm(props: PasswordResetFormProps): ReactElement {
  const [resetPassword, { data, loading }] = useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    Profiles.RESET_PASSWORD,
  )
  const { token } = props
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [tokenError, setTokenError] = useState()

  const getErrorMessage = useCallback(
    (error) => {
      const message = error.message.replace(/^GraphQL error:\W*/gi, '')
      switch (message) {
        case 'Invalid token':
          return (
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="resetPasswordTokenInvalid">
                {'The received token is invalid. Try again later or '}
                <Link to={routes.forgot_password}>{'request another token'}</Link>
                {'.'}
              </Trans>
            </Typography>
          )
        case 'Token has expired':
          return (
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="resetPasswordTokenExpired">
                {'The received token has already expired, but you can '}
                <Link to={routes.forgot_password}>{'request another token'}</Link>
                {'.'}
              </Trans>
            </Typography>
          )
        case 'Token has already been used':
          return (
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="resetPasswordTokenUsed">
                {'The received token has already been used, but you can '}
                <Link to={routes.forgot_password}>{'request another token'}</Link>
                {'.'}
              </Trans>
            </Typography>
          )
        default:
          log.error(error)
          return (
            <Typography className={classes.error} component="h4" variant="subtitle1">
              {t('An error has occurred: {{error}}', { error: message || error.messag })}
            </Typography>
          )
      }
    },
    [classes.error, classes.warningText, t],
  )

  const submit = useCallback(
    async (event: React.MouseEvent): Promise<void> => {
      event.preventDefault()
      if (password && password === repeatPassword) {
        const variables = {
          input: {
            newPassword: password,
            token,
          },
        }
        resetPassword({ variables }).catch(setTokenError)
      }
    },
    [password, repeatPassword, resetPassword, token],
  )

  if (tokenError) {
    return getErrorMessage(tokenError)
  } else if (data?.resetPassword?.email) {
    return (
      <Typography className={classes.success} component="h4" variant="subtitle1">
        {t('Your new password has been successfully set')}
      </Typography>
    )
  } else {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <TextField
          fullWidth
          id="password"
          margin="normal"
          label={t('Password')}
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          required
          type="password"
          variant="outlined"
        />
        <TextField
          error={password !== repeatPassword}
          fullWidth
          helperText={password !== repeatPassword ? t("The passwords don't match") : undefined}
          id="repeat-password"
          label={t('Repeat Password')}
          margin="normal"
          name="repeat-password"
          onChange={(e) => setRepeatPassword(e.target.value)}
          required
          type="password"
          variant="outlined"
        />
        <div className={classes.wrapper}>
          <Button
            className={classes.submit}
            color="primary"
            disabled={loading || !password || password !== repeatPassword}
            fullWidth
            onClick={submit}
            type="submit"
          >
            {t('Set new password')}
          </Button>
          {loading && <CircularProgress className={classes.buttonProgress} size={22} />}
        </div>
      </form>
    )
  }
}
