/* eslint-disable */
import {
  ApolloCache,
  DataProxy,
  DocumentNode,
  FetchResult,
  MutationUpdaterFn,
  OperationVariables,
  QueryResult,
  useMutation,
} from '@apollo/client'
import { FieldNode, SelectionSetNode } from 'graphql'
import { useMemo } from 'react'
import {
  DeleteNodeMutationMutation,
  DeleteNodeMutationMutationVariables,
  DeleteNodeOrganizationUsersMutationMutation,
  DeleteNodeOrganizationUsersMutationMutationVariables,
} from '../generated/graphql'
import log from '../log'
import ApiNodes from '../schema/ApiNodes'

type GraphqlEntity = {
  [key: string]: any
  id: string
}

/**
 * T is the Mutation class
 * D is the MutationData class
 * @param mutationName Name of the mutation return field
 * @param query Gql Query to update
 * @param listName Field in query to be updated
 * @param queryVariables Object of variables to pass to query
 */
function getMutationReducer<T, D>(
  mutationName: keyof T,
  query: DocumentNode,
  listName: keyof D,
  queryVariables: Record<string, any> = {},
) {
  return function (cache: DataProxy, mutationResult: FetchResult<T>) {
    if (mutationResult.data && mutationResult.data[mutationName]) {
      const updatedEntity = mutationResult.data[mutationName] as Record<string, any>
      if ('id' in updatedEntity) {
        const entity = updatedEntity as unknown as GraphqlEntity
        const queryResult: any = cache.readQuery({ query, variables: queryVariables }) || { [listName]: [] }
        let list: GraphqlEntity[] = (queryResult[listName] || []).map((i) => i)
        // If update
        if (list.find((r) => r.id === entity.id)) {
          list = list.map((r) => (r.id === entity.id ? entity : r))
        } else {
          // If add
          list.push(entity)
        }
        cache.writeQuery({
          query,
          variables: queryVariables,
          data: { [listName]: list },
        })
      } else {
        log.error("Entity don't has id property")
      }
    }
  }
}

/**
 * T is the Mutation class
 * D is the MutationData class
 * @param mutationName Name of the mutation return field
 * @param query Gql Query to update
 * @param listName Field in query to be updated
 * @param queryVariables Object of variables to pass to query
 */
function getDeleteReducer<T, D>(
  mutationName: keyof T,
  query: DocumentNode,
  listName: keyof D,
  queryVariables: Record<string, any> = {},
) {
  return function (cache: DataProxy, mutationResult: FetchResult<T>) {
    if (mutationResult.data && mutationResult.data[mutationName]) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      const updatedEntity = mutationResult.data[mutationName] as Object
      if ('id' in updatedEntity) {
        const entity = updatedEntity as unknown as GraphqlEntity
        const queryResult: any = cache.readQuery({ query, variables: queryVariables }) || { [listName]: [] }
        const list: GraphqlEntity[] = queryResult[listName]
        const listFiltered = list.filter((i) => i.id !== entity.id && i.__typename !== 'RemovedNode')
        cache.writeQuery({
          query,
          variables: queryVariables,
          data: { [listName]: listFiltered },
        })
      } else {
        log.error("Entity don't has id property")
      }
    }
  }
}

function getOperationDefinitionSelectionName<TData>(query: DocumentNode): keyof TData {
  const firstDefinition = query.definitions[0]
  if (
    firstDefinition &&
    (firstDefinition.kind === 'OperationDefinition' || firstDefinition.kind === 'FragmentDefinition')
  ) {
    const ss: SelectionSetNode = firstDefinition.selectionSet
    if (ss) {
      const fn: FieldNode = ss.selections[0] as FieldNode
      if (fn.name) {
        return fn.name.value as keyof TData
      }
    }
  }
  throw new Error("Can't determine query selection name")
}

export function useDeleteMutation<TList>(
  listQuery: DocumentNode,
  queryVariables: Record<string, any> = {},
  update?: MutationUpdaterFn<DeleteNodeMutationMutation>,
) {
  const listQueryName = getOperationDefinitionSelectionName<TList>(listQuery)
  return useMutation<DeleteNodeMutationMutation, DeleteNodeMutationMutationVariables>(ApiNodes.DELETE_NODE, {
    update(cache: ApolloCache<any>, mutationResult: FetchResult<DeleteNodeMutationMutation>) {
      getDeleteReducer<DeleteNodeMutationMutation, TList>(
        'deleteNode',
        listQuery,
        listQueryName,
        queryVariables,
      )(cache, mutationResult)
      if (update) {
        update(cache, mutationResult)
      }
    },
  })
}

export function useDeleteOrganizationUsersMutation<TList>(
  listQuery: DocumentNode,
  queryVariables: Record<string, any> = {},
  update?: MutationUpdaterFn<DeleteNodeOrganizationUsersMutationMutation>,
) {
  const listQueryName = getOperationDefinitionSelectionName<TList>(listQuery)
  return useMutation<DeleteNodeOrganizationUsersMutationMutation, DeleteNodeOrganizationUsersMutationMutationVariables>(
    ApiNodes.DELETE_NODE_ORGANIZATION_USERS,
    {
      update(cache: ApolloCache<any>, mutationResult: FetchResult<DeleteNodeOrganizationUsersMutationMutation>) {
        getDeleteReducer<DeleteNodeOrganizationUsersMutationMutation, TList>(
          'deleteNodeOrganizationUsers',
          listQuery,
          listQueryName,
          queryVariables,
        )(cache, mutationResult)
        if (update) {
          update(cache, mutationResult)
        }
      },
    },
  )
}

export function useListMutation<TMutation = any, TVariables = OperationVariables, TList = any>(
  mutationQuery: DocumentNode,
  listQuery: DocumentNode,
  queryVariables: Record<string, any> = {},
) {
  const listQueryName = getOperationDefinitionSelectionName<TList>(listQuery)
  const mutateQueryName = getOperationDefinitionSelectionName<TMutation>(mutationQuery)
  return useMutation<TMutation, TVariables>(mutationQuery, {
    update: getMutationReducer<TMutation, TList>(mutateQueryName, listQuery, listQueryName, queryVariables),
  })
}

export function removeOwnedApiNodeInfo<T extends { [key: string]: any }>(
  data: T,
): Omit<T, 'user' | 'creator' | 'branch' | 'shareWithChildBranches' | 'createdAt' | 'updatedAt'> {
  const { user, creator, branch, shareWithChildBranches, createdAt, updatedAt, ...finalData } = data
  return finalData
}

export function useCanAccessData<T>(queryResult: QueryResult<T>) {
  return useMemo(() => {
    return queryResult.data || !queryResult.loading
  }, [queryResult.data, queryResult.loading])
}

const GqlHelper = {
  getMutationReducer,
  getDeleteReducer,
}

export default GqlHelper
