/* eslint-disable */
import { QueryHookOptions, useQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'
import React, { ReactElement } from 'react'
import GraphqlError from './GraphqlError'
import Loading from './Loading'

interface EditProps<T> {
  id: string
  query: DocumentNode
  options?: QueryHookOptions<T>

  render(node?: T): ReactElement
}

function EditComponent<T>({ query, id, render, options }: EditProps<T>) {
  const { loading, error, data } = useQuery<T>(query, {
    variables: {
      id,
    },
    ...options,
  })
  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  return render(data)
}

interface Props<T> {
  id?: string
  query: DocumentNode
  options?: QueryHookOptions<T>

  render(node?: T): ReactElement
}

export default function CreateOrEditComponent<T>(props: Props<T>) {
  if (props.id) {
    return <EditComponent<T> {...props} id={props.id} />
  }
  return props.render()
}
