import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { ReactNode, useState } from 'react'

interface TabPanelProps {
  children?: any
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dialog-tabpanel-${index}`}
      aria-labelledby={`dialog-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    'id': `dialog-tab-${index}`,
    'aria-controls': `dialog-tabpanel-${index}`,
  }
}

interface Props {
  tabLabels: string[]
  children: ReactNode
}

const DialogTabs: React.FC<Props> = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  const children = Array.isArray(props.children) ? props.children.filter((child) => !!child) : [props.children]

  if (children.length === 1) {
    return <>{children[0]}</>
  } else {
    return (
      <>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newTab) => setActiveTab(newTab)}
          aria-label="dialog tabs"
        >
          {props.tabLabels.map((label, idx) => {
            return <Tab key={idx + label} label={label} {...a11yProps(idx)} />
          })}
        </Tabs>
        {children.map((child, idx) => {
          return (
            <TabPanel key={'tabPanel-' + idx} index={idx} value={activeTab}>
              {child}
            </TabPanel>
          )
        })}
      </>
    )
  }
}

export default DialogTabs
