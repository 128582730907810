import { DialogContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import AsyncButton from '../../components/AsyncButton'
import { OrganizationBranchInput } from '../../generated/graphql'

interface Props {
  open: boolean
  parentId?: string | null
  name?: string
  id?: string

  onClose(): void
  commit(input: OrganizationBranchInput): Promise<void>
}

const useStyles = makeStyles()(() => {
  return {
    submit: {
      float: 'right',
    },
  }
})

const BranchDialog: React.FC<Props> = (props) => {
  const { classes } = useStyles()
  const { open, onClose, commit, id, name, parentId } = props
  const { t } = useTranslation()
  const [branchName, setBranchName] = useState<string>(name || '')

  const createBranch = useCallback(async () => {
    const extra: Partial<OrganizationBranchInput> = {}
    if (id) {
      extra.id = id
    }
    if (parentId) {
      extra.id_parent = parentId
    }
    await commit({ name: branchName, ...extra })
    onClose()
  }, [branchName, commit, id, onClose, parentId])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t(`${id ? 'Edit' : 'Create'} branch`)}</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          autoFocus
          label={t('Branch name')}
          type="text"
          value={branchName}
          onChange={(e) => setBranchName(e.target.value)}
          fullWidth
        />
        <AsyncButton variant="text" onClick={createBranch} className={classes.submit} />
      </DialogContent>
    </Dialog>
  )
}

export default BranchDialog
