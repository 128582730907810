import AddHomeIcon from '@mui/icons-material/AddHome'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ListItemText } from '@mui/material'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withStyles } from 'tss-react/mui'
import { CalendarDataFragment } from '../../generated/graphql'
import DH from '../../helpers/DH'
import log from '../../log'
import { CanBeDeleted, CanBeDeletedOrUpdated, CanBeUpdated } from '../acl/CanBe'

const checkboxes: { [key: string]: any } = {}

Object.keys(DH.getDefaultColors()).forEach((colorName) => {
  checkboxes[colorName] = withStyles(
    (props: CheckboxProps) => <Checkbox color="default" {...props} />,
    () => ({
      root: {
        'color': DH.getColorValue(colorName, 400),
        '&$checked': {
          color: DH.getColorValue(colorName, 600),
        },
      },
      checked: {},
    }),
  )
}, {})

interface Props extends CheckboxProps {
  calendar: CalendarDataFragment

  toggleChecked(): void

  setDefaultAction(calendar: CalendarDataFragment): void

  editAction(calendar: CalendarDataFragment): void

  deleteAction(calendar: CalendarDataFragment): void
}

const CalendarListItem: React.FC<Props> = (props) => {
  const { calendar, toggleChecked, setDefaultAction, editAction, deleteAction, ...checkboxProps } = props
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const labelId = `checkbox-list-secondary-label-${calendar.id}`
  const CheckboxColored = checkboxes[calendar.color]
  if (!CheckboxColored) {
    log.log('Color not exists', calendar.color, { calendar })
    return null
  }
  return (
    <ListItem role={undefined} dense button onClick={toggleChecked}>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <CanBeUpdated entity={calendar}>
          <MenuItem
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleClose(event)
              setDefaultAction(calendar)
            }}
          >
            <ListItemIcon>
              <AddHomeIcon />
            </ListItemIcon>
            {t('Set Default')}
          </MenuItem>
        </CanBeUpdated>
        <CanBeUpdated entity={calendar}>
          <MenuItem
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleClose(event)
              editAction(calendar)
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            {t('Edit')}
          </MenuItem>
        </CanBeUpdated>
        <CanBeDeleted entity={calendar}>
          <MenuItem
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleClose(event)
              deleteAction(calendar)
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            {t('Delete')}
          </MenuItem>
        </CanBeDeleted>
      </Menu>
      <ListItemIcon>
        <CheckboxColored
          edge="start"
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
          {...checkboxProps}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={calendar.name} />
      <CanBeDeletedOrUpdated entity={calendar}>
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="show actions"
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </CanBeDeletedOrUpdated>
    </ListItem>
  )
}

export default CalendarListItem
