import { gql } from '@apollo/client'

const PathwayItemData = gql`
  fragment PathwayItemData on PathwayItem {
    id
    pathway {
      id
    }
    event {
      id
      title
    }
    parent {
      id
    }
    allChildrenIDs
  }
`

const GET_PATHWAY_ITEMS = gql`
  query pathwayItems($input: NodeSelectInput!) {
    pathwayItems(input: $input) {
      ...PathwayItemData
    }
  }
  ${PathwayItemData}
`

const EDIT_PATHWAY_ITEMS = gql`
  mutation PathwayItemMutation($input: PathwayItemInput!) {
    mutatePathwayItem(input: $input) {
      ...PathwayItemData
    }
  }
  ${PathwayItemData}
`

const PathwayItems = {
  GET_PATHWAY_ITEMS,
  EDIT_PATHWAY_ITEMS,
  fragments: {
    PathwayItemData,
  },
}

export default PathwayItems
