import { useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import EventDialogContent from './EventDialogContent'
import CreateOrEditComponent from '../../components/CreateOrEditComponent'
import { EventInput, eventQuery } from '../../generated/graphql'
import CalendarEvents from '../../schema/CalendarEvents'

interface Props {
  open: boolean
  id?: string | null
  initData?: Partial<EventInput>

  onClose(): void
  commit(input: EventInput): Promise<void>
}

const EventDialog: React.FC<Props> = (props) => {
  const { open, onClose, commit, id, initData } = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
    >
      <CreateOrEditComponent<eventQuery>
        query={CalendarEvents.GET_EVENT}
        // eslint-disable-next-line
        id={id!}
        render={(event?: eventQuery) => {
          return (
            <EventDialogContent
              event={event && event.event}
              open={open}
              onClose={onClose}
              commit={commit}
              initData={initData}
            />
          )
        }}
      />
    </Dialog>
  )
}

export default EventDialog
