import { createBrowserRouter, makeRouteConfig, Route } from 'found'
import React from 'react'
import routes from './helpers/routes'
import MainScreen from './screens/MainScreen'
import EmailValidationScreen from './screens/auth/EmailValidationScreen'
import ForgottenPasswordScreen from './screens/auth/ForgottenPasswordScreen'
import LoginScreen from './screens/auth/LoginScreen'
import PasswordResetScreen from './screens/auth/PasswordResetScreen'
import RegisterScreen from './screens/auth/RegisterScreen'
import CalendarScreen from './screens/calendar/CalendarScreen'
import DomainScreen from './screens/domain/DomainScreen'
import DownloadScreen from './screens/download/DownloadScreen'
import EventTypeScreen from './screens/events/EventTypeScreen'
import EventExportScreen from './screens/export/EventExportScreen'
import InvitePacientResponseScreen from './screens/externalactions/InvitePatientResponseScreen'
import EventImportEditScreen from './screens/import/EventImportEditScreen'
import ImportEventsScreen from './screens/import/ImportEventsScreen'
import DocumentTypesScreen from './screens/media/DocumentTypesScreen'
import MediaLibraryScreen from './screens/media/MediaLibraryScreen'
import AccountScreen from './screens/myaccount/AccountScreen'
import ManageNotificationTemplatesScreen from './screens/notifications/ManageNotificationTemplatesScreen'
import TemplatesEditScreen from './screens/notifications/TemplatesEditScreen'
import OrganizationBranchScreen from './screens/organizations/OrganizationBranchScreen'
import OrganizerScreen from './screens/organizer/OrganizerScreen'
import ManagePathwaysScreen from './screens/pathways/ManagePathwaysScreen'
import PathwayEditScreen from './screens/pathways/PathwayEditScreen'
import ManageRolesScreen from './screens/users/ManageRolesScreen'
import ManageUsersScreen from './screens/users/ManageUsersScreen'

const routeConfig = makeRouteConfig(
  <Route path="/">
    <Route Component={LoginScreen} />
    {routes.register && <Route path={routes.register} Component={RegisterScreen} />}
    <Route path={routes.forgot_password} Component={ForgottenPasswordScreen} />
    <Route path={routes.reset_password} Component={PasswordResetScreen} />
    <Route path={routes.invite_pacient_response} Component={InvitePacientResponseScreen} />
    <Route path={routes.email_validation} Component={EmailValidationScreen} />
    <Route path={routes.email_validation_param} Component={EmailValidationScreen} />
    <Route path={routes.app.index} Component={MainScreen}>
      <Route Component={CalendarScreen} />
      <Route path={routes.app.settings.index}>
        <Route path={routes.app.settings.types} Component={EventTypeScreen} />
        <Route path={routes.app.settings.users} Component={ManageUsersScreen} />
        <Route path={routes.app.settings.roles} Component={ManageRolesScreen} />
        <Route path={routes.app.settings.document_types} Component={DocumentTypesScreen} />
        <Route path={routes.app.settings.media_library} Component={MediaLibraryScreen} />
        <Route path={routes.app.settings.templates.index}>
          <Route Component={ManageNotificationTemplatesScreen} />
          <Route path={routes.app.settings.templates.edit + '/:id?'} Component={TemplatesEditScreen} />
          <Route path={routes.app.settings.templates.new} Component={TemplatesEditScreen} />
        </Route>
        <Route path={routes.app.settings.pathways.index}>
          <Route Component={ManagePathwaysScreen} />
          <Route path={routes.app.settings.pathways.edit + '/:id?'} Component={PathwayEditScreen} />
          <Route path={routes.app.settings.pathways.new} Component={PathwayEditScreen} />
        </Route>
        <Route path={routes.app.settings.import.index}>
          <Route Component={ImportEventsScreen} />
          <Route path={routes.app.settings.import.edit + '/:id?'} Component={EventImportEditScreen} />
          <Route path={routes.app.settings.import.new} Component={EventImportEditScreen} />
        </Route>
        <Route path={routes.app.settings.export} Component={EventExportScreen} />
        <Route path={routes.app.settings.organization} Component={OrganizationBranchScreen} />
        <Route path={routes.app.settings.organizer} Component={OrganizerScreen} />
        <Route path={routes.app.settings.domain} Component={DomainScreen} />
      </Route>
      <Route path={routes.app.account} Component={AccountScreen} />
      <Route path={routes.app.download + '/:id'} Component={DownloadScreen} />
    </Route>
  </Route>,
)
const MainRouter = createBrowserRouter({
  routeConfig,
  renderError: ({ error }) => <div>{error.status === 404 ? 'Not found' : 'Error'}</div>,
})

export default MainRouter
