import { gql } from '@apollo/client'

const GET_CONSTANTS = gql`
  query constants {
    constants {
      locales
      notificationMethods {
        key
        value
      }
      notificationTypes {
        key
        value
      }
    }
  }
`

const GET_ACL_ENTITIES = gql`
  query aclEntities {
    constants {
      aclRolesEntities {
        key
        value
      }
    }
  }
`

const Constants = {
  GET_CONSTANTS,
  GET_ACL_ENTITIES,
}

export default Constants
