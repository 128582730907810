import Check from '@mui/icons-material/Check'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AclDefinition, AclDefinitionRow } from '../../schema/AclRoles'

export default function AclRolePopover(props: { aclDefinition: AclDefinition; entityMap: Record<string, string> }) {
  const { defaults, ...aclDefinition } = props.aclDefinition
  const { t } = useTranslation()
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('Entity')}</TableCell>
          <TableCell align="center">{t('C.')}</TableCell>
          <TableCell align="center">{t('R.')}</TableCell>
          <TableCell align="center">{t('U.')}</TableCell>
          <TableCell align="center">{t('D.')}</TableCell>
          <TableCell align="center">{t('R.O.')}</TableCell>
          <TableCell align="center">{t('U.O.')}</TableCell>
          <TableCell align="center">{t('D.O.')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(aclDefinition).map((k) => {
          const acl: AclDefinitionRow = aclDefinition[k]
          return (
            <TableRow key={k}>
              <TableCell component="th" scope="row">
                {t(props.entityMap[k] || k)}
              </TableCell>
              <TableCell align="right">{(defaults.c || acl.c) && <Check />}</TableCell>
              <TableCell align="right">{(defaults.r || acl.r) && <Check />}</TableCell>
              <TableCell align="right">{(defaults.u || acl.u) && <Check />}</TableCell>
              <TableCell align="right">{(defaults.d || acl.d) && <Check />}</TableCell>
              <TableCell align="right">{(defaults.ro || acl.ro) && <Check />}</TableCell>
              <TableCell align="right">{(defaults.uo || acl.uo) && <Check />}</TableCell>
              <TableCell align="right">{(defaults.do || acl.do) && <Check />}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
