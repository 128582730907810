import { useMutation, useQuery } from '@apollo/client'
import CalendarToday from '@mui/icons-material/CalendarToday'
import EventIcon from '@mui/icons-material/Event'
import dayjs from 'dayjs'
import { filter } from 'graphql-anywhere'
import moment from 'moment'
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Calendar as CalendarComponent, momentLocalizer, stringOrDate } from 'react-big-calendar'
import { Helmet } from 'react-helmet'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import AgendaCalendarEventView from './AgendaCalendarEventView'
import CalendarDialog from './CalendarDialog'
import CalendarEventView from './CalendarEventView'
import { ConditionalComponent } from '../../components/ConditionalComponent'
import GraphqlError from '../../components/GraphqlError'
import SpeedDials from '../../components/SpeedDials'
import {
  CalendarEventBasicDataFragment,
  CalendarInput,
  CalendarMutationMutation,
  CalendarMutationMutationVariables,
  EventInput,
  EventMutationMutation,
  EventMutationMutationVariables,
  eventsQuery,
} from '../../generated/graphql'
import DataHelper, { EntityNames } from '../../helpers/DataHelper'
import { useDeleteMutation } from '../../helpers/GqlHelper'
import { useBranchFilter } from '../../hooks/BranchFilterHook'
import { useSelectedCalendars } from '../../providers/CalendarContext'
import { AclAction, useUserContext } from '../../providers/UserContext'
import CalendarEvents from '../../schema/CalendarEvents'
import Calendars from '../../schema/Calendars'
import Recurrence from '../../schema/Recurrence'
import Tags from '../../schema/Tags'
import EventAttendanceRegisterDialog from '../events/EventAttendanceRegisterDialog'
import EventDialog from '../events/EventDialog'
import ViewEventDialog from '../events/ViewEventDialog'

const localizer = momentLocalizer(moment)

type DateRange = null | { start: Date; end: Date }

const useStyles = makeStyles()(() => {
  return {
    root: {
      height: 'calc(100vh - 150px)',
      minHeight: 729,
      paddingBottom: 20,
    },
    baseEvent: {
      padding: 0,
      fontWeight: 300,
      backgroundColor: 'transparent',
    },
    agendaViewItem: {
      cursor: 'pointer',
    },
    buttonAdd: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0',
      margin: '0',
      alignSelf: 'flex-end',
    },
  }
})

const updateDates = (
  datesRange: DateRange,
  setDatesRange: (range: DateRange) => void,
  range: Date[] | { start: stringOrDate; end: stringOrDate },
) => {
  let newDateRange: DateRange
  if (Array.isArray(range)) {
    const momentDates = range.map((d) => dayjs(d))
    const end = dayjs.max(momentDates)
    const start = dayjs.min(momentDates)
    newDateRange = { start: start.toDate(), end: end.toDate() }
  } else {
    newDateRange = { start: dayjs(range.start).toDate(), end: dayjs(range.end).toDate() }
  }
  let changed: boolean
  if (!datesRange) {
    changed = !!newDateRange
  } else {
    changed =
      dayjs(newDateRange.start).unix() !== dayjs(datesRange.start).unix() ||
      dayjs(newDateRange.end).unix() !== dayjs(datesRange.end).unix()
  }
  if (changed) {
    // Set dates from midnight to midnight
    setDatesRange({
      start: dayjs(newDateRange.start).startOf('day').toDate(),
      end: dayjs(newDateRange.end).endOf('day').toDate(),
    })
  }
}

const CalendarScreen: React.FC = () => {
  const calendar = useRef(null)
  const { classes } = useStyles()
  const [datesRange, setDatesRange] = useState<null | { start: Date; end: Date }>(null)
  useEffect(() => {
    if (calendar.current) {
      const ref = calendar.current
      // Initial date ranges
      if (ref) {
        const v = ref.getView()
        if (v) {
          const newRange = v.range(ref.props.getNow(), { localizer: ref.props.localizer }, ref.props.view)
          updateDates(null, setDatesRange, newRange)
        }
      }
    }
  }, [calendar, setDatesRange])
  const [newCalendarOpen, setNewCalendarOpen] = useState(false)
  const user = useUserContext()
  const [newEventOpen, setNewEventOpen] = useState<Partial<EventInput> | undefined>(undefined)
  const [inViewEvent, setInViewEvent] = useState<CalendarEventBasicDataFragment | null>(null)
  const [inEditEvent, setInEditEvent] = useState<CalendarEventBasicDataFragment | null>(null)
  const [showAttendanceManagementDialog, setShowAttendanceManagementDialog] = useState(false)
  const branchFilter = useBranchFilter()
  const [newCalendar] = useMutation<CalendarMutationMutation, CalendarMutationMutationVariables>(
    Calendars.EDIT_CALENDAR,
    {
      refetchQueries: [{ query: Calendars.GET_CALENDARS, variables: { branchFilter } }],
    },
  )

  const [mutateEvent] = useMutation<EventMutationMutation, EventMutationMutationVariables>(CalendarEvents.EDIT_EVENT, {
    refetchQueries: [
      {
        query: CalendarEvents.GET_EVENTS,
        variables: {
          fromDate: dayjs((datesRange && datesRange.start) || new Date()).unix(),
          toDate: dayjs((datesRange && datesRange.end) || new Date()).unix(),
          branchFilter,
        },
      },
    ],
  })

  const [deleteEvent] = useDeleteMutation(CalendarEvents.GET_EVENTS, { branchFilter })
  const { t } = useTranslation()

  const { error, data } = useQuery<eventsQuery>(CalendarEvents.GET_EVENTS, {
    variables: {
      fromDate: dayjs((datesRange && datesRange.start) || new Date()).unix(),
      toDate: dayjs((datesRange && datesRange.end) || new Date()).unix(),
      branchFilter,
    },
  })

  const dialActions = useMemo(() => {
    const actions: { icon: ReactElement; name: string; action: () => any }[] = []
    if (user.canDo(EntityNames.CalendarEvent, AclAction.create)) {
      actions.push({
        icon: <EventIcon />,
        name: 'New event',
        action: () => setNewEventOpen({}),
      })
    }

    if (user.canDo(EntityNames.Calendar, AclAction.create)) {
      actions.push({
        icon: <CalendarToday />,
        name: 'New calendar',
        action: () => setNewCalendarOpen(true),
      })
    }

    return actions
  }, [user])

  const selectedCalendars = useSelectedCalendars()
  const createCalendar = async (input: CalendarInput) => {
    await newCalendar({
      variables: { input },
    })
  }

  const createEvent = async (input: EventInput & Partial<CalendarEventBasicDataFragment>) => {
    const recurrenceData = {
      recurrence: null,
    }
    if (input.recurrence) {
      recurrenceData.recurrence = {
        ...filter(Recurrence.fragments.RecurrenceInput, input.recurrence),
      }
    }

    await mutateEvent({
      variables: {
        input: {
          ...DataHelper.removeTypeName(input),
          ...recurrenceData,
          reminders: input.reminders?.map((r) => ({ offset: r.offset, template: r.template })),
          tags: filter(Tags.fragment.TagData, input.tags ?? []),
        },
      },
    })
  }

  const deleteNode = async (id: string) => {
    await deleteEvent({
      variables: {
        input: { id },
      },
    })
  }

  const editEvent = async (event: CalendarEventBasicDataFragment) => {
    setInViewEvent(null)
    setInEditEvent(event)
  }

  const manageAttendance = () => {
    setShowAttendanceManagementDialog(true)
  }

  const handleSlotSelect = (slotInfo: {
    start: stringOrDate
    end: stringOrDate
    slots: Date[] | string[]
    action: 'select' | 'click' | 'doubleClick'
  }) => {
    const today = dayjs()
    let start = dayjs(slotInfo.start)
    let end = dayjs(slotInfo.end)
    if (start.hour() === end.hour() && start.minute() === end.minute()) {
      start = start.set('hour', today.hour()).set('minute', today.minute()).set('second', today.second())
      end = end.set('hour', today.hour()).set('minute', today.minute()).set('second', today.second()).add(1, 'hour')
    }

    if (start.isSameOrAfter(today, 'hour')) {
      // Avoid creation of past events
      setNewEventOpen({
        start: start.unix(),
        end: end.unix(),
      })
    }
  }
  let errors: ReactElement | null = null
  if (error) {
    errors = <GraphqlError error={error} />
  }
  let events: CalendarEventBasicDataFragment[] = (data && data.events) || []
  const selectedCalendarsIds = selectedCalendars.map((cal) => cal.id)
  events = events.filter((e: CalendarEventBasicDataFragment) => selectedCalendarsIds.includes(e.id_calendar))

  // If event has been updated, and it's selected, update also inViewEvent
  if (inViewEvent) {
    const event = events.find((ev) => ev.id === inViewEvent.id)
    if (event && event !== inViewEvent) {
      setInViewEvent(event)
    }
  }
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{t('Calendar')}</title>
      </Helmet>
      {errors}
      <CalendarComponent
        ref={calendar}
        popup
        selectable
        views={{
          month: true,
          week: true,
          day: true,
          agenda: true,
        }}
        components={{
          event: CalendarEventView,
          agenda: {
            event: (props: any) => {
              return (
                <div className={classes.agendaViewItem} onClick={() => setInViewEvent(props.event)}>
                  <AgendaCalendarEventView {...props} />
                </div>
              )
            },
          },
        }}
        localizer={localizer}
        events={events}
        onRangeChange={(range: Date[] | { start: stringOrDate; end: stringOrDate }) => {
          updateDates(datesRange, setDatesRange, range)
        }}
        onSelectEvent={(event) => setInViewEvent(event)}
        startAccessor={(event) => {
          return dayjs.unix(event.start).toDate()
        }}
        endAccessor={(event) => dayjs.unix(event.end).toDate()}
        tooltipAccessor="description"
        eventPropGetter={() => ({ className: classes.baseEvent })}
        onSelectSlot={handleSlotSelect}
      />
      <div className={classes.buttonAdd}>
        <ConditionalComponent show={dialActions.length}>
          <SpeedDials actions={dialActions} />
        </ConditionalComponent>
      </div>

      <CalendarDialog open={newCalendarOpen} onClose={() => setNewCalendarOpen(false)} commit={createCalendar} />
      <EventDialog
        open={!!newEventOpen}
        initData={newEventOpen}
        onClose={() => setNewEventOpen(undefined)}
        commit={createEvent}
      />
      <ConditionalComponent show={inEditEvent}>
        <EventDialog
          open={true}
          id={inEditEvent && inEditEvent.id}
          onClose={() => setInEditEvent(null)}
          commit={createEvent}
        />
      </ConditionalComponent>
      <ConditionalComponent show={inViewEvent}>
        <ViewEventDialog
          open={true}
          event={inViewEvent}
          deleteEvent={deleteNode}
          onClose={() => setInViewEvent(null)}
          manageAttendance={manageAttendance}
          editEvent={() => editEvent(inViewEvent)}
        />
      </ConditionalComponent>
      <ConditionalComponent show={inViewEvent}>
        <EventAttendanceRegisterDialog
          event={inViewEvent}
          editEvent={createEvent}
          open={showAttendanceManagementDialog}
          onClose={() => setShowAttendanceManagementDialog(false)}
        />
      </ConditionalComponent>
    </div>
  )
}

export default CalendarScreen
