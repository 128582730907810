import { RouterState } from 'found'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import PathwayEditContent from './PathwayEditContent'
import CreateOrEditComponent from '../../components/CreateOrEditComponent'
import { pathwayQuery } from '../../generated/graphql'
import Pathways from '../../schema/Pathways'

const PathwayEditScreen: React.FC<RouterState> = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <title>{t(props.match.params.id ? 'Edit Pathway' : 'New Pathway')}</title>
      </Helmet>
      <CreateOrEditComponent<pathwayQuery>
        query={Pathways.GET_PATHWAY}
        id={props.match.params.id}
        render={(pathway?: pathwayQuery) => {
          return <PathwayEditContent pathway={pathway && pathway.pathway} />
        }}
      />
    </div>
  )
}

export default PathwayEditScreen
