import { useQuery } from '@apollo/client'
import React, { FunctionComponent } from 'react'
import { GetNodePermissionsQuery, GetNodePermissionsQueryVariables } from '../../generated/graphql'
import { CanBeInterface } from '../../providers/UserContext'
import ApiNodes from '../../schema/ApiNodes'
import GraphqlError from '../GraphqlError'

interface Props {
  entity: CanBeInterface
}

interface NodeProps {
  entityId: string
  prop: 'canBeDeleted' | 'canBeUpdated'
}

export const NodeCanBe: FunctionComponent<NodeProps> = React.forwardRef((props, ref) => {
  const { loading, error, data } = useQuery<GetNodePermissionsQuery, GetNodePermissionsQueryVariables>(
    ApiNodes.GET_NODE_PERMISSIONS,
    { variables: { input: { id: props.entityId } } },
  )
  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data.node[props.prop]) {
    return null
  }
  if (React.Children.count(props.children) === 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const children = React.cloneElement(props.children, { ref })
    return <>{children}</>
  }
  return <>{props.children}</>
})

export const NodeCanBeDeleted: FunctionComponent<Omit<NodeProps, 'prop'>> = (props) => {
  return <NodeCanBe {...props} prop="canBeDeleted" />
}

export const NodeCanBeUpdated: FunctionComponent<Omit<NodeProps, 'prop'>> = (props) => {
  return <NodeCanBe {...props} prop="canBeUpdated" />
}

export const CanBeDeleted: FunctionComponent<Props> = React.forwardRef((props, ref) => {
  if (!props.entity.canBeDeleted) {
    return null
  }
  if (React.Children.count(props.children) === 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const children = React.cloneElement(props.children, { ref })
    return <>{children}</>
  }
  return <>{props.children}</>
})

export const CanBeUpdated: FunctionComponent<Props> = React.forwardRef((props, ref) => {
  if (!props.entity.canBeUpdated) {
    return null
  }
  if (React.Children.count(props.children) === 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const children = React.cloneElement(props.children, { ref })
    return <>{children}</>
  }
  return <>{props.children}</>
})

export const CanBeDeletedOrUpdated: FunctionComponent<Props> = React.forwardRef((props, ref) => {
  if (!props.entity.canBeUpdated && !props.entity.canBeDeleted) {
    return null
  }
  if (React.Children.count(props.children) === 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const children = React.cloneElement(props.children, { ref })
    return <>{children}</>
  }
  return <>{props.children}</>
})
